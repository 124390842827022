import {
    IRequestQuotasAction, IRequestQuotasActionFailure, IRequestQuotasActionSuccess, IResetRequestQuotasAction,
} from "../../types";
import * as actions from '../../constants';


export const requestQuotas = (id:number): IRequestQuotasAction => ({
    type: actions.requestQuotasAction.requested,
    payload:id
});
export const requestQuotasSuccess = (): IRequestQuotasActionSuccess => ({
    type: actions.requestQuotasAction.fulfilled,
});
export const requestQuotasFailure = (error): IRequestQuotasActionFailure => ({
    type: actions.requestQuotasAction.rejected,
    payload: error
});
export const resetRequestQuotas = (): IResetRequestQuotasAction => ({
    type: actions.resetRequestQuotasAction,
});