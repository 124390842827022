import * as Mixpanel from "mixpanel-browser";
export const trackEvent = (eventName: string, ...args: string[]) => {
  Mixpanel.track(
    eventName
    // args.length ? {args} : undefined
  );
  hj("tagRecording", [
    eventName
    // ...args
  ]);
};
