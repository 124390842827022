export const FACEBOOK = 'Facebook';
export const TWITTER = 'Twitter';
export const INSTAGRAM = 'Instagram';
// export const SNAPCHAT = 'SnapChat';
export const OTHERS = 'Others';

export const socialLinksArr = [
  FACEBOOK,
  TWITTER,
  // SNAPCHAT,
  INSTAGRAM,
  OTHERS,
];

export enum SocialLinksEnum {
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  // SNAPCHAT,
  OTHERS,
}
