import React from "react";
import * as styles from './styles.module.sass';
import { MODES } from "../..";
import DownloadAppContent from "./DownloadAppContent";
import { useKindaTranslate } from "../PhoneEntry";

export interface IThankYouProps {
  store: {
    image: string;
  }
  mode: MODES;
  campaignPoints?: number;
  backgroundColor: string;
  OS: 'IOS' | 'ANDROID' | null;
  formatText?: (str: string, {count: number}?) => string;
  isSelected?: boolean;
  onSendEmail?: (email: string) => Promise<any>;
  isPointsIncentive?: boolean;
  campaign_gift_name?: string;
  download_title: string;
}


const modesClasses = {
  [MODES.EXAMPLE]: styles.example,
  [MODES.MOCK_UP]: styles.mockUp,
  [MODES.LIVE_APP]: styles.liveApp,
};



export const ThankYouLayout: React.FC<IThankYouProps> = ({
  mode,
  store: { image },
  children,
  formatText,
  campaignPoints = 0,
  isPointsIncentive = true,
  campaign_gift_name = '',
}) => {

  const t = useKindaTranslate(formatText);
  return (
    <>
      <div className={`${styles.mainContent} ${modesClasses[mode]}`}>
        <div className={`${styles.lowerPanel} ${modesClasses[mode]}`}>
          <img src={require('../../assets/koinz-logo.svg')} className={styles.logo}/>
          <p className={styles.congrats}>{t('Congrats!')}</p>
          <div className={styles.giftWrapper}>
            <img src={require('../../assets/gift-opened.svg')} className={styles.gift}/>
            <img src={require('../../assets/line.svg')} className={styles.line}/>
            <img src={image} className={styles.storeImage} />
          </div>
          <p className={styles.points}>{isPointsIncentive ? 
          t('You won 20 points!', {count: campaignPoints}) :
          t('You won a Cake', {campaign_gift_name})
        }</p>
          <div className={styles.separator}/>
          {children}
        </div>
      </div>
    </>
  )
};

const ThankYou: React.FC<IThankYouProps> = (props) => (
  <ThankYouLayout {...props}>
    <DownloadAppContent {...props} />
  </ThankYouLayout>
);

export default ThankYou;
