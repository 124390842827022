import * as React from "react";
import { Route } from "react-router";
import CustomerAcquisitionFormMain from "../OnlineToOfflineCampaigns/OnlineToOfflineFormMain";
import OfflineToOnlineSpecialEvents from "../OnlineToOfflineCampaigns/OnlineToOfflineSpecialCampaignForm";
import RecentOTOs from "../OnlineToOfflineCampaigns/RecentOTOs";
import EditOTOCampaign from "../OnlineToOfflineCampaigns/EditOTOCampaign";
import ViewOTOCampaign from "../OnlineToOfflineCampaigns/ViewOTOCampaign";
// import OnlineToOfflinePublish from "../OnlineToOfflineCampaigns/OnlineToOfflinePublish";

class OTOCampaignsAuthenticatedRoutes extends React.Component<{}> {
  public render() {
    return (
      <>
        <Route
          exact={true}
          component={CustomerAcquisitionFormMain}
          path="/online-to-offline/new"
        />
        <Route
          exact={true}
          component={OfflineToOnlineSpecialEvents}
          path="/online-to-offline/new/special"
        />
        <Route
          exact={true}
          component={RecentOTOs}
          path="/online-to-offline/history"
        />
        <Route
          exact={true}
          component={EditOTOCampaign}
          path="/online-to-offline/edit"
        />
        <Route
          exact={true}
          component={ViewOTOCampaign}
          path="/online-to-offline/view"
        />
        {/*<Route exact={true} component={OnlineToOfflinePublish} path="/online-to-offline/publish" />*/}
      </>
    );
  }
}

export default OTOCampaignsAuthenticatedRoutes;
