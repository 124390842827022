import {
    IDeleteCampaignAction,
    IDeleteCampaignActionFailure,
    IDeleteCampaignActionSuccess,
    IDeleteCampaignResponse
} from "../../types";
import {deleteCampaignAction} from "../../constants";

export const deleteCampaign=(id:string):IDeleteCampaignAction=>({
    type:deleteCampaignAction.requested,
    payload:id
});
export const deleteCampaignSuccess=(data:IDeleteCampaignResponse):IDeleteCampaignActionSuccess=>({
    type:deleteCampaignAction.fulfilled,
    payload:data
});
export const deleteCampaignFailure=(error):IDeleteCampaignActionFailure=>({
    type:deleteCampaignAction.rejected,
    payload:error
});

