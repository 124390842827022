import OnlineToOfflineIncentive from "../OnlineToOfflineIncentive";
import React from "react";
import ShopXPointsIncentive from "../OnlineToOfflineIncentive/ShopXPointsIncentive";
import * as styles from './styles.module.sass'
import { WrappedFieldProps, Field } from "redux-form";
import GiftItemsHandler from "../GiftInboxCommon/GiftItemsHandler";

interface IProps {
  isEdit?: boolean;
}

export const OTOTabNavigation: React.FC<WrappedFieldProps & IProps> = (props) => {
  return (
    <>
      <p className={styles.header}>Select Incentive</p>
      <p className={styles.para}>Select to either send points or vouchers to<br /> your customers</p>
      <OnlineToOfflineIncentive disabled={props.isEdit} {...props}>
        <ShopXPointsIncentive />
        <Field disabled={props.isEdit} component={GiftItemsHandler} name='gift_tab_index'/>
      </OnlineToOfflineIncentive>
    </>
  );
};
