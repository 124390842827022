import * as React from "react";
import * as styles from './styles.module.sass';
import NavigationItems from './NavigationItems/NavigationItems';
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {useContext} from "react";
import StoreData from "../../contexts/StoreData";
import {IStore} from "../../types";
import NavigationItem from "./NavigationItems/NavigationItem/NavigationItem";
import { imagesURL } from "../../../Utils/helpers";


const NavBar: React.FunctionComponent = () => {
  const state = useContext(StoreData);
  const store = state.storeData as IStore;
  return (
    <nav className={styles.navBar}>
      <img className={styles.header} src={require('../../assets/navbar-icon.png')} />
      <div className={styles.items}>
        <span className={styles.title}>Main</span>
        <NavigationItems/>
        <div className={styles.separator}/>
        <span className={styles.title}>Support</span>
        <NavigationItem link="/info" exact={true}>
          <img src={require('../../assets/info.svg')}/>
          <p>Info</p>
        </NavigationItem>
      </div>
      {/*<div className={styles.secondPart}>*/}
      {/*    <span style={{cursor: 'pointer'}} onClick={this.goToInfo} className={styles["icon-Information"]}/>*/}
      {/*    <span className={styles["icon-setting"]}/>*/}
      {/*</div>*/}
      <div style={{marginTop: 'auto', width: '100%'}}>
        <div style={{margin: 0}} className={styles.separator}/>
        <UncontrolledDropdown direction={'up'}>
          <DropdownToggle className={styles.userDD}>
            <div style={{margin: '0 auto'}} className={styles.userDD}>
              <img className={styles.brandImg} src={imagesURL + (store.store_image as any)}/>
              <div className={styles.userData}>
                <p className={styles.brandName}>{store.name}</p>
              </div>
            </div>
            <img className={styles.dd} src={require('../../assets/icon-dropdownD.svg')}/>
          </DropdownToggle>
          <DropdownMenu className={styles.menu}>
            {/*<DropdownItem onClick={onClickLogout} className={styles.item}>{t(Strings.logout)}</DropdownItem>*/}
          </DropdownMenu>
        </UncontrolledDropdown>
        <div style={{margin: 0}} className={styles.separator}/>
        <p className={styles.footer}>© Koinz LLC. 2019</p>
      </div>
    </nav>
  );
};

export default NavBar;
