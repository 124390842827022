import {Modal, ModalBody} from "reactstrap";
import * as React from "react";
import * as styles from "./styles.module.sass";
import {RouteComponentProps, withRouter} from "react-router";
import {IQuota} from "../../../types";


interface IProps {
    modal: boolean;
    quota: IQuota;
}

class QuotasSuccessModal extends React.Component<IProps & RouteComponentProps> {
    public handleSubmit = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    };

    public render() {
        const {quota: {name}, modal} = this.props;
        return (
            <Modal isOpen={modal} className={styles.content}>
                <ModalBody className={styles.body}>
                    <div className={styles.wrapper}>
                        <div className={styles.title}>
                            We received your request
                        </div>
                        <div className={styles.paragraph}>
                            You have booked: {name}
                        </div>
                        <img className={styles.logo} src={require('../../../assets/phone.svg')}/>
                        <div className={styles.paragraph}>
                            Wait for our call to confirm
                        </div>
                        <button onClick={this.handleSubmit} className={styles.confirmBtn}><span
                            className={styles.arrow}>&lt;</span>Return to home
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}


export default withRouter(QuotasSuccessModal);
