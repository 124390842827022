import SocialMediaDistChart from "./SocialMediaDistChart";
import React, { useContext, useEffect, useState } from "react";
import * as styles from './styles.module.sass';
import { getOTOCampaignSocialMediaConversion } from "../../../axios/getOTOCampaignSocialMediaConversion";
import StoreData from "../../../contexts/StoreData";
import { useProgressState } from "../../../../Utils/custom-hooks/useProgressState";
import { FACEBOOK, TWITTER, INSTAGRAM, OTHERS, socialLinksArr } from "./lib";

interface IStatListItemProps {
  color: string;
  name: string;
  value: number;
  percentage: number;
}


const socialStatsInitialData = {
  [FACEBOOK]: {
    percentage: 0,
    value: 0,
    color: '#1877f1'
  },
  [TWITTER]: {
    percentage: 0,
    value: 0,
    color: '#1d9dec'
  },
  [INSTAGRAM]: {
    percentage: 0,
    value: 0,
    color: '#d62978'
  },
  // [SNAPCHAT]: {
  //   percentage: 0,
  //   value: 0,
  //   color: '#f8f500'
  // },
  [OTHERS]: {
    percentage: 0,
    value: 0,
    color: '#9b9b9b'
  },
  series: [0, 0, 0, 0, 0]
}

const StatListItem: React.FC<IStatListItemProps> = ({
  color,
  name,
  percentage,
  value,
}) => {
  return (
    <div className={styles.stat}>
      <div className={styles.statNameAndDot}>
        <div className={styles.dot} style={{ backgroundColor: color }} />
        <div className={styles.statName}>
          {name}
        </div>
      </div>
      <div className={styles.statValues}>
        <div className={styles.value}>{value}</div>
        <div className={styles.percentage}>{percentage}%</div>
      </div>
    </div>
  )
};

const SocialMediaConversion: React.FC<{ campaignId: string }> = ({ campaignId }) => {
  const [socialMediaStats, setSocialMediaStats] = useState(socialStatsInitialData);
  const { setFailure, setSuccess, setLoading } = useProgressState();
  const { token } = useContext(StoreData);
  useEffect(() => {
    setLoading();
    getOTOCampaignSocialMediaConversion(token, campaignId).then(({ data }) => {
      const siteKeys = Object.keys(data);
      const total = siteKeys.reduce((acc, key) => acc + data[key], 0);
      const socialData = siteKeys.reduce((acc, key) => {
        const foundSocialChannel = socialMediaStats[key];
        const value = data[key];
        const percentage = Math.round((value / total) * 100);
        const statKey = foundSocialChannel ? key : OTHERS;
        const other = acc[OTHERS];
        const patch = foundSocialChannel ? { value, percentage, } : {
          value: other.value + value,
          percentage: Math.round(((other.value + value) / total) * 100)
        };
        return {
          ...acc,
          [statKey]: {
            ...acc[statKey],
            ...patch,
          },
        }
      }, socialStatsInitialData);
      setSocialMediaStats({
        ...socialData,
        series: socialLinksArr.map((linkName) => socialData[linkName].value || 0)
      });
      setSuccess();
    }).catch(setFailure)
  }, []);

  return (
    <div className={styles.wrapper}>
      <OTOStatPanelTitle
        title={'Platform Conversion'}
        subTitle={'This is how your campaign preformed on social platforms'} />
      <div className={styles.chartWrapper}>
        <SocialMediaDistChart chartSeries={socialMediaStats.series} />
      </div>
      <div className={styles.statsList}>
        {
          socialLinksArr.map(key => <StatListItem
            key={key}
            color={socialMediaStats[key].color}
            name={key}
            value={socialMediaStats[key].value}
            percentage={socialMediaStats[key].percentage} />)
        }
      </div>
    </div>
  );
};

export const OTOStatPanelTitle = ({ title, subTitle }) => {
  return (
    <>
      <h3 className={styles.title}>
        {title}
      </h3>
      <h4 className={styles.subTitle}>
        {subTitle}
      </h4>
    </>
  )
};

export default SocialMediaConversion;
