import axios from './axios';
import { AxiosReturn } from "./types";
import * as Requests from './requests';
import { ICataloguedItems } from "../types/wizard-types";

export const getStoreItems = (token): AxiosReturn<ICataloguedItems[]> => {
  return axios.get(Requests.storeItems, {
    headers: {
      token,
    }
  });
};
