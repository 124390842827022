import * as React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { Field } from "redux-form";
import { RenderGenericField } from "../../../FormElements/RenderGenericField";
import {
  isEmpty,
  maxLength,
  required
} from "../../../../redux-store/validators";
import { RenderPhotoPicker } from "../../../FormElements/RenderPhotoPicker";
const Strings = {
  uploadImageButtonText: "Upload Image",
  uploadImageDescription: "Image should be at least 4KB and at most 1MB",
  unsupportedImageType: "Unsupported file type..."
};
const ReviewsStrings = {
  customGiftImageTitle: "Item image",

  customGiftNameTitle: "Item name",

  sendGiftButtonTitle: "Send Gift",

  voucherNamePlaceholder: "Ex. 50% off on meal...",

  next: "Next"
};
import ImageTools from "./imageResizer";
import { compressBase64Images } from "../../../../../Utils/helpers";

export const resizeImage = (image: File) => {
  return new Promise<string>(res => {
    ImageTools.resize(
      image,
      {
        width: 300,
        height: 300
      },
      (blob: Blob, didItResize: boolean) => {
        if (didItResize) {
          res(compressBase64Images(blob));
        }
        res(compressBase64Images(image));
      }
    );
  });
};

const AddSpecialItem: React.FC<{}> = props => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.separator} />
        <Field
          name="voucher.image"
          type="file"
          description={Strings.uploadImageDescription}
          buttonText={Strings.uploadImageButtonText}
          unsupportedFileTypeText={Strings.unsupportedImageType}
          label={ReviewsStrings.customGiftImageTitle}
          component={RenderPhotoPicker}
          validate={[required, maxLength(85)]}
        />
        <div className={styles.separator} />
        <Field
          name="voucher.name"
          placeholder={ReviewsStrings.voucherNamePlaceholder}
          type="text"
          className={styles.input}
          component={RenderGenericField}
          label={ReviewsStrings.customGiftNameTitle}
          validate={[required, isEmpty, maxLength(85)]}
        />
        <div className={styles.separator} />
        {/* <button type='submit' className={styles.btn}>
          {ReviewsStrings.next}
        </button> */}
      </div>
    </div>
  );
};

export default hot(module)(AddSpecialItem);
