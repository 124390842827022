export enum MODES {
  MOCK_UP = 0,
  EXAMPLE = 1,
  LIVE_APP = 2,
};

const PhoneEntry = 'First Page';
type PhoneEntry = typeof PhoneEntry;

const ThankYou = 'Second Page';
type ThankYou = typeof ThankYou;

interface IScreenNames {
  PhoneEntry: typeof PhoneEntry;
  ThankYou: typeof ThankYou;
}

export const ScreenNames: IScreenNames = {
  PhoneEntry,
  ThankYou,
}

export type MiniAppScreen = PhoneEntry | ThankYou;

// lib, data, and export point for all of three wrappers
