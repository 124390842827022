import { RouteComponentProps, Route, Redirect, withRouter } from "react-router";
import React, { useMemo, useCallback } from "react";
import PhoneEntry from "../MiniApp/PhoneEntry";
import MiniApp from "../MiniApp";
import { MODES } from "..";
import ThankYou from "../MiniApp/ThankYou";
import { hot } from "react-hot-loader";
import SpecialOTOEntry from "../MiniApp/SpecialOTOEntry";
import ThankYouSpecialOTO from "../MiniApp/ThankYouSpecialEntry";
import { CampaignLanguage } from "../../../axios/getCampaignData";

interface IExampleWrapperProps extends RouteComponentProps {
  storeImage: string;
}

const ExampleWrapper: React.FC<IExampleWrapperProps> = ({
  match: { url },
  history,
  location,
  storeImage,
}) => {
  const storeData = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const background_color = params.get("background_color") as string;
    const text_color = params.get("text_color") as string;
    const description = params.get("description") as string;
    const title = params.get("title") as string;
    const download_title = params.get("download_title") as string;
    return {
      background_color: "#" + background_color,
      text_color: "#" + text_color,
      title,
      description,
      download_title,
    };
  }, []);
  const currentCampaignLanguage = localStorage.getItem("campaign_language") as
    | CampaignLanguage
    | undefined;

  const campaign_logo = localStorage.getItem("campaign_logo") as
    | string
    | undefined;

  const currentCampaignType = localStorage.getItem("campaign_tyoe");
  const goToThankYou = useCallback(() => history.push(`${url}/finished`), []);
  const renderPhoneEntry = useCallback(() => {
    return (
      <PhoneEntry
        onSendPhoneNumber={goToThankYou}
        campaign={{
          backgroundColor: storeData.background_color,
          textColor: storeData.text_color,
          campaign_page_description: storeData.description,
          campaign_page_title: storeData.title,
          current_users_count: 92,
          max_users: "115",
        }}
        store={{
          image: storeImage,
        }}
        mode={MODES.EXAMPLE}
      />
    );
  }, [storeData, storeImage]);

  // OTO special events
  const renderSpecialOTOEntry = useCallback(() => {
    return (
      <SpecialOTOEntry
        onSendPhoneNumber={goToThankYou}
        campaign={{
          backgroundColor: storeData.background_color,
          textColor: storeData.text_color,
          campaign_page_description: storeData.description,
          campaign_page_title: storeData.title,
          current_users_count: 92,
          max_users: "115",
          campaign_language: currentCampaignLanguage || undefined,
          campaign_logo: campaign_logo,
        }}
        store={{
          image: storeImage,
        }}
        mode={MODES.EXAMPLE}
      />
    );
  }, [storeData, storeImage]);

  const renderThankYou = useCallback(
    () => (
      <ThankYou
        download_title={storeData.download_title}
        OS={null}
        backgroundColor={storeData.background_color}
        store={{
          image: storeImage,
        }}
        mode={MODES.EXAMPLE}
      />
    ),
    [storeImage]
  );

  // OTO special events
  const renderSpecialOTOThankYou = useCallback(
    () => (
      <ThankYouSpecialOTO
        download_title={storeData.download_title}
        OS={null}
        backgroundColor={storeData.background_color}
        store={{
          image: storeImage,
        }}
        mode={MODES.EXAMPLE}
        campaignLanguage={currentCampaignLanguage || undefined}
        secondPageHeading={storeData.download_title}
        campaign_logo={campaign_logo}
      />
    ),
    [storeImage]
  );

  return (
    <MiniApp
      backgroundColor={storeData.background_color}
      mode={MODES.EXAMPLE}
      textColor={storeData.text_color}
    >
      <Route
        render={
          currentCampaignType === "special_event_OTO"
            ? renderSpecialOTOEntry
            : renderPhoneEntry
        }
        exact={true}
        path={`${url}/registration`}
      />
      <Route
        render={
          currentCampaignType === "special_event_OTO"
            ? renderSpecialOTOThankYou
            : renderThankYou
        }
        exact={true}
        path={`${url}/finished`}
      />
      <Redirect from={`${url}/`} exact={true} to={`${url}/registration`} />
    </MiniApp>
  );
};

export default hot(module)(withRouter(ExampleWrapper));
