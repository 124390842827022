import * as React from "react";
import * as styles from './styles.module.sass';
import {WrappedFieldProps} from 'redux-form';

interface IProps {
    label: string;
    type: string;
    placeholder: string;
    className?: string;
    disabled?: boolean;
}

const CountSMS = (input) => {
    const hasMoreThanAscii = [...input].some(char => char.charCodeAt(0) > 127);
    let charsInSMS = 0;
    if (hasMoreThanAscii) {
        if (input.length > 70) {
            charsInSMS = 67;
        } else {
            charsInSMS = 70;
        }
        return {
            smsCount: Math.ceil((input.length) / charsInSMS),
        };
    }
    else {
        if (input.length > 160) {
            charsInSMS = 153;
        } else {
            charsInSMS = 160;
        }
        return {
            smsCount: Math.ceil((input.length) / charsInSMS),
        }
    }
};

interface IState {
    charsUsed: number;
    smsCount: number;
}

export class RenderSMSTextAreaGenericField extends React.Component<WrappedFieldProps & IProps, IState> {
    constructor(props) {
        super(props);

        const {smsCount} = CountSMS(props.input.value);
        const charsUsed = props.input.value.length;
        this.state = {
            charsUsed,
            smsCount,
        };
    }

    public handleChange = (event) => {
        const input = event.target.value;
        const charsUsed = input.length;
        const {smsCount} = CountSMS(input);
        this.setState({charsUsed, smsCount});
        this.props.input.onChange(input);
    };

    public render() {
        const {
            input,
            meta: {touched, error, warning},
            placeholder,
            disabled,
            label,
            className
        } = this.props;
        return (
            <div className={`${styles.wrapper} ${className || ''}`}>
                <div className={styles.textWrapper}>
                    <h1>{label}</h1>
                    <div className={styles.countersWrapper}>
                        <p>Characters Count: {this.state.charsUsed}</p>
                        <p>SMS Count: {this.state.smsCount}</p>
                    </div>
                </div>

                <div className={styles.textAreaWrapper}>
                    <textarea  {...input} disabled={disabled} placeholder={placeholder} className={styles.textArea}
                               value={input.value} onChange={this.handleChange}/>
                </div>
                {touched && ((error && <span className={styles.formError}>{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        );
    }
}
