import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import * as React from "react";
import { Field } from "redux-form";
import { isEmpty, maxLength, required } from "../../../redux-store/validators";
import { RenderGenericField } from "../../FormElements/RenderGenericField";

export const maxLength100 = maxLength(100);

const OnlineToOfflineName: React.FunctionComponent = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.header}>Choose Campaign name</p>
      <p className={styles.para}>
        A clear name will make it easier to track your campaign in the future.
      </p>
      <Field
        className={styles.width}
        name="name"
        type="text"
        placeholder="Campaign name"
        component={RenderGenericField}
        label={"Campaign name"}
        validate={[required, isEmpty, maxLength100]}
      />
    </div>
  );
};

export default hot(module)(OnlineToOfflineName);
