import * as React from "react";
import * as styles from "./styles.module.sass";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ITabItemProps } from "./components/TabItem";
import { hot } from "react-hot-loader";
import { noop } from "redux-saga/utils";


interface ITabNavigationProps {
  children: Array<React.ReactElement<ITabItemProps> | null>;
  disabled?: boolean;
  initialTabIndex?: number;
  onChangeTab?: (tabIndex?: number) => void
}


const TabNavigation: React.FC<ITabNavigationProps> = props => {
  const [currentTabIndex, setCurrentTabIndex] = React.useState(props.initialTabIndex || 0)

  const handleChange = (event, nextTabIndex) => {
    if (props.onChangeTab) {
      props.onChangeTab(nextTabIndex);
    }
    setCurrentTabIndex(nextTabIndex);
  };
  return (
    <div className={styles.root}>
      <Tabs
        value={currentTabIndex}
        onChange={props.disabled ? noop : handleChange}
        variant="fullWidth"
        classes={{
          indicator: styles.indicator
        }}>
        {
          React.Children.map(props.children, ((child: React.ReactElement<ITabItemProps>) => {
            return child && <Tab disabled={props.disabled} className={styles.tab} classes={
              {
                selected: styles.selectedTab,
                disabled: styles.disabled,
              }
            } label={child.props.title} />
          }))
        }
      </Tabs>
      {
        React.Children.map(props.children, (child, index) => {
          if (index === currentTabIndex) {
            return child
          }
          return null;
        })
      }
    </div>
  );
}

export default hot(module)(TabNavigation);
