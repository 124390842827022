import * as React from "react";
import * as styles from './styles.module.sass';
import * as giftListStyles from '../GiftListItem/styles.module.sass';
import { hot } from "react-hot-loader";
import GiftListItem from "../GiftListItem";
import Skeleton from 'react-skeleton-loader';
import { useProgressState } from "../../../../../Utils/custom-hooks/useProgressState";
import StoreData from "../../../../contexts/StoreData";
import { IStore } from "../../../../types";
import { WrappedFieldProps, Field } from "redux-form";
import { IStoreItem } from "../../../../types/wizard-types";
const Strings = {
  somethingWentWrong: 'Something went wrong!',
  emptyItems: 'Couldn\'t find any items with the selected criteria!',
  itemSearchTitle: 'Item Search',
  itemSearchPlaceholder: 'Search for an item',
};

import formElementsStyles from '../../../FormElements/styles.module.sass';
import { getStoreItems } from "../../../../axios/getStoreItems";
import { getSpecialItems } from "../../../../axios/getSpecialItems";
import { required } from "../../../../redux-store/validators";


interface IProps {
  setSelectedItem: (selectedItem: IStoreItem | undefined) => void;
  menu_item_id: string;
  menuItemError: boolean;
}

const getAllItems = async (token: string) => {
  try {
    const {data: storeCategories = []} = await getStoreItems(token);
    const storeItems = storeCategories.reduce((acc, nextCat) => {
      return [
        ...acc,
        ...nextCat.items,
      ]
    }, []);
    const {data} = await getSpecialItems(token);
    const {items: specialItems} = data || {items: []}

    return [
      ...storeItems,
      ...specialItems,
    ]
  } catch (e) {
    throw [];
  }
}

const useGiftListItems = (search = '') => {
  const { token } = React.useContext(StoreData);
  const [items, setItems] = React.useState<IStoreItem[]>([]);
  const { failure, success, setSuccess, setFailure, setLoading } = useProgressState();

  React.useEffect(() => {
    setLoading();
    getAllItems(token)
      .then((allItems) => {
          setSuccess();
          setItems(allItems.sort((a, b) => a.price - b.price));
      })
      .catch(setFailure);
  }, [token]);

  const filteredItems = React.useMemo(() => {
    return items.filter(item => {
      return item.name.includes(search);
    })
  }, [search, items]);

  return {
    items: filteredItems,
    failure,
    success,
  }
}




const AddFromMenu: React.FC<IProps> = ({ setSelectedItem, menuItemError, menu_item_id }) => {
  const [search, setSearch] = React.useState('');
  const { storeData } = React.useContext(StoreData);
  const { items, failure, success } = useGiftListItems(search);

  const [selectedItemId, setSelectedItemId] = React.useState<string>(menu_item_id)

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSelectItem = (itemId?: string) => {
    setSelectedItem(items.find(a => a.id === itemId));
    setSelectedItemId(itemId || '')
  }

  let render = (
    <>
      <h5 className={styles.header}>
        <Skeleton widthRandomness={0} width={'10rem'} height={'2rem'} />
      </h5>
      <div className={styles.categorisedItems}>
        {new Array(5).fill(0).map((a, i) => {
          return (
            <div key={i} className={giftListStyles.wrapper}>
              <div className={giftListStyles.cardWrapper}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton widthRandomness={0} width={'6rem'} height={'6rem'} borderRadius={'50%'} />
                  <div className={giftListStyles.dataWrapper}>
                    <p className={giftListStyles.itemName}>
                      <Skeleton widthRandomness={0} width={'10rem'} height={'1.5rem'} />
                    </p>
                    <div style={{ margin: '.5rem 0' }} />
                    <p className={giftListStyles.itemPrice}>
                      <Skeleton widthRandomness={0} width={'5rem'} height={'1.5rem'} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );

  if (failure) {
    render = (<div style={{ fontSize: '2rem' }}>{Strings.somethingWentWrong}</div>);
  }
  if (success) {
    render = (
      <>
        {items.length ?
          (
            <>
              <div className={styles.categorisedItems}>
                {items
                  .map(item =>
                    <GiftListItem
                      iso_code={(storeData as IStore).country_iso_code}
                      key={item.id}
                      setSelectedItemId={handleSelectItem}
                      selectedItemId={selectedItemId}
                      item={item} />)}
              </div>
            </>
          )
          : <div className={styles.noItems}>{Strings.emptyItems}</div>}
      </>
    );
  }
  return (
    <div className={styles.wrapper}>
      {menuItemError && <span style={{ alignSelf: 'flex-start' }} className={formElementsStyles.formError}>{menuItemError}</span>}
      <div className={styles.box}>
        <div className={styles.fieldWrapper}>
          <label className={styles.label}>{Strings.itemSearchTitle}</label>
          <div className={styles.search}>
            <input className={styles.input} maxLength={30}
              value={search}
              disabled={!success}
              placeholder={Strings.itemSearchPlaceholder} type="text"
              onChange={handleChange} />
            <img src={require('../assets/search.svg')} className={styles.logo} />
          </div>
        </div>
      </div>
      <div className={styles.giftsWrapper}>
        {render}
      </div>
    </div>
  )
}

const AddFromMenuField: React.FC<WrappedFieldProps> = (props) => {
  const { meta: { touched, error}, input: { value, onChange, onBlur},  } = props;
  const handleChange = React.useCallback((item: IStoreItem | undefined) => {
    if (item) {
      onChange(item.id);
    } else {
      onBlur(null);
    }
  }, [onChange]);
  return (
    <AddFromMenu
      menuItemError={touched && error}
      menu_item_id={value as string}
      setSelectedItem={handleChange}
    />
  )
}

const AddFromMenuWrappedElement = () => {
  return (
    <Field name='item_id' validate={[required]} component={AddFromMenuField} />    
  )
}

export default hot(module)(AddFromMenuWrappedElement);
