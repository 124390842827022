import * as React from "react";
import * as styles from './styles.module.sass';
import {required} from "../../../redux-store/validators";
import {RenderGenericField} from "../../FormElements/RenderGenericField";
import {Field} from "redux-form";

interface IProps {
    onClick: () => void;
    disabled: boolean;
    canEdit?: boolean;
}


class EditHeader extends React.Component<IProps> {

    public handleClick = () => {
        this.props.onClick();
    };

    public render() {
        return (
            <div className={styles.header}>
                <div className={styles.nameSection}>
                    <span className={styles.arrow}>Campaign Name: </span>
                    <Field
                        name='campaignName'
                        placeholder=''
                        type='text'
                        component={RenderGenericField}
                        disabled={this.props.disabled}
                        validate={[required]}
                    />

                </div>
                {this.props.canEdit && <button onClick={this.handleClick} type='button' className={styles.editBTN}>
                    Enable Edit Campaign
                </button>}
            </div>
        );
    }
}

export default EditHeader;
