import { IDailyStatsSelect, ChartTypes, chartTypesSelectItems } from ".";

import { useMemo, useState, useEffect, useCallback } from "react";
import { ISelectItemData } from "../../../FormElements/RenderDropDownSelect";

const noop = () => null;

export const useSelect = (onChange?: IDailyStatsSelect['onChange'], value?: ChartTypes) => {
  const handleChangeExternal = useMemo(() => onChange || noop, [onChange]);
  const [selectedChartType, setSelectedChartType] = useState(chartTypesSelectItems[0]);

  useEffect(() => {
    handleChange(chartTypesSelectItems.find(({ value: v }) => v === value || v === selectedChartType.value) as ISelectItemData<ChartTypes>);
  }, [value]);

  const handleChange = useCallback((newValue: ISelectItemData<ChartTypes>) => {
    if (newValue.value !== selectedChartType.value) {
      setSelectedChartType(newValue);
      handleChangeExternal(newValue);
    }
  }, [handleChangeExternal, setSelectedChartType, selectedChartType]);
  return { handleChange, selectedChartType };
};
