import Chart, {Props} from 'react-apexcharts';
import {ApexOptions} from 'apexcharts'
import React from 'react';
import { FACEBOOK, TWITTER, INSTAGRAM, OTHERS } from '../lib';


const chartOptions: ApexOptions = {
  labels: [FACEBOOK, TWITTER, INSTAGRAM, OTHERS],
  stroke: {
    width: 0,
  },
  dataLabels: {
    enabled: false,
  },
  colors: [
    '#1877f1',
    '#1d9dec',
    '#d62978',
    // '#f8f500',
    '#9b9b9b'
  ],
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          total: {
            label: 'Total conversions',
            show: true,
            color: '#453937',
          },
          name: {
            offsetY: 20,
            color: '#453937',
            fontSize: '1.6rem',
          },
          value: {
            fontSize: '4rem',
            offsetY: -20,
            color: '#453937',
          }
        },
        size: '85%',
      },
      expandOnClick: false,
    },
  },
  chart: {
    fontFamily: "Avenir, 'Avenir LT Std', 'Cairo', 'Roboto', sans-serif",
  },
  legend: {
    show: false,
  },
  fill: {
    opacity: 1
  }
};

const chartData: Props = {
  type: 'donut',
  options: chartOptions,
};

const SocialMediaDistChart: React.FC<{ chartSeries: number[] }> = ({chartSeries}) => {
  return (
    <Chart
      {...chartData}
      series={chartSeries}
    />
  );
};

export default SocialMediaDistChart;
