import { ISelectItemData } from "../../../FormElements/RenderDropDownSelect";
import { useSelect } from "./hooks";
import { motion, AnimatePresence, Variants } from 'framer-motion';
import React, { useCallback } from 'react'
import * as styles from './styles.module.sass';
import Arrow from '@material-ui/icons/KeyboardArrowDown'
import { useToggleState } from "../../../../../Utils/custom-hooks/useToggleState";


const list = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const listItem: Variants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
    y: 0,
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
    y: 20,
  },
};

export enum ChartTypes {
  Clicks = 'Clicks',
  PhoneNumbersAdded = 'PhoneNumbersAdded',
  Visits = 'Visits',
}

export const chartTypesSelectItems: Array<ISelectItemData<ChartTypes>> = [
  {
    label: 'Link visits',
    value: ChartTypes.Clicks,
  },
  {
    label: 'Phone numbers added',
    value: ChartTypes.PhoneNumbersAdded,
  },
  // {
  //   label: 'Store visits',
  //   value: ChartTypes.Visits,
  // },
];

export interface IDailyStatsSelect {
  // options: ISelectItemData[];
  value?: ChartTypes;
  onChange?: (chartType: ISelectItemData<ChartTypes>) => void;
}

const { div: Div } = motion;

const ListItem: React.FC<{ item: ISelectItemData; onSelect: (item: ISelectItemData) => void }> = ({
  item,
  onSelect
}) => {
  const handleSelect = useCallback(() => onSelect(item), [item.value]);
  return (
    <Div
      onClick={handleSelect}
      whileTap={{ scale: 0.9 }}
      className={styles.selectItem}
      initial='hidden'
      exit='hidden'
      whileHover={{ backgroundColor: '#ebebeb', }}
      animate='visible'
      variants={listItem}
      key={item.value}>
      {item.label}
    </Div>
  )
}

const DailyStatsSelect: React.FC<IDailyStatsSelect> = ({
  onChange,
  // options,
  value,
}) => {
  const { selectedChartType, handleChange } = useSelect(onChange, value);
  const [isOpen, toggle] = useToggleState();

  const handleSelect = (item: ISelectItemData<ChartTypes>) => {
    toggle();
    handleChange(item);
  }
  return (
    <Div className={styles.wrapper}>
      <Div className={styles.toggle} onClick={toggle}>
        <Div className={styles.selectedLabel}>
          {selectedChartType.label}
        </Div>
        <Div animate={{ rotate: isOpen ? '180deg' : '0deg' }}>
          <Arrow className={styles.arrow} />
        </Div>
      </Div>
      <AnimatePresence>
        {
          isOpen &&
          <Div
            variants={list}
            onClick={toggle}
            initial='hidden'
            exit='hidden'
            key='DailyStatsSelect'
            animate='visible'
            transition={{ duration: 0.4 }}
            className={styles.menu}>
            {
              chartTypesSelectItems.map((a) => (<ListItem item={a} key={`DailyStatsSelect-valuel-${a.value}`} onSelect={handleSelect} />))
            }
          </Div>
        }
      </AnimatePresence>
    </Div>
  );
}

export default DailyStatsSelect;
