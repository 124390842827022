import {
    ICreateCampaignAction, ICreateCampaignActionFailure,
    ICreateCampaignActionSuccess, IResetFailAction, ISetCampaignDataAction,
    ISetCampaignNameAction,
    ISetCampaignTypeAction
} from "../../types";
import * as actions from '../../constants';

export const setCampaignName = (name: string): ISetCampaignNameAction => ({
    type: actions.setCampaignNameAction,
    payload: name
});

export const setCampaignType = (data): ISetCampaignTypeAction => ({
    type: actions.setCampaignTypeAction,
    payload: data
});

export const resetFail=():IResetFailAction =>({
    type: actions.resetFailAction
});
export const setCampaignData = (): ISetCampaignDataAction => ({
    type: actions.setCampaignDataAction,
});
export const createCampaign = (): ICreateCampaignAction => ({
    type: actions.createCampaignAction.requested,
});
export const createCampaignSuccess = (): ICreateCampaignActionSuccess => ({
    type: actions.createCampaignAction.fulfilled,
});
export const createCampaignFailure = (error): ICreateCampaignActionFailure => ({
    type: actions.createCampaignAction.rejected,
    payload: error
});