import * as React from "react";
import * as styles from './styles.module.sass';
import Operation from "./Operation";
import {IRootReducerState} from "../../../redux-store/rootReducer";
import {ICampaignLog} from "../../../types";
import {connect} from "react-redux";
import {getCampaignsLog} from "../../../redux-store/actions/campaigns-log";
import {LoadingPageInfo} from "../../LoadingPageInfo";
import NoCampaignCreated from "../../NoCampaignCreated";
import {Title} from "react-head";
import {RouteComponentProps, withRouter} from "react-router";

interface IProps extends RouteComponentProps{
  campaignsLog: ICampaignLog[];
  getCampaignsLog: () => void;
  loaded: boolean;
  failed: boolean;
  loading: boolean;
  subHistory?: boolean;
}

interface IState {
  active: string,
}

enum CampaignStatuses {
  waiting_for_review_1 = 1,
  waiting_for_review_0 = 0,
  rejected = 2,
  scheduled = 3,
  running = 4,
  completed = 5,
}

class CampaignsHistory extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      active: 'All',
    };
  }

  public onBtnActive = (active) => {
    this.setState({active: active.target.value});
  };

  public componentDidMount() {
    this.props.getCampaignsLog();
  }

  public onShowAllClicked = () => this.props.history.push('/sms/history');

  public render() {
    const {loaded, failed, campaignsLog, subHistory} = this.props;
    const {active} = this.state;
    let render = <LoadingPageInfo/>;
    if (loaded) {
      render = (<div className={styles.operationsWrapper}>
          <div className={styles.header}>
            <p className={styles.th1}>Campaign name</p>
            <p className={styles.th2}>Status</p>
            <p className={styles.th3}>Starting Date</p>
            <p className={styles.th4}>Target Users</p>
            <div style={{width: '10%'}}/>
          </div>
          {campaignsLog.length ? campaignsLog.sort((a, b) => b.created_at - a.created_at)
              .filter(campaign => {

                if (active === 'All') {
                  return true;
                } else if (active === 'Waiting for review' && (campaign.status === CampaignStatuses.waiting_for_review_0
                  || campaign.status === CampaignStatuses.waiting_for_review_1)) {
                  return true;
                } else if (active === 'Scheduled' && campaign.status === CampaignStatuses.scheduled) {
                  return true;
                } else if (active === 'Rejected' && campaign.status === CampaignStatuses.rejected) {
                  return true;
                } else if (active === 'Running' && campaign.status === CampaignStatuses.running) {
                  return true;
                } else {
                  return !!(active === 'Completed' && campaign.status === CampaignStatuses.completed);
                }
              }).map(campaign => <Operation {...{}} campaign={campaign}
                                            key={campaign.id}/>
              ) :
            <div className={styles.rejectedText}>
              <NoCampaignCreated
                message='There are no SMS campaigns, Creat a new one now!'
                url='/sms/new'
              />
            </div>}
        </div>
      );
    }
    if (failed) {
      render = (
        <div className={styles.operationsWrapper}>
          <div className={styles.rejectedText}>Failed to load campaigns</div>
        </div>);
    }
    return (
      <div style={{padding: !subHistory ? '4.4rem 2.6rem' : '0 2.5rem'}} className={styles.wrapper}>
        <h1 className={styles.title}>
          Recent SMS Campaigns
          {subHistory && <button onClick={this.onShowAllClicked} className={styles.showAll}>
            Show all
          </button>}
        </h1>
        <Title>{subHistory ? 'Home' : 'History'}</Title>
        {!subHistory && <div className={styles.filterWrapper}>
          <h1 className={styles.textWrapper}>Filters</h1>
          <div className={styles.btnWrapper}>
            <button onClick={this.onBtnActive}
                    className={active === 'All' ? styles.btnActive : styles.btn} value='All'>All
            </button>
            <button onClick={this.onBtnActive}
                    className={active === 'Waiting for review' ? styles.btnActive : styles.btn}
                    value='Waiting for review'>Waiting for review
            </button>
            <button onClick={this.onBtnActive}
                    className={active === 'Scheduled' ? styles.btnActive : styles.btn}
                    value='Scheduled'>Scheduled
            </button>
            <button onClick={this.onBtnActive}
                    className={active === 'Rejected' ? styles.btnActive : styles.btn}
                    value='Rejected'>Rejected
            </button>
            <button onClick={this.onBtnActive}
                    className={active === 'Completed' ? styles.btnActive : styles.btn}
                    value='Completed'>Completed
            </button>
          </div>
        </div>}
        {render}
      </div>
    );
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  campaignsLog: state.campaignsLogReducer.campaignsLog,
  loading: state.campaignsLogReducer.loading,
  loaded: state.campaignsLogReducer.loaded,
  failed: state.campaignsLogReducer.failed,
});
const mapDispatchToProps = (dispatch) => ({
  getCampaignsLog: () => dispatch(getCampaignsLog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CampaignsHistory))
