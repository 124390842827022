import React from "react";
import {Field, InjectedFormProps, reduxForm, formValueSelector} from "redux-form";
import {RenderSMSTextAreaGenericField} from "../../FormElements/RenderSMSTextAreaGenericField";
import {fullTimeValidator, required} from "../../../redux-store/validators";
import {RenderFullTimePicker} from "../../FormElements/RenderFullTimePicker";
import {RenderChipsField} from "../../FormElements/RenderChipsField";
import {RenderTwoGenericFields} from "../../FormElements/RenderTwoGenericFields";
import {RenderDropDownSelect} from "../../FormElements/RenderDropDownSelect";
import {IRootReducerState} from "../../../redux-store/rootReducer";
import {connect} from "react-redux";
import * as styles from './styles.module.sass';
import {Footer} from "./Footer";
import {Dispatch} from "redux";
import {IFilterCampaignAction, IFilterResetAction, ILoginAction} from "../../../types";
import {filterCampaign, filterReset} from "../../../redux-store/actions/filter-campaign-actions";
import EditHeaderForm from "../EditHeader";

export const CUSTOM = 'custom';
export const LAST_30_DAYS = 'last 30 days';
export const LAST_7_DAYS = 'last 7 days';
export const OVERTIME = 'overtime';
export const DAY = 'day';

interface IProps {
  branches: any;
  country_ISO: string;
  onSubmit: () => void;
  campaignType: string;
  campaignName: string;
  filterCampaign: () => void;
  filterReset: () => void;
  totalUsers: number;
  costPerSMS: number;
  isFiltered: boolean;
  isFiltering: boolean;
  formValues: {
    sms: any;
    fullTime: any;
    branches: any;
    visitsCount: any;
    lastVisit: any;
    avg: any;
  }
  // edit campaign
  initialValues: any;
  canEdit?: boolean;
  isEdit?: boolean;
}

interface IState {
  disabled: boolean;
}

class FilterCampaign extends React.Component<IProps & InjectedFormProps, IState> {
  constructor(props) {
    super(props);
    this.state = {disabled: true}
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const oldValues = this.props.formValues;
    const newValues = nextProps.formValues;
    const keys = Object.keys(newValues);
    keys.forEach(key => {
      const oldValue = oldValues[key];
      const newValue = newValues[key];
      if (oldValue !== newValue) {
        this.props.filterReset();
      }
    });
  }

  public handleFilterCampaign = () => {
    const {touch, valid} = this.props;
    touch('sms', 'fullTime', 'branches', 'visitsCount', 'lastVisit', 'avg', 'campaignName');
    if (valid) {
      this.props.filterCampaign();
    }
  };

  public enableEdit = () => this.setState({disabled: false});

  public render() {
    const {
      totalUsers, costPerSMS, onSubmit,
      isFiltered, isFiltering, country_ISO, campaignType,
      canEdit, isEdit, branches,
      touch
    } = this.props;
    const {disabled} = this.state;
    let visitsCountStart = {label: '1', value: 1};
    let lastVisitStart = {label: OVERTIME, value: OVERTIME};
    if (campaignType === 'promotion') {
      visitsCountStart = {label: '2', value: 2};
    }
    if (campaignType === 'bring-back-customer') {
      lastVisitStart = {label: LAST_30_DAYS, value: LAST_30_DAYS};
    }
    return (
      <form className={styles.wrapper}>
        {isEdit && <EditHeaderForm canEdit={canEdit} onClick={this.enableEdit} disabled={disabled}/>}
        <h1>Send SMS Message</h1>
        <Field
          disabled={disabled && isEdit}
          name='sms'
          component={RenderSMSTextAreaGenericField as any}
          label={'Create your Campaign SMS message'}
          validate={[required]}
        />
        <h1 style={{'margin': '2rem 0'}}>Select Time</h1>
        <Field
          disabled={disabled && isEdit}
          name='fullTime'
          guess={!isEdit}
          component={RenderFullTimePicker as any}
          touch={touch}
          label={'When should the campaign be delivered?'}
          validate={[fullTimeValidator]}
        />
        <div style={{margin: '4rem 0', display: 'flex', justifyContent: 'center', width: '100%'}}>
          <h1 style={{fontSize: '2.6rem'}}>Filter your targeted customers</h1>
        </div>
        <div className={styles.row}>
          <Field
            disabled={disabled && isEdit}
            name='branches'
            options={branches}
            component={RenderChipsField as any}
            label={'By branche(s) they visited'}
          />
          <Field
            disabled={disabled && isEdit}
            name='visitsCount'
            label='By how many times they visited your brand'
            toolTipText='It allows you to select customers based on
                        how many times they visited your brand.'
            options={[{label: '1', value: 1}, {label: '2', value: 2},
              {label: '3', value: 3}, {label: '4', value: 4}, {label: '5 or more', value: 5}]}
            start={isEdit ? undefined : visitsCountStart}
            component={RenderDropDownSelect as any}
          />
          <Field
            disabled={disabled && isEdit}
            name='lastVisit'
            label='By the date of their last visit'
            toolTipText='It allows you to select customers based on
                        their last visit date to your brand.'
            options={[{label: OVERTIME, value: OVERTIME}, {label: LAST_30_DAYS, value: LAST_30_DAYS},
              {label: LAST_7_DAYS, value: LAST_7_DAYS}, {label: CUSTOM, value: CUSTOM}]}
            start={isEdit ? undefined : lastVisitStart}
            component={RenderDropDownSelect as any}
          />
          <Field
            disabled={disabled && isEdit}
            name='avg'
            type='number'
            component={RenderTwoGenericFields as any}
            placeholder1={'From'}
            placeholder2={'To'}
            country_ISO={country_ISO}
            label={'By How much they spent on average in your brand'}
          />
        </div>
        <Footer createCampaign={this.handleFilterCampaign}
                isFiltering={isFiltering}
                isFiltered={isFiltered}
                totalUsers={totalUsers}
                totalCost={costPerSMS}
                disabled={disabled && isEdit}
                btnName={isEdit ? 'Save edits' : 'Create campaign'}
                openConfirmationModal={onSubmit}/>
      </form>
    );
  }
}

const mapStateToProps = (state: IRootReducerState) => {
  return {
    branches: state.authReducer.storeData.branches,
    country_ISO: state.authReducer.storeData.country_iso_code,
    campaignType: state.createCampaignReducer.type,
    campaignName: state.createCampaignReducer.name,
    totalUsers: state.filterCampaignReducer.total_users,
    costPerSMS: state.filterCampaignReducer.cost_per_SMS,
    isFiltered: state.filterCampaignReducer.isFiltered,
    isFiltering: state.filterCampaignReducer.isFiltering,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IFilterCampaignAction | ILoginAction | IFilterResetAction>) => {
  return {
    filterCampaign: () => dispatch(filterCampaign()),
    filterReset: () => dispatch(filterReset()),
  }
};

let FilterCampaignForm = reduxForm({
  form: 'filterCampaignForm',
})(FilterCampaign as any) as any;

const selector = formValueSelector('FilterCampaignForm');
FilterCampaignForm = connect(state => {
  // can select values individually
  const sms = selector(state, 'sms');
  const fullTime = selector(state, 'fullTime');
  const branches = selector(state, 'branches');
  const visitsCount = selector(state, 'visitsCount');
  const lastVisit = selector(state, 'lastVisit');
  const avg = selector(state, 'avg');
  return {
    formValues: {
      sms,
      fullTime,
      branches,
      visitsCount,
      lastVisit,
      avg,
    }
  }
})(FilterCampaignForm);

export default connect(mapStateToProps, mapDispatchToProps)
(FilterCampaignForm) as any;
