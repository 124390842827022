import React, { useState, useCallback } from "react";
import { IThankYouProps } from "../ThankYou";
import * as styles from '../ThankYou/styles.module.sass'
import { MODES } from "../..";
import TouchableGesture from "../../TouchableGesture";
import { notify } from "react-notify-toast";
import { useProgressState } from "../../../../../Utils/custom-hooks/useProgressState";
import { useKindaTranslate } from "../PhoneEntry";


interface IRemindMeProps extends IThankYouProps {
  onSendEmail?: (email: string) => Promise<any>;
}

export const emailValidator = (value: string) => !value || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);



const RemindMe: React.FC<IRemindMeProps> = props => {
  const { mode, backgroundColor, onSendEmail, formatText } = props;

  const t = useKindaTranslate(formatText);
  const {
    loading,
    setFailure,
    setLoading,
    setSuccess,
  } = useProgressState()
  const [email, setEmail] = useState('');

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleClick = useCallback(() => {
    const emailInvalid = emailValidator(email);
    if (emailInvalid) {
      return notify.show(t('Please enter a valid email address'), 'error');
    }
    if (onSendEmail) {
      setLoading();
      onSendEmail(email)
        .then(setSuccess)
        .catch(setFailure)
    }
  }, [email]);


  return (
    <>
      <div className={styles.phoneNumberFieldWrapper}>
        <label className={styles.phoneNumberLabel}>{t('Enter your e-mail address')}</label>
        <input
          required={true}
          onChange={onEmailChange} disabled={mode === MODES.MOCK_UP} type="email" placeholder='name@example.com' className={styles.phoneNumberInput} />
      </div>
      <TouchableGesture onClick={handleClick} loading={loading} style={{ backgroundColor, }} type="button" className={styles.phoneNumberSubmit}>
        {t('Send')}
      </TouchableGesture>
    </>
  )
};

export default RemindMe;
