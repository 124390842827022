import { useState, useContext, useCallback } from "react";
import {
  IEditOTOCampaign,
  editCampaignDataType,
  editOTOPointsCampaign,
  editOTOMenuItemCampaign,
  editOTOVoucherCampaign
} from "../../../axios/editOTOCampaign";
import {
  createOTOSpecialEventsPointsCampaign,
  createOTOSpecialEventsMenuItemCampaign,
  createOTOSpecialEventsVoucherCampaign,
  ICreateOTOCampaign,
  createSpecialOTOCampaignDataType
} from "../../../axios/createOTOCampaign";
import { notify } from "react-notify-toast";
import StoreData from "../../../contexts/StoreData";
import { useProgressState } from "../../../../Utils/custom-hooks/useProgressState";
import { AxiosResponse } from "axios";
import { trackEvent } from "../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../constants/events-for-tracking";

export const useCampaignEditAndCreate = () => {
  const { loading, setFailure, setSuccess, setLoading } = useProgressState();
  const { token } = useContext(StoreData);
  const [createdCampaignId, setCreatedCampaignId] = useState<
    string | undefined
  >(undefined);

  const genCreationFn = <DataType = ICreateOTOCampaign>(
    fn: (
      token: string,
      campaignData: createSpecialOTOCampaignDataType
    ) => Promise<AxiosResponse<{ id: string }>>
  ) => (campaign: createSpecialOTOCampaignDataType) => {
    const isRemoteAuthentication = localStorage.getItem(
      "is_remote_authentication"
    );
    setLoading();
    return fn(token, campaign)
      .then(({ data: { id } }) => {
        setCreatedCampaignId(id);
        setSuccess();
        notify.show(
          "Your online-to-offline campaign has been created successfully!",
          "success"
        );
        if (!isRemoteAuthentication) {
          trackEvent(EVENTS_FOR_TRACKING["Created an OTO campaign"]);
        }
        return id;
      })
      .catch(() => {
        setFailure();
        notify.show("Something went wrong!", "error");
      });
  };
  const genUpdateFn = <DataType = IEditOTOCampaign>(
    fn: (
      token: string,
      campaignData: editCampaignDataType
    ) => Promise<AxiosResponse<{ id: string }>>
  ) => (campaign: editCampaignDataType) => {
    setLoading();
    return fn(token, campaign)
      .then(({ data: { id } }) => {
        setCreatedCampaignId(id);
        setSuccess();
        notify.show(
          "Your online-to-offline campaign has been edited successfully!",
          "success"
        );
        return id;
      })
      .catch(() => {
        setFailure();
        notify.show("Something went wrong!", "error");
      });
  };

  const createPointsCampaign = useCallback(
    genCreationFn(createOTOSpecialEventsPointsCampaign),
    [setSuccess, setFailure]
  );
  const createMenuItemCampaign = useCallback(
    genCreationFn(createOTOSpecialEventsMenuItemCampaign),
    [setSuccess, setFailure]
  );
  const createVoucherCampaign = useCallback(
    genCreationFn(createOTOSpecialEventsVoucherCampaign),
    [setSuccess, setFailure]
  );
  const editPointsCampaign = useCallback(genUpdateFn(editOTOPointsCampaign), [
    setSuccess,
    setFailure
  ]);
  const editMenuItemCampaign = useCallback(
    genUpdateFn(editOTOMenuItemCampaign),
    [setSuccess, setFailure]
  );
  const editVoucherCampaign = useCallback(genUpdateFn(editOTOVoucherCampaign), [
    setSuccess,
    setFailure
  ]);

  return {
    loading,
    createdCampaignId,
    createPointsCampaign,
    createMenuItemCampaign,
    createVoucherCampaign,
    editPointsCampaign,
    editMenuItemCampaign,
    editVoucherCampaign
  };
};
