import { hot } from "react-hot-loader";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import BreadCrumbs from "../../../contexts/BreadCrumbs";
import { RouteComponentProps } from "react-router";
import { getOTOCampaign } from "../../../axios/getOTOCampaign";
import StoreData from "../../../contexts/StoreData";
import { useProgressState } from "../../../../Utils/custom-hooks/useProgressState";
import { IOTOCampaign } from "../RecentOTOs";
import * as styles from "./styles.module.sass";
import { LoadingPageInfo } from "../../LoadingPageInfo";
import OTOCard from "../RecentOTOs/OTOCard";
import { useRef } from "react";
import { notify } from "react-notify-toast";
import OTOStatCards from "./OTOStatCards";
import DailyStatChart from "../DailyStatChart";
import SocialMediaConversion from "../SocialMediaConversion";
import CustomersLocationsDist from "../CustomersLocationsDist";
import { trackEvent } from "../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../constants/events-for-tracking";
import { CampaignType } from "../../../axios/createOTOCampaign";
import { useRecoilValue } from "recoil";
import { remoteAuthenticationAtom } from "../../../atoms/remote-authentication.atom";

const SocialLinkIcon: React.FC<{
  image: string;
  name: string;
  link?: string;
}> = ({ link, image, name }) => {
  const hasLink = Boolean(link);
  return (
    <a
      target="_blank"
      title={hasLink ? "" : `Add your ${name} link from your brand's listing`}
      href={link}
      className={`${styles.socialIcon} ${hasLink ? "" : styles.disabled}`}
    >
      <img src={image} />
    </a>
  );
};

const ViewOTOCampaign: React.FunctionComponent<RouteComponentProps> = ({
  location,
  history: { push },
}) => {
  const remoteAuthenticationValue = useRecoilValue(remoteAuthenticationAtom);

  // Campaigns urls
  const normalOTOBaseURL = `${remoteAuthenticationValue
    ? process.env.REMOTE_CAMPAIGN_LINK
    : process.env.CAMPAIGN_LINK
    }c/`;
  const specialEventsOTOBaseURL = `${remoteAuthenticationValue
    ? process.env.REMOTE_CAMPAIGN_LINK
    : process.env.CAMPAIGN_LINK
    }c-special/`;

  const { setCrumbs } = React.useContext(BreadCrumbs);
  const { token, storeData } = useContext(StoreData);
  const urlRef = useRef<HTMLInputElement>(null);
  const params = new URLSearchParams(location.search);
  const campaignId = params.get("id");
  const { failure, success, setFailure, setSuccess, setLoading } =
    useProgressState();
  const [campaignData, setCampaignData] = useState<IOTOCampaign | undefined>(
    undefined
  );
  const [defaultCampaignURL, setDefaultCampaignURL] = useState<string>("");
  const goToEdit = () =>
    push("/online-to-offline/edit?id=" + (campaignData as IOTOCampaign).id);
  useEffect(() => {
    const isRemoteAuthentication = localStorage.getItem(
      "is_remote_authentication"
    );
    if (campaignId) {
      setLoading();
      getOTOCampaign(token, campaignId)
        .then((res) => {
          const { data } = res;
          const campaignCustomersNumber = data.customers_count || 0;
          const isInfinite = data.max_users === "infinity";
          const isPastExpirationDate = data.days_left <= 0;
          const hasReachedTarget =
            !isInfinite && campaignCustomersNumber >= +data.max_users;
          setCampaignData({
            ...data,
            haxExpired: isPastExpirationDate || hasReachedTarget,
          });
          setDefaultCampaignURL(
            data.type === CampaignType.special_event_OTO
              ? `${remoteAuthenticationValue
                ? `${specialEventsOTOBaseURL}${data.id}/remoteAuthentication`
                : `${specialEventsOTOBaseURL}${data.id}`
              }`
              : `${remoteAuthenticationValue
                ? `${normalOTOBaseURL}${data.id}/remoteAuthentication`
                : `${normalOTOBaseURL}${data.id}`
              }`
          );
          setSuccess();
        })
        .catch(setFailure);
      if (!isRemoteAuthentication) {
        trackEvent(EVENTS_FOR_TRACKING["Viewed an OTO campaign results"]);
      }
    }
  }, [campaignId]);
  useEffect(() => {
    setCrumbs([
      {
        name: "Koinz Reach",
        url: "",
        disabled: true,
      },
      {
        name: "View Online-To-Offline campaign",
        url: "/",
      },
    ]);
  }, []);
  const onCopyClicked = () => {
    if (urlRef.current) {
      urlRef.current.select();
      document.execCommand("copy");
      notify.show("Copied!", "success");
    }
  };

  let OTOCardRender = <LoadingPageInfo />;

  if (success) {
    OTOCardRender = (
      <>
        <OTOCard
          style={{ margin: 0, width: "100%" }}
          hasEdit={true}
          campaignData={campaignData as IOTOCampaign}
        />
        <div className={styles.urlWrapper}>
          <p className={styles.urlT}>The campaign URL</p>
          <p className={styles.urlP}>
            copy the link below and use it on your social media platform
          </p>
          <div className={styles.clipWrapper}>
            <label className={styles.label}>Campaign link</label>
            <div className={styles.input}>
              <div className={styles.inputWrapper}>
                <input
                  ref={urlRef}
                  onClick={onCopyClicked}
                  defaultValue={defaultCampaignURL}
                  className={styles.url}
                />
                <div className={styles.shadow} />
              </div>
              <div onClick={onCopyClicked} className={styles.clip}>
                <img
                  className={styles.icon}
                  src={require("../../../assets/icon-link.svg")}
                />
                <p className={styles.text}>Copy</p>
              </div>
            </div>
          </div>
          <p className={styles.find}>
            Find your campaign on social
            <br /> media platforms
          </p>
          {storeData && (
            <div className={styles.socialIcons}>
              <SocialLinkIcon
                link={storeData.facebook_link}
                image={require("../../../assets/logo-facebook.svg")}
                name="facebook"
              />
              <SocialLinkIcon
                link={storeData.twitter_link}
                image={require("../../../assets/logo-twitter.svg")}
                name="twitter"
              />
              <SocialLinkIcon
                link={storeData.instagram_link}
                image={require("../../../assets/logo-instagram.svg")}
                name="instagram"
              />
              <SocialLinkIcon
                link={storeData.snapchat_link}
                image={require("../../../assets/logo-snapchat.svg")}
                name="snapchat"
              />
            </div>
          )}
        </div>
      </>
    );
  }

  if (failure) {
    OTOCardRender = (
      <div className={styles.rejectedText}>
        Failed to load online-to-offline campaign
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <button
        onClick={goToEdit}
        disabled={!success || (campaignData as IOTOCampaign).haxExpired}
        className={styles.edit}
      >
        <img src={require("../../../assets/icon-oto-edit.svg")} />
        <p>Edit campaign</p>
      </button>
      <div className={styles.line} />
      <h1 className={styles.title}>Campaign result</h1>
      <p className={styles.para}>This is how your campaign performed so far</p>
      {success && <OTOStatCards campaignId={campaignId || ""} />}
      {success && (
        <DailyStatChart campaignData={campaignData as IOTOCampaign} />
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {success && <SocialMediaConversion campaignId={campaignId || ""} />}
        {success && <CustomersLocationsDist campaignId={campaignId || ""} />}
        <div style={{ width: "33%" }}>{OTOCardRender}</div>
      </div>
    </div>
  );
};

export default hot(module)(ViewOTOCampaign);
