import Datetime from "react-datetime";
import * as React from "react";
import * as styles from './styles.module.sass';
import {WrappedFieldProps} from "redux-form";
import IcoMoon from 'react-icomoon';
import 'react-datetime/css/react-datetime.css';
import * as moment from "moment";
import {Moment} from "moment";
import TimezonePicker from 'react-timezone';


interface IProps {
  label: string;
  disabled?: boolean;
  guess?: boolean;
  touch: any;
}

export class RenderFullTimePicker extends React.Component<WrappedFieldProps & IProps> {
  public dateRef: any = {openCalendar: () => ({})};
  public timeRef: any = {openCalendar: () => ({})};
  public timeZoneRef: any = {handleFocus: () => ({})};

  constructor(props) {
    super(props);
    if (props.guess) {
      const timezone = moment.tz.guess();
      const {value, onChange} = props.input;
      onChange({...value, timezone});
    }
  }

  public handleDateChange = (date) => {
    const {input: {value, onChange}, touch} = this.props;
    onChange({...value, date});
    touch('fullTime');
  };
  public handleTimeChange = (time) => {
    const {input: {value, onChange}, touch} = this.props;
    onChange({...value, time});
    touch('fullTime');
  };

  public handleTimeZoneChange = (timezone) => {
    const {input: {value, onChange}, touch} = this.props;
    onChange({...value, timezone});
    touch('fullTime');
  };

  // public onBlurDate = () => {
  //     const {onBlur, value: {date}} = this.props.input;
  //     onBlur(date);
  // };
  //
  // public onBlurTime = () => {
  //     const {onBlur, value: {time}} = this.props.input;
  //     onBlur(time);
  // };
  //
  // public onBlurTimezone = () => {
  //     const {onBlur, value: {timeZone}} = this.props.input;
  //     onBlur(timeZone);
  // };
  //
  // public onFocusDate = () => {
  //     const {onFocus, value: {date}} = this.props.input;
  //     onFocus(date);
  // };
  //
  // public onFocusTime = () => {
  //     const {onFocus, value: {time}} = this.props.input;
  //     onFocus(time);
  // };
  //
  // public onFocusTimezone = () => {
  //     const {onFocus, value: {timeZone}} = this.props.input;
  //     onFocus(timeZone);
  // };

  public render() {
    const onlyTodayAndAfter = (current: Moment) => {
      return current.isAfter(moment().startOf('day').subtract(1, 'day'));
    };
    const {input, label, meta: {warning, error, touched}, disabled} = this.props;
    return (
      <div className={styles.fullTimeWrapper}>
        <div className={styles.textWrapper}>
          <h2>{label}</h2>
        </div>
        <div className={styles.pickersWrapper}>
          <div className={styles.datePicker}>
            <Datetime isValidDate={onlyTodayAndAfter} ref={d => {
              this.dateRef = d
            }} inputProps={{
              placeholder: 'Choose a date...',
              readOnly: true,
              className: styles.date,
              disabled,
              // onBlur: this.onBlurDate,
              // onFocus: this.onFocusDate
            }}
                      timeFormat={false} value={input.value.date}
                      onChange={this.handleDateChange}/>
            <div onClick={this.dateRef.openCalendar} className={styles.icon}><IcoMoon icon="calendar"/>
            </div>
          </div>
          <div className={styles.timePicker}>
            <Datetime ref={d => {
              this.timeRef = d
            }} inputProps={{
              placeholder: 'Choose a time...',
              readOnly: true,
              className: styles.time,
              disabled,
              // onBlur: this.onBlurTime,
              // onFocus: this.onFocusTime
            }}
                      dateFormat={false} value={input.value.time}
                      onChange={this.handleTimeChange}/>
            <div onClick={this.timeRef.openCalendar} className={styles.icon}><IcoMoon icon="clock"/></div>
          </div>
          <div className={styles.timePicker}>
            <TimezonePicker
              ref={d => {
                this.timeZoneRef = d
              }}
              inputProps={{
                placeholder: 'Select Timezone...',
                name: 'timezone',
                disabled,
                // onBlur: this.onBlurTimezone,
                // onFocus: this.onFocusTimezone
              }}
              onChange={this.handleTimeZoneChange}
              value={input.value.timezone}
            />
            <div onClick={this.timeZoneRef.handleFocus} className={styles.icon}><IcoMoon icon="clock"/>
            </div>

          </div>
        </div>
        {touched && ((error && <span className={styles.formError}>{error}</span>) || (warning &&
          <span>{warning}</span>))}

      </div>
    );
  }
}

