import React, {useContext, useEffect, useState} from "react";
import OTOCard from "./OTOCard"
import * as styles from './styles.module.sass'
import {ICreateOTOCampaign} from "../../../axios/createOTOCampaign";
import {useProgressState} from "../../../../Utils/custom-hooks/useProgressState";
import StoreData from "../../../contexts/StoreData";
import {getOTOCampaigns} from "../../../axios/getOTOCampaigns";
import {LoadingPageInfo} from "../../LoadingPageInfo";
import NoCampaignCreated from "../../NoCampaignCreated";
import {RouteComponentProps, withRouter} from "react-router";

export const menu_item = 'Item';
export type menu_item = typeof menu_item;
export const points = 'OnlineToOfflinePoint';
export type points = typeof points;
export const voucher = 'Voucher';
export type voucher = typeof voucher;


export interface IOTOCampaignGiftData {
  type: menu_item|points|voucher;
  id?: string;
  name?: string;
  image?: string;
  item_id?: string;
  points?: number;
}
export interface IOTOCampaign extends ICreateOTOCampaign {
  id: string;
  days_left: number;
  updated_at: number;
  created_at: number;
  haxExpired: boolean;
  customers_count: number;
  gift: IOTOCampaignGiftData;
	
}

interface IProps extends RouteComponentProps {
  subHistory?: boolean;
}

const RecentOTOs: React.FC<IProps> = ({subHistory, history: {push}}) => {
  const [OTOCampaign, setOTOCampaign] = useState<IOTOCampaign[]>([]);
  const {setFailure, setSuccess, setLoading, success, failure} = useProgressState();
  const {token} = useContext(StoreData);
  useEffect(() => {
    setLoading();
    getOTOCampaigns(token, subHistory ? 3 : undefined).then(({data}) => {
      setOTOCampaign(data || []);
      setSuccess();
    }).catch(setFailure);
  }, []);
  let render = <LoadingPageInfo/>;
  if (success) {
    render = (OTOCampaign.length ?
        <>
          {OTOCampaign.map(c => <OTOCard key={c.id} campaignData={c}/>)}
        </>
        :
        <NoCampaignCreated
          message='There’s no online-to-offline campaigns, Creat a new one now!'
          url='/online-to-offline/new'
        />
    )
  }
  if (failure) {
    render = (
      <div className={styles.rejectedText}>Failed to load online-to-offline campaigns</div>
    );
  }
  const onShowAllClicked = () => push('/online-to-offline/history');
  return (
    <div style={{padding: !subHistory ? '4.4rem 2.6rem' : 0}}>
      <h2 className={styles.title}>
        {subHistory && 'Recent '}Online to offline campaigns
        {subHistory && <button onClick={onShowAllClicked} className={styles.showAll}>
          Show all
        </button>}
      </h2>
      {!subHistory && <p className={styles.para}>
        Acquire and track new customers to your business from social<br/> channels.
      </p>}
      <div className={styles.wrapper}>
        {render}
      </div>
    </div>
  )
};

export default withRouter(RecentOTOs);
