import {Redirect, Route} from "react-router";
import * as React from "react";
import {useCallback, useContext} from "react";
import StoreData from "../contexts/StoreData";

export const PrivateRoute: React.FC<{component: React.ComponentType<any>; [x: string]: any}> = ({component: Component, ...rest}) => {
  const {isLoggedIn} = useContext(StoreData);
  const renderRoute = useCallback(props =>
    isLoggedIn ? (
      <Component {...{...props, ...rest}} />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: {from: props.location}
        }}
      />
    ), [isLoggedIn]);
  return (
    <Route
      {...rest}
      render={renderRoute}
    />
  );
};
