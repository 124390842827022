import {ChartTypes} from "./DailyStatsSelect";
import {getOTOCampaignClicksPerDay} from "../../../axios/getOTOCampaignClicksPerDay";
import {useContext, useEffect, useState, useRef} from "react";
import StoreData from "../../../contexts/StoreData";
import {getOTOCampaignVisitorsPerDay} from "../../../axios/getOTOCampaignVisitorsPerDay";
import {getOTOCampaignAddedUsersPerDay} from "../../../axios/getOTOCampaignAddedUsersPerDay";
import * as moment from "moment";


const apis = {
  [ChartTypes.Clicks]: getOTOCampaignClicksPerDay,
  [ChartTypes.Visits]: getOTOCampaignVisitorsPerDay,
  [ChartTypes.PhoneNumbersAdded]: getOTOCampaignAddedUsersPerDay,
};

export const useChartsAPIs = (campaignId: string, chartType: ChartTypes, chartName: string) => {
  const nameRef = useRef(chartName);
  nameRef.current = chartName;
  const {token} = useContext(StoreData);
  const [chartSeries, setChartSeries] = useState();
  useEffect(() => {
    apis[chartType](token, campaignId).then(({data}) => {
      setChartSeries([{
        name: nameRef.current,
        data: Object.keys(data).map(key => ({
          y: data[key],
          x: +moment(key, 'YYYY-MM-DD').format('x'),
        }))
      }])
    }
    );
  }, [chartType]);
  return chartSeries;
};
