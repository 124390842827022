import {all} from "redux-saga/effects";
import * as SAGAS from './sagas';

export default function* rootSaga() {
  yield all([
    SAGAS.watchCampaignsLogSaga(),
    SAGAS.watchLoginSaga(),
      SAGAS.watchFilterCampaignSaga(),
      SAGAS.watchCreateCampaignSaga(),
      SAGAS.watchGetCampaignSaga(),
      SAGAS.watchEditCampaignSaga(),
      SAGAS.watchGetQuotasSaga(),
      SAGAS.watchRequestQuotasSaga(),
      SAGAS.watchDeleteCampaignSaga()
  ])
}
