import { useEffect, useState, useContext, } from "react";
import { getStoreProfile } from "../axios/storeProfile";
import { goToLogin } from "../axios/axios";
import { notify } from "react-notify-toast";
import { useProgressState } from "../../Utils/custom-hooks/useProgressState";
import StoreData from "../contexts/StoreData";
import {getBranches} from "../axios/getBranches";

export const useGetStoreData = (token: string) => {
  const [localToken, setLocalToken] = useState(token);
  const { setStoreData, storeData } = useContext(StoreData);
  const {
    loading,
    setLoading,
    setSuccess,
    failure,
    setFailure,
    success,
  } = useProgressState();
  useEffect(() => {
    const availableToken = token || localToken;
    if (storeData && availableToken) {
      localStorage.setItem('authData', JSON.stringify({
        token: availableToken,
        storeData,
      }));
    }
  }, [token, storeData, localToken]);

  useEffect(() => {
    const localData = localStorage.getItem('authData');
    if (localData) {
      const parsedData = JSON.parse(localData);
      setLocalToken(parsedData.token);
    }
  }, []);

  const getProfile = async () => {
    const availableToken = token || localToken;
    if (!availableToken) {
      return;
    } else {
      setLoading();
      getStoreProfile(availableToken)
        .then(async ({ data: {store} }) => {
          setSuccess();
          const {data} = await getBranches(availableToken);
          setLocalToken(availableToken);
          setStoreData({...store,branches: data} , availableToken);
        })
        .catch((e) => {
          setFailure();
          if (e.response && e.response.status === 401) {
            localStorage.removeItem('authData');
            goToLogin();
          } else if (e.response && e.response.status === 500) {
            notify.show('Something went wrong. Trying to reconnect', 'warning');
            setTimeout(getProfile, 1000);
          }
        });
    }
  };
  useEffect(() => {
    getProfile();
  }, [token, localToken]);
  return {
    token: localToken,
    storeData,
    loading,
    success,
    failure,
  };
};
