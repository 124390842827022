import { useProgressState } from "../../../../../Utils/custom-hooks/useProgressState";
import { useEffect, useState, useContext } from "react";
import StoreData from "../../../../contexts/StoreData";
import { getOTOCampaignClicksNumber } from "../../../../axios/getOTOCampaignClicksNumber";
import { getOTOCampaignOfflineVisitors } from "../../../../axios/getOTOCampaignOfflineVisitorsNumber";
import { getOTOCampaignPhoneNumbersAddedNumber } from "../../../../axios/getOTOCampaignPhoneNumbersAddedNumber";

const getStatistics = async (token: string, campaignId: string) => {
  const { data: { visitors_count } } = await getOTOCampaignOfflineVisitors(token, campaignId);
  const { data: { added_users_count } } = await getOTOCampaignPhoneNumbersAddedNumber(token, campaignId);
  const { data: { total_clicks } } = await getOTOCampaignClicksNumber(token, campaignId);

  return {
    total_clicks,
    visitors_count,
    added_users_count,
  }
};

export const useGetOTOStats = (campaignId: string) => {
  const {
    loading,
    success,
    failure,
    setLoading,
    setSuccess,
    setFailure,
  } = useProgressState();

  const [stats, setStats] = useState({
    total_clicks: 0,
    visitors_count: 0,
    added_users_count: 0,
  });

  const { token } = useContext(StoreData);

  useEffect(() => {
    setLoading();
    getStatistics(token, campaignId)
      .then(data => {
        setSuccess();
        setStats(data)
      })
      .catch(setFailure)
      ;
  }, [campaignId, token])

  return {
    loading,
    success,
    failure,
    stats
  }
}
