import {
    IFilterCampaignAction,
    IFilterCampaignActionFailure,
    IFilterCampaignActionSuccess,
    IFilterResetAction,
} from "../../types";
import * as actions from '../../constants'


export const filterReset=():IFilterResetAction=>({
    type:actions.filterResetAction,
});
export const filterCampaignSuccess=(data):IFilterCampaignActionSuccess=>({
    type:actions.filterCampaignAction.fulfilled,
    payload:data
});
export const filterCampaignFailure=(error):IFilterCampaignActionFailure=>({
    type:actions.filterCampaignAction.rejected,
    payload:error
});
export const filterCampaign=():IFilterCampaignAction=>({
    type:actions.filterCampaignAction.requested,
});