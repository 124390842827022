import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import * as React from "react";
import { Field } from "redux-form";
import { isEmpty, required, maxLength } from "../../../redux-store/validators";
import { RenderGenericField } from "../../FormElements/RenderGenericField";
import { RenderTextAreaGenericField } from "../../FormElements/RenderTextAreaGenericField";
import { RenderColorPickerField } from "../../FormElements/RenderColorPickerField";
import { maxLength100 } from "../OnlineToOfflineName";
import MockUpPart, { ScreensArray } from "./MockUpPart";
import { MiniAppScreen } from "../../oto-campaign-mini-app";
import { RenderPhotoPicker } from "../../../components/FormElements/RenderPhotoPicker";

// validation
const maxLength250 = maxLength(250);

interface IProps {
  isSpecialOTO?: boolean;
  campaign_language?: any;
  secondPageHeading?: string;
  campaign_logo?: string;
}

const OnlineToOfflineTemplate: React.FC<IProps> = Props => {
  const [previewImage, setPreviewImage] = React.useState<any>("");
  const [currentScreen, setCurrentScreen] = React.useState<MiniAppScreen>(
    ScreensArray[0]
  );
  const [currentScreenIndex, setCurrentScreenIndex] = React.useState(0);
  const switchScreen = React.useCallback(
    (goNext: boolean) => {
      const nextIndex = goNext
        ? currentScreenIndex + 1
        : currentScreenIndex - 1;
      setCurrentScreenIndex(nextIndex);
      setCurrentScreen(ScreensArray[nextIndex]);
    },
    [currentScreenIndex]
  );
  const nextScreen = React.useCallback(() => switchScreen(true), [
    switchScreen
  ]);
  const previousScreen = React.useCallback(() => switchScreen(false), [
    switchScreen
  ]);

  const switchToFinish = React.useCallback(() => {
    if (currentScreenIndex !== 1) {
      setCurrentScreen(ScreensArray[1]);
      setCurrentScreenIndex(1);
    }
  }, [setCurrentScreen, setCurrentScreenIndex, currentScreenIndex]);
  const switchToPhoneEntry = React.useCallback(() => {
    if (currentScreenIndex !== 0) {
      setCurrentScreen(ScreensArray[0]);
      setCurrentScreenIndex(0);
    }
  }, [setCurrentScreen, setCurrentScreenIndex, currentScreenIndex]);

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const getAndConvertToBase64 = async () => {
    if (Props.campaign_logo) {
      const previewCampaignImage = await toBase64(Props.campaign_logo[0]);
      setPreviewImage(previewCampaignImage);
    }
  };

  React.useEffect(() => {
    getAndConvertToBase64();
  }, [Props.campaign_logo]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.editorWrapper}>
        <p className={styles.header}>Customize your campaign web page</p>

        {Props.isSpecialOTO && (
          <Field
            type="file"
            description={
              "Upload image for your campaign or leave it empty and we will use your store logo"
            }
            buttonText={"Upload"}
            unsupportedFileTypeText={"Please use valid image"}
            label={"Upload campaign image"}
            component={RenderPhotoPicker}
            required={false}
            name="campaign_logo"
            className={styles.width}
          />
        )}
        <Field
          onFocus={switchToPhoneEntry}
          name="heading_text"
          type="text"
          note={` `}
          placeholder="First Page Heading Text"
          component={RenderGenericField}
          label={"First Page Heading Text"}
          className={styles.width}
          validate={[required, isEmpty, maxLength100]}
        />
        <Field
          onFocus={switchToPhoneEntry}
          name="description"
          placeholder="First Page Description"
          note=" "
          component={RenderTextAreaGenericField}
          label={"First Page Description"}
          className={styles.width}
          validate={[required, isEmpty, maxLength250]}
        />
        <Field
          onFocus={switchToFinish}
          name="download_title"
          placeholder="Second Page Description"
          note=" "
          component={RenderGenericField}
          label={"Second Page Description"}
          className={styles.width}
          validate={[required, isEmpty, maxLength100]}
        />
        <div className={styles.colorsWrapper}>
          <Field
            name="main_color"
            className={styles.colorWidth}
            label="Main color"
            component={RenderColorPickerField}
            validate={[required]}
          />
          <Field
            name="text_color"
            className={styles.colorWidth}
            label="Text color"
            component={RenderColorPickerField}
            validate={[required]}
          />
        </div>
      </div>
      <div className={styles.previewWrapper}>
        <p className={styles.header}>Live Preview</p>
        <p className={styles.subTitle}>
          This is how your campaign will look like to your customers
        </p>
        <MockUpPart
          currentScreen={currentScreen}
          currentScreenIndex={currentScreenIndex}
          nextScreen={nextScreen}
          previousScreen={previousScreen}
          isSpecialOTO={Props.isSpecialOTO}
          campaign_language={Props.campaign_language}
          secondPageHeading={Props.secondPageHeading}
          campaign_logo={previewImage}
        />
      </div>
    </div>
  );
};

export default hot(module)(OnlineToOfflineTemplate);
