import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {IBranch} from "../types";

export const getBranches = (token): AxiosReturn<IBranch[]> => {
    return axios.get(Requests.branches, {
      
      headers: {
            token,
        }
    })
};
