import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { CampaignLanguages } from "src/components/OnlineToOfflineCampaigns/OnlineToOfflineSpecialCampaignForm";

export enum CampaignType {
  special_event_OTO = "special_event_OTO",
  normal_OTO = "normal_OTO"
}

export interface ICreateOTOCampaign {
  type?: CampaignType;
  campaign_language?: CampaignLanguages;
  name: string;
  only_first_store_visitors: number;
  max_users: string;
  end_date: string;
  gift_expiration_date?: string;
  campaign_page_title: string;
  campaign_page_description: string;
  campaign_page_main_color: string;
  campaign_page_text_color: string;
  campaign_page_download_title: string;
}

export interface ICreateOTOPointsCampaign extends ICreateOTOCampaign {
  points_per_customer: number;
}

export interface ICreateOTOMenuItemCampaign extends ICreateOTOCampaign {
  item_id: string;
}

export interface ICreateOTOVoucherCampaign extends ICreateOTOCampaign {
  voucher: {
    name: string;
    image: string;
  };
}

export interface ICreateOTOSpecialEventsPointsCampaign
  extends ICreateOTOCampaign {
  points_per_customer: number;
  campaign_logo?: string;
  campaign_language: CampaignLanguages;
}

export interface ICreateOTOSpecialEventsMenuItemCampaign
  extends ICreateOTOCampaign {
  item_id: string;
  campaign_language: CampaignLanguages;
  campaign_logo?: string;
}

export interface ICreateOTOSpecialEventsVoucherCampaign
  extends ICreateOTOCampaign {
  campaign_language: CampaignLanguages;
  campaign_logo?: string;
  voucher: {
    name: string;
    image: string;
  };
}

export type createCampaignDataType =
  | ICreateOTOPointsCampaign
  | ICreateOTOVoucherCampaign
  | ICreateOTOMenuItemCampaign;

export type createSpecialOTOCampaignDataType =
  | ICreateOTOSpecialEventsPointsCampaign
  | ICreateOTOSpecialEventsVoucherCampaign
  | ICreateOTOSpecialEventsMenuItemCampaign;

const genOTOCreation = <DataType = ICreateOTOCampaign>(req: string) => (
  token: string,
  data: DataType
): AxiosReturn<{ id: string }> => {
  return axios.post(req, data, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token
    }
  });
};

export const createOTOPointsCampaign = genOTOCreation<ICreateOTOPointsCampaign>(
  Requests.OTOCampaignPoints
);
export const createOTOMenuItemCampaign = genOTOCreation<
  ICreateOTOMenuItemCampaign
>(Requests.OTOCampaignMenuItem);
export const createOTOVoucherCampaign = genOTOCreation<
  ICreateOTOVoucherCampaign
>(Requests.OTOCampaignVoucher);
// for OTO special events
export const createOTOSpecialEventsPointsCampaign = genOTOCreation<
  ICreateOTOSpecialEventsPointsCampaign
>(Requests.OTOSpecialEventsCampaignPoints);
export const createOTOSpecialEventsMenuItemCampaign = genOTOCreation<
  ICreateOTOSpecialEventsMenuItemCampaign
>(Requests.OTOSpecialEventsCampaignMenuItem);
export const createOTOSpecialEventsVoucherCampaign = genOTOCreation<
  ICreateOTOSpecialEventsVoucherCampaign
>(Requests.OTOSpecialEventsCampaignVoucher);
