import {Modal, ModalBody, ModalHeader} from "reactstrap";
import * as React from "react";
import * as styles from './styles.module.sass';
import Datetime from 'react-datetime';
import IcoMoon from 'react-icomoon';
import {IRenderDropDownSelectProps} from "../../FormElements/RenderDropDownSelect";


interface IProps extends IRenderDropDownSelectProps {
    modal: boolean,
    toggle: () => void,
}


export class CustomModal extends React.Component<IProps> {
    public handleFromChange = (event) => {
        const {value, onChange} = this.props.input;
        onChange({...value, dropDownValue: 'custom', value1: event});
    };

    public handleToChange = (event) => {
        const {value, onChange} = this.props.input;
        onChange({...value, dropDownValue: 'custom', value2: event});
    };

    public render() {
        const {modal, toggle, input} = this.props;
        return (
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}><strong>Date of last visit</strong></ModalHeader>
                <ModalBody>
                    <div className={styles.wrapper}>
                        <Datetime inputProps={{readOnly: true, className: styles.picker}} timeFormat={false}
                                  value={input.value.value1} defaultValue='Choose a date...'
                                  onChange={this.handleFromChange}/>
                        <div className={styles.icon}><IcoMoon icon="calendar"/></div>

                        <p className={styles.paragraph1}>From</p>
                    </div>
                    <div className={styles.wrapper}>
                        <Datetime inputProps={{readOnly: true, className: styles.picker}} timeFormat={false}
                                  value={input.value.value2} defaultValue='Choose a date...'
                                  onChange={this.handleToChange}/>
                        <div className={styles.icon}><IcoMoon icon="calendar"/></div>
                        <p className={styles.paragraph2}>To</p>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
