import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';

export interface ILoyaltyItem {
  id: string,
  name: string,
  points: number,
  price: number,
  image: string
}

export const getLoyaltyItems = (token: string, unlimited = false): AxiosReturn<ILoyaltyItem[]> => {
    return axios.get(Requests.loyalityItems, {
        baseURL: process.env.LUMEN_ENDPOINT,
        headers: {
            token,
        },
        params: {
          limit: unlimited ? undefined : 2,
        }
    })
};
