import React from "react";
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";
import * as styles from './styles.module.sass';


interface IProps {
  percent: number;
  count: number;
}

class StepProgressBar extends React.Component<IProps> {
  public render() {
    const {count, percent} = this.props;
    return (
      <ProgressBar width='40%' filledBackground="linear-gradient(to right, #FEBE19, #FEBE19)"
                   percent={percent}>
        {
          new Array(count).fill(0).map((v, i) => {
            return (
              <Step key={i} transition="scale">
                {({accomplished, index}) => (
                  <div
                    className={`${styles.indexedStep} ${accomplished ? styles.accomplished : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
            );
          })
        }
      </ProgressBar>
    );
  }
}

export default StepProgressBar;
