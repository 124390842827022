import * as React from "react";
import {IStore} from "../types";

interface IContextValues {
  storeData: IStore | null;
  token: string;
  isLoggedIn: boolean;
  setStoreData: (store: IStore, token: string) => void;
}

export default React.createContext<IContextValues>({
  storeData: null,
  isLoggedIn: false,
  setStoreData: (store: IStore, token: string) => null,
  token: '',
});
