export const genericAction = (action: string) => ({
    fulfilled: `${action}_FULFILLED`,
    rejected: `${action}_REJECTED`,
    requested: `${action}_REQUESTED`,
});

// const ACTION_SYNC_APP = 'ACTION_SYNC_APP';
// export const syncAppAction = genericAction(ACTION_SYNC_APP);

export const setCampaignNameAction = 'ACTION_SET_CAMPAiGN_NAME';
export const setCampaignTypeAction = 'ACTION_SET_CAMPAiGN_TYPE';
export const setCampaignDataAction = 'ACTION_SET_CAMPAiGN_DATA';
export const filterResetAction = 'ACTION_FILTER_RESET';
export const setCampaignEditDataAction = 'ACTION_SET_CAMPAiGN_EDIT_DATA';
export const resetFailAction = 'ACTION_RESET_FAIL';
const ACTION_CREATE_CAMPAiGN = 'ACTION_CREATE_CAMPAiGN';
export const createCampaignAction = genericAction(ACTION_CREATE_CAMPAiGN);

const ACTION_EDIT_CAMPAiGN = 'ACTION_EDIT_CAMPAiGN';
export const editCampaignAction = genericAction(ACTION_EDIT_CAMPAiGN);

const ACTION_FILTER_CAMPAiGN = 'ACTION_FILTER_CAMPAiGN';
export const filterCampaignAction = genericAction(ACTION_FILTER_CAMPAiGN);

const ACTION_GET_CAMPAiGNS_LOG = 'ACTION_GET_CAMPAiGNS_LOG';
export const getCampaignsLogAction = genericAction(ACTION_GET_CAMPAiGNS_LOG);


const ACTION_GET_CAMPAiGN = 'ACTION_GET_CAMPAiGN';
export const getCampaignAction = genericAction(ACTION_GET_CAMPAiGN);


const ACTION_DELETE_CAMPAiGN = 'ACTION_DELETE_CAMPAiGN';
export const deleteCampaignAction = genericAction(ACTION_DELETE_CAMPAiGN);

const ACTION_LOGIN = 'ACTION_LOGIN';
export const loginAction = genericAction(ACTION_LOGIN);


const ACTION_GET_QUOTAS = 'ACTION_GET_QUOTAS';
export const getQuotasAction = genericAction(ACTION_GET_QUOTAS);

const ACTION_REQUEST_QUOTAS = 'ACTION_REQUEST_QUOTAS';
export const requestQuotasAction = genericAction(ACTION_REQUEST_QUOTAS);
export const ACTION_FILTER_ADD_FROM_MENU = 'ACTION_FILTER_ADD_FROM_MENU';


export const resetRequestQuotasAction = 'ACTION_RESET_REQUEST_QUOTAS';

/**
 * @name FULL_DATE_FORMAT
 * @description
 * # FULL_DATE_FORMAT
 * Month Day Year
 */
export const FULL_DATE_FORMAT = 'MM DD YYYY';

/**
 * @name FULL_TIME_FORMAT
 * @description
 * # FULL_TIME_FORMAT
 * hour:minute:second PM/AM
 */
export const FULL_TIME_FORMAT = 'hh mm ss a';

export const CAIRO_TIMEZONE = 'Africa/Cairo';

