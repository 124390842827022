import {call, put, select, takeLatest} from "redux-saga/effects";
import {deleteCampaignAction} from "../../constants";
import {selectToken} from "../selectors";
import {IDeleteCampaignAction} from "../../types";
import {deleteCampaignFailure, deleteCampaignSuccess} from "../actions/delete-campaign-actions";
import {deleteCampaign} from "../../axios/deleteCampaign";
import {notify} from "react-notify-toast";

function* deleteCampaignSaga(action: IDeleteCampaignAction) {
    try {
        const token = yield select(selectToken);
        const res = yield call(deleteCampaign, action.payload, token);
        yield put(deleteCampaignSuccess({id:action.payload,...res.data}));
    } catch (e) {
        yield put(deleteCampaignFailure(e));
        notify.show('Please try again. if the problem persists please contact us ASAP.', 'error');
    }
}

export function* watchDeleteCampaignSaga() {
    yield takeLatest(deleteCampaignAction.requested, deleteCampaignSaga);
}
