import * as React from "react";
import * as styles from './styles.module.sass';
import {IRootReducerState} from "../../../redux-store/rootReducer";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {ICampaignLog, IEditCampaignAction} from "../../../types";
import {editCampaign, resetFail} from "../../../redux-store/actions/edit-campaign-actions";
import ConfirmationModal from "../ConfirmationModal";
import SuccessModal from "../SuccessModal";
import FailedModal from "../FailedModal";
import {filterReset} from "../../../redux-store/actions/filter-campaign-actions";
import {Title} from "react-head";
import FilterCampaignForm, {CUSTOM, LAST_30_DAYS, LAST_7_DAYS, OVERTIME} from "../FilterCampaignForm";
import {getCampaign} from "../../../redux-store/actions/campaigns-log";
import {RouteComponentProps} from "react-router";
import {LoadingPageInfo} from "../../LoadingPageInfo";
import * as moment from "moment-timezone";
import {onlyAll} from "../../FormElements/RenderChipsField";

const convertCampaignDataToForm = (campaign: ICampaignLog, branches) => {
  if (campaign.criteria) {
    const initialValues: any = {};
    const criteria = JSON.parse(campaign.criteria);
    initialValues.visitsCount = {dropDownValue: +criteria.visits_number.replace('+', '')};
    initialValues.campaignName = campaign.campaign_name;
    initialValues.sms = campaign.sms_content;
    const date = moment(campaign.launch_timestamp, 'x').tz(campaign.launch_timezone);
    initialValues.fullTime = {
      date,
      time: date,
      timezone: campaign.launch_timezone
    };
    initialValues.branches = criteria.branch_ids ? branches.filter(branch => criteria.branch_ids.includes(branch.id)) : onlyAll;
    let custom_visit_to: any;
    let custom_visit_from: any;
    let last_visit = '';
    if (criteria.last_visit_timestamps_text === CUSTOM) {
      last_visit = CUSTOM;
      if (+criteria.last_visit_timestamps_range[0] !== 0) {
        custom_visit_from = moment(+criteria.last_visit_timestamps_range[0], 'x');

      }
      if (+criteria.last_visit_timestamps_range[1]) {
        custom_visit_to = moment(+criteria.last_visit_timestamps_range[1], 'x');
      }
    } else if (criteria.last_visit_timestamps_text === LAST_7_DAYS) {
      last_visit = criteria.last_visit_timestamps_text;
    } else if (criteria.last_visit_timestamps_text === LAST_30_DAYS) {
      last_visit = criteria.last_visit_timestamps_text;
    } else {
      last_visit = OVERTIME;
    }
    initialValues.lastVisit = {
      dropDownValue: last_visit,
      value1: custom_visit_from,
      value2: custom_visit_to,
    };
    if (criteria.average_ticket_sizes_range) {
      initialValues.avg = {
        value1: criteria.average_ticket_sizes_range[0],
        value2: criteria.average_ticket_sizes_range[1]
      };
    }
    return initialValues;
  }
};


interface IProps extends RouteComponentProps {
  isEdited: boolean;
  isFailed: boolean;
  isEditing: boolean;
  closeModal: boolean;
  editCampaign: () => void;
  resetFail: () => void;
  filterReset: () => void;
  getCampaign: (id) => void;
  campaignLoaded: boolean;
  campaignFailed: boolean;
  branches: any;
  campaign: any;
}

interface IState {
  confirmationModal: boolean;
}

class CampaignEditing extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: false,
    }
  }

  public toggleConfirmationModal = () => {
    this.setState(prevState => ({
      confirmationModal: !prevState.confirmationModal,
    }))
  };

  public componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const campaignId = params.get('id');
    if (campaignId) {
      this.props.getCampaign(campaignId);
    }
  }

  public componentWillUnmount() {
    this.props.resetFail();
    this.props.filterReset();
  }

  public editCampaign = () => {
    this.props.editCampaign();
  };

  public render() {
    const {
      isFailed, isEdited, isEditing, closeModal,
      campaignFailed, campaignLoaded, campaign, branches
    } = this.props;
    let render = <LoadingPageInfo/>;
    if (campaignLoaded) {
      const canEdit = (campaign.status === 0 || campaign.status === 1 || campaign.status === 3);
      const formData = convertCampaignDataToForm(campaign, branches);
      render = <FilterCampaignForm isEdit={true}
                                   initialValues={formData}
                                   canEdit={canEdit}
                                   onSubmit={this.toggleConfirmationModal}/>;
    }
    if (campaignFailed) {
      render = (
        <div className={styles.rejectedText}><h1>Failed to load campaign data</h1></div>);
    }
    return (
      <div className={styles.wrapper}>
        <Title>Edit campaign</Title>
        {render}
        {!closeModal && <ConfirmationModal paragraph='Confirm your campaign edit' disabled={isEditing}
                                           action={this.editCampaign} title='Edit campaign'
                                           modal={this.state.confirmationModal}
                                           toggle={this.toggleConfirmationModal}/>}
        {isEdited && <SuccessModal {...{}} paragraph='Your campaign is edited successfully' modal={true}/>}
        {isFailed &&
        <FailedModal {...{}} paragraph='Please recharge your points to create the campaign' modal={true}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  isEdited: state.editCampaignReducer.isEdited,
  isFailed: state.editCampaignReducer.isFailed,
  isEditing: state.editCampaignReducer.isEditing,
  closeModal: state.editCampaignReducer.closeModal,
  campaignLoaded: state.campaignsLogReducer.isCampaignLoaded,
  campaignFailed: state.campaignsLogReducer.isCampaignFailed,
  campaign: state.campaignsLogReducer.campaignToEdit,
  branches: state.authReducer.storeData.branches
});
const mapDispatchToProps = (dispatch: Dispatch<IEditCampaignAction>) => ({
  editCampaign: () => dispatch(editCampaign()),
  resetFail: () => dispatch(resetFail()),
  filterReset: () => dispatch(filterReset()),
  getCampaign: (id) => dispatch(getCampaign(id)),
});


export default connect(mapStateToProps, mapDispatchToProps)(CampaignEditing);
