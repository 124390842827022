import React from 'react';
import * as styles from './styles.module.sass';
import {Dispatch} from "redux";
import {IQuota, IRequestQuotasAction, IResetRequestQuotasAction} from "../../../types";
import {connect} from "react-redux";
import ConfirmationModal from "../../SMSCampaigns/ConfirmationModal";
import QuotasSuccessModal from "../QuotasSuccessModal";
import {numberFormatter} from "../../../../Utils/numberFormatter";
import {IRootReducerState} from "../../../redux-store/rootReducer";
import {requestQuotas, resetRequestQuotas} from "../../../redux-store/actions/request-quotas-actions";
import {DotLoader} from "react-spinners";


interface IProps {
    quota: IQuota;
    requestQuotas: (id: number) => void;
    resetRequestQuotas: () => void;
    requesting: boolean;
    requested: boolean;
    failedRequesting: boolean;
    hasPurchasedQuota: boolean;
    requestedQuotaID: number;
}

interface IState {
    confirmationModal: boolean;
    successModal: boolean;
}

class PricingCard extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            confirmationModal: false,
            successModal: true,
        }
    }

    public toggleConfirmationModal = () => {
        this.setState(prevState => ({
            confirmationModal: !prevState.confirmationModal,
        }))
    };
    public requestQuota = () => {
        this.props.requestQuotas(this.props.quota.id);
    };

    public componentWillUnmount() {
        this.props.resetRequestQuotas();
    }

    public render() {
        const {quota, requesting, requested, requestedQuotaID, hasPurchasedQuota} = this.props;
        if (requesting && requestedQuotaID === quota.id) {
            return (
                <div className={styles.wrapper}>
                    <DotLoader
                        className={styles.override}
                        sizeUnit={"px"}
                        size={150}
                        color={'#FEBE19'}
                    />
                </div>);
        }
        return (
            <div className={styles.wrapper}>
                <div className={styles.aboveWrapper}>
                    <div className={styles.header}>
                        <h2 className={styles.title}>{quota.name}</h2>
                        {quota.off &&
                        <div className={styles.off}>
                            <img src={require('../../../assets/off.svg')} className={styles.offLogo}/>
                            <span className={styles.offText}>{(quota.off * 100).toFixed(1)}% Off</span>
                        </div>}
                    </div>
                </div>
                <div style={{width: '100%'}}>
                    <div className={styles.body}>
                        <div
                            className={styles.points}>{numberFormatter(quota.points, 0)}{quota.id === 4 && '+'}<span>Point</span>
                        </div>
                        <div className={styles.sms}><span>PT</span><p><span>{quota.sms_price}</span>/point</p></div>
                    </div>
                    <button className={styles.btn} disabled={hasPurchasedQuota}
                            onClick={this.toggleConfirmationModal}>Request this quote<span
                        className={styles.arrow}>&gt;</span></button>
                    <ConfirmationModal action={this.requestQuota} paragraph='Please confirm your selection'
                                       title='Request a quote'
                                       modal={this.state.confirmationModal} toggle={this.toggleConfirmationModal}/>
                    {requested && requestedQuotaID === quota.id &&
                    <QuotasSuccessModal {...{}} quota={quota}
                                        modal={this.state.successModal}/>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IRootReducerState) => ({
    requesting: state.quotasReducer.requesting,
    requested: state.quotasReducer.requested,
    hasPurchasedQuota: state.quotasReducer.hasPurchasedQuota,
    failedRequesting: state.quotasReducer.failedRequesting,
    requestedQuotaID: state.quotasReducer.requestedQuotaID
});

const mapDispatchToProps = (dispatch: Dispatch<IRequestQuotasAction | IResetRequestQuotasAction>) => ({
    requestQuotas: (id: number) => dispatch(requestQuotas(id)),
    resetRequestQuotas: () => dispatch(resetRequestQuotas()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingCard);
