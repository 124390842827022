import {call, put, select, takeLatest} from "redux-saga/effects";
import { createCampaignAction} from "../../constants";
import { createCampaign} from "../../axios";
import {selectCampaignName, selectForm, selectToken} from "../selectors";
import {createCampaignFailure, createCampaignSuccess} from "../actions/create-campaign-actions";
import {notify} from "react-notify-toast";
import {convertFormToCampaignData} from "./filterCampaign";
import {IFormState} from "../rootReducer";
import { trackEvent } from "../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";

function* createCampaignSaga() {
    const isRemoteAuthentication = localStorage.getItem(
      "is_remote_authentication"
    );
    try {
      const token = yield select(selectToken);
      const forms: IFormState = yield select(selectForm);
      const campaignName: IFormState = yield select(selectCampaignName);
      const { sms, fullTime, branches, visitsCount, lastVisit, avg } = forms
        .filterCampaignForm.values as any;
      const data = convertFormToCampaignData(
        true,
        fullTime.date,
        fullTime.time,
        fullTime.timezone,
        sms,
        lastVisit.dropDownValue,
        lastVisit.value1 || undefined,
        lastVisit.value2 || undefined,
        visitsCount.dropDownValue,
        (avg || {}).value1 || undefined,
        (avg || {}).value2 || undefined,
        branches,
        campaignName
      );
      yield call(createCampaign, data, token);
      yield put(createCampaignSuccess());
      if (!isRemoteAuthentication) {
        trackEvent(EVENTS_FOR_TRACKING["Created an sms campaign"]);
      }
      notify.show("Campaign Created", "success");
    } catch (e) {
      yield put(createCampaignFailure(e));
      if (e.response.status !== 403) {
        notify.show(
          "Please try again. if the problem persists please contact us ASAP.",
          "error"
        );
      }
    }
}

export function* watchCreateCampaignSaga() {
    yield takeLatest(createCampaignAction.requested, createCampaignSaga);
}
