import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {IOTOCampaign} from "../components/OnlineToOfflineCampaigns/RecentOTOs";


export const getOTOCampaigns = (token: string, limit?: number): AxiosReturn<IOTOCampaign[]> => {
  return axios.get(Requests.OTOCampaign, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
    params: {
      limit
    }
  })
};
