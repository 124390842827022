import * as constants from '../../constants';
import {mutateState} from "../../helpers/mutate-state";
import {Map} from "immutable";
import {
    ICampaignLog,
    IGetCampaignsLogActionSuccess, IDeleteCampaignActionSuccess, IGetCampaignActionSuccess
} from "../../types";

export interface ICampaignsLogReducerState {
    campaignsLog: ICampaignLog[];
    isCampaignLoaded: false;
    isCampaignLoading: false;
    isCampaignFailed: false;
    campaignToEdit: ICampaignLog;
    loading: boolean;
    loaded: boolean;
    failed: boolean;
}

const CAMPAIGNS_LOG = 'campaignsLog';
const CAMPAIGN_TO_EDIT = 'campaignToEdit';
const IS_CAMPAIGN_LOADED = 'isCampaignLoaded';
const IS_CAMPAIGN_LOADING = 'isCampaignLoading';
const IS_CAMPAIGN_FAILED = 'isCampaignFailed';
const LOADING = 'loading';
const LOADED = 'loaded';
const FAILED = 'failed';

const initialState: ICampaignsLogReducerState = Map({
    [CAMPAIGNS_LOG]: [],
    [CAMPAIGN_TO_EDIT]: {},
    [IS_CAMPAIGN_LOADED]: false,
    [IS_CAMPAIGN_LOADING]: false,
    [IS_CAMPAIGN_FAILED]: false,
    [LOADED]: false,
    [LOADING]: false,
    [FAILED]: false,
}).toJS();

const campaignsLogReducer = (state: ICampaignsLogReducerState = initialState,
                             action: IDeleteCampaignActionSuccess
                                 | IGetCampaignsLogActionSuccess
                                 | IGetCampaignActionSuccess): ICampaignsLogReducerState => {
    switch (action.type) {
        case constants.getCampaignsLogAction.fulfilled: {
            return mutateState(state, map => {
                map.set(CAMPAIGNS_LOG, ((action as IGetCampaignsLogActionSuccess).payload) || []);
                map.set(LOADED, true);
                map.set(LOADING, false);
                map.set(FAILED, false);
            });
        }
        case constants.getCampaignsLogAction.requested: {
            return mutateState(state, map => {
                map.set(CAMPAIGNS_LOG, ((action as IGetCampaignsLogActionSuccess).payload) || []);
                map.set(LOADED, false);
                map.set(LOADING, true);
                map.set(FAILED, false);
            });
        }
        case constants.getCampaignsLogAction.rejected: {
            return mutateState(state, map => {
                map.set(CAMPAIGNS_LOG, ((action as IGetCampaignsLogActionSuccess).payload) || []);
                map.set(LOADED, false);
                map.set(LOADING, false);
                map.set(FAILED, true);
            });
        }

        case constants.getCampaignAction.requested: {
            return mutateState(state, map => {
                map.set(IS_CAMPAIGN_LOADED, false);
                map.set(IS_CAMPAIGN_LOADING, true);
                map.set(IS_CAMPAIGN_FAILED, false);
                map.set(CAMPAIGN_TO_EDIT, {});
            });
        }

        case constants.getCampaignAction.rejected: {
            return mutateState(state, map => {
                map.set(IS_CAMPAIGN_LOADED, false);
                map.set(IS_CAMPAIGN_LOADING, false);
                map.set(IS_CAMPAIGN_FAILED, true);
            });
        }
        case constants.getCampaignAction.fulfilled: {
            return mutateState(state, map => {
                map.set(CAMPAIGN_TO_EDIT, (action as IGetCampaignActionSuccess).payload);
                map.set(IS_CAMPAIGN_LOADED, true);
                map.set(IS_CAMPAIGN_LOADING, false);
                map.set(IS_CAMPAIGN_FAILED, false);
            });
        }
        case constants.deleteCampaignAction.fulfilled: {
            return mutateState(state, map => {
                const oldCampaignsLog = map.get(CAMPAIGNS_LOG) as ICampaignLog[];
                const newCampaignLog = oldCampaignsLog.filter(campaign =>
                    (action as IDeleteCampaignActionSuccess).payload.id !== campaign.id);
                map.set(CAMPAIGNS_LOG, newCampaignLog);

            });
        }
        default:
            return state;
    }
};


export default campaignsLogReducer;
