import * as React from "react";
import * as styles from './styles.module.sass';
import '../../rc-steps/assets/index.css';
import '../../rc-steps/assets/iconfont.css';
import StepsCmp, {Step as StepCmp} from '../../rc-steps/es/index';
import {ClipLoader} from "react-spinners";
import { CampaignFormScreens } from "../OnlineToOfflineFormMain/lib";


interface IStepsWithProgressProps {
  children: Array<React.ReactElement<any>>;
  className?: string;
  nextDisabled?: boolean;
  titles: string[];
  shouldGoNext: (screenIndex: CampaignFormScreens) => boolean;
  onSaveClicked: () => void;
  saving: boolean;
}

interface IStepsWithProgressState {
  currentStepIndex: number;
}

const Steps = StepsCmp as any;
const Step = StepCmp as any;

export class StepsWithProgress extends React.Component<IStepsWithProgressProps, IStepsWithProgressState> {
  constructor(props) {
    super(props);
    this.state = {
      currentStepIndex: props.initialStep || 0,
    }
  }

  public render() {
    const {children, className, titles, onSaveClicked, nextDisabled, saving} = this.props;
    const {currentStepIndex} = this.state;
    const showNext = currentStepIndex < React.Children.count(this.props.children) - 1;
    const showBack = currentStepIndex > 0;
    return (
      <div className={`${styles.wrapper} ${className}`}>
        <Steps style={{width: '100%'}} labelPlacement="vertical" current={currentStepIndex}>
          {titles.map((t, i) => {
            return <Step key={i} title={t}/>;
          })}
        </Steps>
        {children[currentStepIndex]}
        <div className={styles.btnsWrapper}>
          {showBack && <button type='button' onClick={this.handleBackStep} className={styles.back}>
            Back
          </button>}
          {showNext ?
            <button style={{
              margin: !showBack ? 0 : '0 1.6rem',
              background: nextDisabled ? '#fee6a9' : '#fdbb11'
            }}
                    type='button'
                    onClick={this.handleNextStep}
                    className={styles.next}>
              Next
            </button> :

            <button type='button' disabled={saving} onClick={onSaveClicked}
                    className={styles.next}>
              {saving ? <ClipLoader
                  sizeUnit={"px"}
                  size={20}
                  color={'white'}
                  loading={true}
                /> :
                'Save'}
            </button>}
        </div>
      </div>
    )
  }

  private handleNextStep = () => {
    const {currentStepIndex} = this.state;
    const {shouldGoNext} = this.props;
    const lastIndex = React.Children.count(this.props.children) - 1;
    if (currentStepIndex < lastIndex && shouldGoNext(currentStepIndex)) {
      this.setState({currentStepIndex: currentStepIndex + 1}, () => {
        setTimeout(() => window.scrollTo({
          top: 0,
          behavior: "smooth",
        }))
      });
    }
  };
  private handleBackStep = () => {
    const {currentStepIndex} = this.state;
    if (currentStepIndex > 0) {
      this.setState({currentStepIndex: currentStepIndex - 1});
    }
  };
}

export default StepsWithProgress;
