import instance from "./axios";
import { campaignDataById } from "./requests";

export const menu_item_type = "menu_item";
type menu_item_type = typeof menu_item_type;
export const voucher_type = "voucher";
type voucher_type = typeof voucher_type;
export const points_type = "points";
type points_type = typeof points_type;

type giftType = menu_item_type | voucher_type | points_type;

export enum CampaignType {
  special_event_OTO = "special_event_OTO",
  normal_OTO = "normal_OTO"
}

export enum CampaignLanguage {
  en = "en",
  ar = "ar"
}
export interface ICampaignData {
  store: {
    image: string;
    country_iso_code: "eg" | "sa";
  };
  campaign: {
    type?: CampaignType;
    campaign_logo?: string;
    max_users: string;
    campaign_language?: CampaignLanguage;
    campaign_page_title: string;
    campaign_page_description: string;
    campaign_page_main_color: string;
    campaign_page_text_color: string;
    customers_count: number;
    campaign_page_download_title: string;
    days_left?: number;
    gift: {
      type: giftType;
      data: {
        points?: number;
        gift_name?: string;
      };
    };
  };
}

export const getCampaignData = (id: string) => {
  return instance.get<ICampaignData>(campaignDataById(id));
};
