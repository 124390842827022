import { hot } from "react-hot-loader";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import BreadCrumbs from "../../../contexts/BreadCrumbs";
import { RouteComponentProps } from "react-router";
import { getOTOCampaign } from "../../../axios/getOTOCampaign";
import StoreData from "../../../contexts/StoreData";
import { useProgressState } from "../../../../Utils/custom-hooks/useProgressState";
import { IOTOCampaign, menu_item, points } from "../RecentOTOs";
import * as styles from "../RecentOTOs/styles.module.sass";
import { LoadingPageInfo } from "../../LoadingPageInfo";
import OnlineToOfflineFormMain from "../OnlineToOfflineFormMain";
import moment from "moment";
import { convertDataURLToFile } from "../../../../Utils/helpers";

const prefixReachURL = (url: string) => {
  const baseURL = process.env.LUMEN_ENDPOINT as string;
  return baseURL + url;
};

const EditOTOCampaign: React.FunctionComponent<RouteComponentProps> = ({
  location
}) => {
  const { setCrumbs } = React.useContext(BreadCrumbs);
  const { token } = useContext(StoreData);
  const params = new URLSearchParams(location.search);
  const campaignId = params.get("id");
  const {
    failure,
    success,
    setFailure,
    setSuccess,
    setLoading
  } = useProgressState();
  const [campaignData, setCampaignData] = useState<IOTOCampaign | undefined>(
    undefined
  );
  useEffect(() => {
    if (campaignId) {
      setLoading();
      getOTOCampaign(token, campaignId)
        .then(async ({ data }) => {
          const campaignCustomersNumber = data.customers_count || 0;
          const isInfinite = data.max_users === "infinity";
          const isPastExpirationDate = data.days_left <= 0;
          const hasReachedTarget =
            !isInfinite && campaignCustomersNumber >= +data.max_users;
          const gift: any = {
            ...data.gift,
            image:
              data.gift.type === "Voucher"
                ? await convertDataURLToFile(
                    prefixReachURL(data.gift.image as string)
                  )
                : undefined
          };
          setCampaignData({
            ...data,
            haxExpired: isPastExpirationDate || hasReachedTarget,
            gift
          });
          setSuccess();
        })
        .catch(setFailure);
    }
  }, [campaignId]);
  useEffect(() => {
    setCrumbs([
      {
        name: "Koinz Reach",
        url: "",
        disabled: true
      },
      {
        name: "Edit Online-To-Offline campaign",
        url: "/"
      }
    ]);
  }, []);

  let render = <LoadingPageInfo />;
  const tabsExtension = React.useMemo(() => {
    let tab_index = 0;
    let gift_tab_index = 0;
    if (campaignData) {
      const {
        gift: { type }
      } = campaignData;
      if (type === menu_item || type === "Voucher") {
        tab_index = 1;
      }
      if (type === "Voucher") {
        gift_tab_index = 1;
      }
    }
    return {
      tab_index,
      gift_tab_index
    };
  }, [campaignData]);

  if (success) {
    const {
      name,
      max_users,
      campaign_page_main_color,
      campaign_page_text_color,
      only_first_store_visitors,
      id,
      end_date,
      gift_expiration_date,
      campaign_page_title,
      campaign_page_description,
      campaign_page_download_title,
      haxExpired,
      gift
    } = campaignData as IOTOCampaign;
    const points_per_customer =
      gift.type === points ? String(gift.points) : undefined;
    const item_id = gift.type === menu_item ? gift.item_id : "";
    const voucher = gift.type === "Voucher" ? gift : undefined;
    const campaignFormData = {
      points_per_customer,
      name,
      incentive_number: max_users,
      voucher,
      item_id,
      download_title: campaign_page_download_title,
      ...tabsExtension,
      ending_date: moment(end_date, "DD-MM-YYYY"),
      gift_expiration_date: moment(gift_expiration_date, "DD-MM-YYYY"),
      heading_text: campaign_page_title,
      text_color: { hex: campaign_page_text_color },
      main_color: { hex: campaign_page_main_color },
      description: campaign_page_description,
      id
    };
    render = (
      <>
        {haxExpired ? (
          <p className={styles.rejectedText}>This campaign can't be edited!</p>
        ) : (
          <OnlineToOfflineFormMain
            currentCustomersCount={campaignData && campaignData.customers_count}
            isEdit={true}
            initialNewCustomersOnly={!!only_first_store_visitors}
            initialUnlimitedIncentives={max_users === "infinity"}
            initialValues={campaignFormData}
          />
        )}
      </>
    );
  }

  if (failure) {
    render = (
      <div className={styles.rejectedText}>
        Failed to load online-to-offline campaigns
      </div>
    );
  }

  return render;
};

export default hot(module)(EditOTOCampaign);
