import * as constants from '../../constants';
import {mutateState} from "../../helpers/mutate-state";
import {Map} from "immutable";
import {
    IFilterCampaignActionSuccess,
} from "../../types";


const IS_FILTERED = 'isFiltered';
const IS_FILTERING = 'isFiltering';
const IS_FAILED_TO_FILTER = 'isFailedToFilter';
const COST_PER_SMS = 'cost_per_SMS';
const TOTAL_USERS = 'total_users';

export interface IFilterCampaignReducerState {
    [IS_FILTERED]: boolean,
    [IS_FILTERING]: boolean,
    [IS_FAILED_TO_FILTER]: boolean,
    [COST_PER_SMS]: number,
    [TOTAL_USERS]: number,
}

const initialState: IFilterCampaignReducerState = Map({
    [IS_FILTERED]: false,
    [IS_FILTERING]: false,
    [IS_FAILED_TO_FILTER]: false,
    [COST_PER_SMS]: 0,
    [TOTAL_USERS]: 0,

}).toJS();

const filterCampaignReducer = (state: IFilterCampaignReducerState = initialState, action: IFilterCampaignActionSuccess): IFilterCampaignReducerState => {
    switch (action.type) {
        case constants.filterCampaignAction.fulfilled: {
            return mutateState(state, map => {
                map.set(IS_FILTERED, true);
                map.set(COST_PER_SMS, action.payload.cost_per_SMS);
                map.set(TOTAL_USERS, action.payload.total_users);
                map.set(IS_FAILED_TO_FILTER, false);
                map.set(IS_FILTERING, false);
            });
        }
        case constants.filterCampaignAction.rejected: {
            return mutateState(state, map => {
                map.set(IS_FAILED_TO_FILTER, true);
                map.set(IS_FILTERED, false);
                map.set(IS_FILTERING, false);
            });
        }
        case constants.filterResetAction:{
            return mutateState(state, map => {
                map.set(IS_FILTERED, false);
                map.set(IS_FAILED_TO_FILTER, false);
                map.set(COST_PER_SMS, 0);
                map.set(TOTAL_USERS, 0);
                map.set(IS_FILTERING, false);
            });
        }
        case constants.filterCampaignAction.requested:{
            return mutateState(state, map => {
                map.set(IS_FILTERING, true);
            });
        }
        default:
            return state;
    }
};


export default filterCampaignReducer;
