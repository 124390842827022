import React from 'react';
import * as styles from './styles.module.sass';
import IcoMoon from 'react-icomoon';
import {ICampaignLog} from "../../../../types";
import * as moment from "moment-timezone";
import {deleteCampaign} from "../../../../redux-store/actions/delete-campaign-actions";
import {connect} from "react-redux";
import ConfirmationModal from "../../ConfirmationModal";
import {Tooltip} from "reactstrap";
import {RouteComponentProps, withRouter} from "react-router";

interface IProps {
    campaign: ICampaignLog;
    deleteCampaign: (id: string) => void;
}

interface IState {
    confirmationModal: boolean;
    tooltipOpen: boolean;
}

class Operation extends React.Component<IProps & RouteComponentProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            confirmationModal: false,
            tooltipOpen: false
        }
    }

    public toggleToolTip = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    };
    public openCampaign = () => {
        const {history: {push}, campaign: {id}} = this.props;
        push(`/sms/edit?id=${id}`)
    };
    public toggleConfirmationModal = () => {
        this.setState(prevState => ({
            confirmationModal: !prevState.confirmationModal,
        }))
    };
    public deleteCampaign = () => {
        this.props.deleteCampaign(this.props.campaign.id);
    };

    public render() {
        let status;
        let wrapperStyle;
        const {campaign} = this.props;
        const beforeAccept = campaign.status === 0 || campaign.status === 1 || campaign.status === 3
            || campaign.status === 2;
        const afterAccept = campaign.status === 3 || campaign.status === 4 || campaign.status === 5;
        const isEdit = campaign.status === 0 || campaign.status === 1 || campaign.status === 3;
        if (campaign.status === 0 || campaign.status === 1) {
            status = (<div className={styles.statusPending}>
                <IcoMoon icon="clock"/>
                <p>Waiting for review</p>
            </div>);
            wrapperStyle = styles.pendingWrapper;
        }
        if (campaign.status === 2) {
            status = (<div className={styles.statusRejected}>
                <IcoMoon icon="clock"/>
                <p>Rejected</p>
            </div>);
            wrapperStyle = styles.rejectedWrapper;
        }
        if (campaign.status === 3) {
            status = (<div className={styles.statusPending}>
                <IcoMoon icon="clock"/>
                <p>Scheduled</p>
            </div>);
            wrapperStyle = styles.scheduledWrapper;
        }
        if (campaign.status === 4) {
            status = (<div className={styles.statusPayment}>
                <IcoMoon icon="clock"/>
                <p>Running</p>
            </div>);
            wrapperStyle = styles.runningWrapper;
        }
        if (campaign.status === 5) {
            status = (<div className={styles.statusCompleted}>
                <IcoMoon icon="clock"/>
                <p>Completed</p>
            </div>);
            wrapperStyle = styles.completedWrapper;
        }
        return (
            <div className={wrapperStyle}>
                <h6 id={`tt${campaign.id}`} className={styles.title}>
                    {campaign.campaign_name.substring(0, 15)}
                    {campaign.campaign_name.length > 15 && '...'}</h6>
                <Tooltip style={{fontSize: '1.8rem'}} placement="top" target={`#tt${campaign.id}`}
                         toggle={this.toggleToolTip}
                         isOpen={this.state.tooltipOpen}>
                    {campaign.campaign_name}
                </Tooltip>
                {status}
                <div className={styles.date}>
                    <img src={require('../../../../assets/noun_date.svg')} className={styles.logo}/>
                    <p>{moment(campaign.launch_timestamp, 'x').format('DD-MM-YYYY')} / {moment(campaign.launch_timestamp, 'x').format('hh:mm a')}</p>
                </div>
                <div className={styles.user}>
                    <img src={require('../../../../assets/noun_User.svg')} className={styles.logo}/>
                    <p style={{display: 'flex'}}>{beforeAccept ?
                        <div>{(campaign.total_users_count)
                        * (campaign.cost_per_SMS || campaign.sms_number)} Point</div> :
                        <div>{campaign.total_users_count} Users</div>}

                        {afterAccept
                        && <span>| {campaign.sent_users_count || 0} Sent</span>}</p>
                </div>
                <div className={styles.btnsWrapper}>
                    <button onClick={this.openCampaign}
                            className={styles.btn}>{isEdit
                        ? 'Edit' : 'View'}</button>
                    {isEdit &&
                    <img src={require('../../../../assets/delete.svg')} onClick={this.toggleConfirmationModal}
                         className={styles.delete}/>}
                </div>
                {<ConfirmationModal paragraph='Confirm your campaign deletion'
                                    action={this.deleteCampaign} title='Delete campaign'
                                    modal={this.state.confirmationModal} toggle={this.toggleConfirmationModal}/>}

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteCampaign: (id: string) => dispatch(deleteCampaign(id)),
});

export default connect(null, mapDispatchToProps)(withRouter(Operation))
