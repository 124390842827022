import {Moment} from "moment";
import * as moment from "moment";

export const transformPhone = (str: string) => str && str.replace(/\D/g, '');
export const trimString = (str: string) => str && str.trim();
export const normalizeDate = (m:Moment):number => +m.startOf('day').format('x');
export const normalizeNumber = (cnt:string):number => +cnt;
export const transformDate = (timestamp: number) => moment(timestamp, 'x');
const normalizePhone = value => {
  if (!value) {
    return value
  }

  return value.replace(/[^\d]/g, '');
};

export default normalizePhone
