import {call, put, select, takeLatest} from "redux-saga/effects";
import {editCampaignAction} from "../../constants";
import {selectCurrentCampaignId, selectForm, selectToken} from "../selectors";
import {editCampaignFailure, editCampaignSuccess} from "../actions/edit-campaign-actions";
import {editCampaign} from "../../axios";
import {notify} from "react-notify-toast";
import {IFormState} from "../rootReducer";
import {convertFormToCampaignData} from "./filterCampaign";

function* editCampaignSaga() {
    try {
        const token = yield select(selectToken);
        const forms: IFormState = yield select(selectForm);
        const currentCampaignId: IFormState = yield select(selectCurrentCampaignId);
        const {sms, fullTime, branches, visitsCount, lastVisit, avg, campaignName} = forms.filterCampaignForm.values as any;
        const data = convertFormToCampaignData(true, fullTime.date, fullTime.time, fullTime.timezone, sms,
            lastVisit.dropDownValue, lastVisit.value1 || undefined, lastVisit.value2 || undefined,
            visitsCount.dropDownValue, (avg || {}).value1 || undefined, (avg || {}).value2 || undefined,
            branches, campaignName);
        yield call(editCampaign, {...data, id: currentCampaignId}, token);
        yield put(editCampaignSuccess());
        notify.show('Campaign Edited', 'success');
    } catch (e) {
        yield put(editCampaignFailure(e));
        if (e.response.status !== 403) {
            notify.show('Please try again. if the problem persists please contact us ASAP.', 'error');
        }
    }
}

export function* watchEditCampaignSaga() {
    yield takeLatest(editCampaignAction.requested, editCampaignSaga);
}
