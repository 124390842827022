import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {ICampaignLog} from "../types";




export const getCampaign = (campaign_id:string,token:string): AxiosReturn<{response:ICampaignLog}> => {
  return axios.get(Requests.getCampaign(campaign_id),{
      baseURL:process.env.LUMEN_ENDPOINT,
    headers:{
      token,
    }
  })
};
