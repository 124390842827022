import {call, put, select, takeLatest} from "redux-saga/effects";
import {getCampaignAction} from "../../constants";
import {getCampaign} from "../../axios";
import {selectToken} from "../selectors";
import {IGetCampaignAction} from "../../types";
import {getCampaignFailure, getCampaignSuccess} from "../actions/campaigns-log";

function* getCampaignSaga(action: IGetCampaignAction) {
    try {
        const token = yield select(selectToken);
        const res = yield call(getCampaign, action.payload, token);
        const campaign = res.data.campaign;
        yield put(getCampaignSuccess(campaign));
    } catch (e) {
        yield put(getCampaignFailure(e));
    }
}

export function* watchGetCampaignSaga() {
    yield takeLatest(getCampaignAction.requested, getCampaignSaga);
}
