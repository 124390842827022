import * as React from 'react';
import * as styles from './App.module.sass';
import Notifications from 'react-notify-toast';
import { hotjar } from 'react-hotjar';
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import { Route, Switch } from "react-router";
import { HashRouter } from "react-router-dom";
import Login from "./components/Login";
import ScrollToTop from "./components/Scroller";
import { useEffect, useState } from "react";
import BreadCrumbs, { ICrumbData } from './contexts/BreadCrumbs';
import memoize from 'lodash/memoize';
import isEqual from 'lodash/isEqual';
import { IStore } from "./types";
import StoreData from "./contexts/StoreData";
import { PrivateRoute } from "./components/PrivateRoute";
import AuthenticatedRoutesRoot from './components/AuthenticatedRoutes';
import MiniAppExample from './components/OnlineToOfflineCampaigns/MiniAppExample';
import {UpdateNotifier} from 'shopx-shared-components/src';
import { useRecoilValue } from "recoil";
import { remoteAuthenticationAtom } from "./atoms/remote-authentication.atom";

interface IState {
  storeData: IStore | null;
  isLoggedIn: boolean;
  token: string;
}

export const Aux = props => props.children;

const AuthenticatedRoutes: React.FunctionComponent = () => {
  const [crumbs, setCrumbs] = useState<ICrumbData[]>([]);
  const updateCrumbs = memoize(data => {
    if (!isEqual(data, crumbs)) {
      setCrumbs(data);
    }
  });
  return (
    <>
      <NavBar />
      <div className={styles.navBar} />
      <div className={styles.secondarySection}>
        <BreadCrumbs.Provider value={{ crumbs, setCrumbs: updateCrumbs }}>
          <Header />
          <UpdateNotifier appName={"Koinz Reach"} />
          <AuthenticatedRoutesRoot />
        </BreadCrumbs.Provider>
      </div>
    </>
  );
};
const App: React.FunctionComponent = () => {
  useEffect(() => {
    const isRemoteAuthentication = localStorage.getItem(
      "is_remote_authentication"
    );
    if (!isRemoteAuthentication) {
      hotjar.initialize(1123651, 6);
    }
  }, []);
  const [state, setState] = useState<IState>({
    storeData: null,
    token: "",
    isLoggedIn: false
  });
  const setStoreData = (store: IStore, token: string) => {
    setState({
      storeData: store,
      isLoggedIn: true,
      token
    });
  };
  const remoteAuthenticationValue = useRecoilValue(remoteAuthenticationAtom);

  return (
    <HashRouter>
      <div className={`${styles.App}`}>
        {remoteAuthenticationValue && (
          <div className={styles.dashboardPreview}>
            <span>Reach Dashboard Live Preview</span>
          </div>
        )}
        <div style={{ fontSize: "2rem" }}>
          <Notifications options={{ zIndex: 2000 }} />
        </div>
        <div className={styles.mainSection}>
          <ScrollToTop {...{}}>
            <StoreData.Provider value={{ ...state, setStoreData }}>
              <Switch>
                <Route exact={true} path="/login" component={Login} />
                <PrivateRoute
                  path="/campaign-example"
                  component={MiniAppExample}
                />
                <PrivateRoute component={AuthenticatedRoutes} />
              </Switch>
            </StoreData.Provider>
          </ScrollToTop>
        </div>
      </div>
    </HashRouter>
  );
};


export default App;

