import Chart from 'react-apexcharts';
import React, { useMemo, useState, useEffect } from 'react';
import * as styles from './styles.module.sass';
import DailyStatsSelect, { ChartTypes, chartTypesSelectItems } from './DailyStatsSelect';
import { ISelectItemData } from '../../FormElements/RenderDropDownSelect';
import { useChartsAPIs } from "./useChartsAPIs";
import { IOTOCampaign } from "../RecentOTOs";
import moment from 'moment';
import { ApexOptions } from 'apexcharts';


const chartOptions = (campaignData: IOTOCampaign): ApexOptions => ({
  // tooltip: {
  //   enabled: false,
  // },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '20%',
      colors: {
        ranges: [{
          from: 0,
          to: Infinity,
          color: '#fdbb11'
        }]
      },
    },
  },
  grid: {
    yaxis: {
      lines: { show: false }
    },
  },
  yaxis: {
    min: 0,
    labels: {
      style: {
        cssClass: styles.yAxisLabel
      },
    },
    tickAmount: 5,
  },
  dataLabels: {
    enabled: false,
  },
  chart: {
    fontFamily: "Avenir, 'Avenir LT Std', 'Cairo', 'Roboto', sans-serif",
  },
  xaxis: {
    min: campaignData && campaignData.created_at ? +moment(campaignData.created_at.toString(), 'x').startOf('day').subtract(1, 'day').format('x') : undefined,
    max: campaignData && campaignData.end_date ? +moment(campaignData.end_date, 'DD-MM-YYYY').format('x') : undefined,
    type: 'datetime',
    floating: false,
    axisTicks: {
      height: 14,
      color: 'rgba(2, 10, 39, 0.1)'
    },
    axisBorder: {
      color: 'rgba(2, 10, 39, 0.1)'
    },
    labels: {
      style: {
        cssClass: styles.xAxisLabel
      }
    }
  },
  tooltip: {
    // enabled: false,
    y: {
      formatter: (val) => {
        return val + " users"
      }
    }
  },
  fill: {
    opacity: 1
  },
});


const DailyStatChart: React.FC<{ campaignData: IOTOCampaign }> = ({ campaignData }) => {
  const [selectedChartType, setSelectedChartType] = useState<ChartTypes>(ChartTypes.Clicks);
  const [seriesName, setSeriesName] = useState(chartTypesSelectItems[0].label);
  const chartSeries = useChartsAPIs(campaignData.id, selectedChartType, seriesName);
  useEffect(() => {
    setSeriesName((chartTypesSelectItems.find(a => a.value === selectedChartType) as any).label);
  }, [selectedChartType])
  const handleChangeChart = (item: ISelectItemData<ChartTypes>) => {
    setSelectedChartType(item.value);
  };
  const memoizedChartOptions = useMemo(() => chartOptions(campaignData), [campaignData]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.chartHeader}>
        <DailyStatsSelect value={selectedChartType} onChange={handleChangeChart} />
      </div>
      <Chart type='bar'
        options={memoizedChartOptions}
        series={chartSeries || []}
        height="280"
      />
    </div>
  )
};

export default DailyStatChart;
