"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
const themeDisabled = {
    chipsContainer: {
        display: "flex",
        position: "relative",
        border: "1px solid #F5F5F5",
        backgroundColor: '#F3F3F3',
        alignItems: "center",
        pointerEvents: 'none',
        flexWrap: "wrap",
        padding: ".5rem 2rem",
        fontSize: "2rem",
        borderRadius: 5,
        ':focus': {
            border: "1px solid #aaa"
        }
    },
    container: {
        flex: 1,
    },
    containerOpen: {},
    input: {
        border: 'none',
        outline: 'none',
        display: 'none',
        boxSizing: 'border-box',
        width: '100%'
    },
    suggestionsContainer: {},
    suggestionsList: {
        position: 'absolute',
        maxHeight: '30rem',
        overflow: 'auto',
        border: '1px solid #ccc',
        zIndex: 10,
        left: 0,
        top: '100%',
        width: '100%',
        backgroundColor: '#fff',
        listStyle: 'none',
        padding: 0,
        fonSize: '2rem',
        margin: 0
    },
    suggestion: {
        padding: '10px 20px',
    },
    suggestionHighlighted: {
        background: '#FEBE19'
    },
    sectionContainer: {},
    sectionTitle: {}
};

exports.default = themeDisabled;
