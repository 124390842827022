import Datetime from "react-datetime";
import * as React from "react";
import * as styles from './styles.module.sass';
import {WrappedFieldProps} from "redux-form";
import 'react-datetime/css/react-datetime.css';
import {Moment} from "moment";
import * as moment from "moment";

interface IProps {
  label: string;
  className?: string;
  disabled: boolean;
}

export class RenderDatePicker extends React.Component<WrappedFieldProps & IProps> {
  public render() {
    const onlyTodayAndAfter = (current: Moment) => {
      return current.isAfter(moment().startOf('day').subtract(1, 'day'));
    };
    const {input, label, meta: {touched, warning, error}, className} = this.props;
    return (
      <div className={`${styles.datePickerWrapper} ${className}`}>
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
          {touched && ((error && <span className={styles.formError}>{error}</span>) || (warning &&
            <span>{warning}</span>))}
        </label>
        <div className={styles.datePicker}>
          <Datetime
            isValidDate={onlyTodayAndAfter}
            disableOnClickOutside={true}
            inputProps={{readOnly: true, className: styles.dateInput, disabled: this.props.disabled}}
            timeFormat={false}
            value={input.value}
            defaultValue='Pick a date...'
            onChange={input.onChange}/>
          <div className={styles.icon}>
            <img src={require('../../assets/calendar.svg')}/>
          </div>
        </div>
      </div>
    );
  }
}

