import * as styles from './styles.module.sass';
import { hot } from "react-hot-loader";
import * as React from "react";
import { Field } from "redux-form";
import {minValue, required} from "../../../../redux-store/validators";
import { useGetCurrency } from "../../../../../Utils/getLocalizedCurrency";
import { RenderPointsToMoneyField } from "../../../FormElements/RenderPointsToMoneyField";
import { useContext, useEffect, useState } from "react";
import StoreData from "../../../../contexts/StoreData";
import { IStore } from "../../../../types";
import { getLoyaltyItems, ILoyaltyItem } from "../../../../axios/getLoyaltyItems";
import { useProgressState } from "../../../../../Utils/custom-hooks/useProgressState";
import { LoadingPageInfo } from "../../../LoadingPageInfo";
import SpendingForGift from "../../SpendingForGift";

const useGetLoyaltyItems = () => {
  const { token } = useContext(StoreData);
  const { setFailure, setSuccess, setLoading, success, failure } = useProgressState();
  const [loyaltyItems, setLoyaltyItems] = useState<ILoyaltyItem[]>([]);
  useEffect(() => {
    setLoading();
    getLoyaltyItems(token)
      .then(({ data }) => {
        setLoyaltyItems(data);
        setSuccess();
      }).catch(setFailure);
  }, []);
  return {
    success,
    failure,
    loyaltyItems
  }
};

const ShopXPointsIncentive: React.FunctionComponent = () => {
  const currency = useGetCurrency();
  const { loyaltyItems, failure, success } = useGetLoyaltyItems();
  const { storeData } = useContext(StoreData);
  const minValue1 = React.useCallback(minValue(1), []);
  let render = <LoadingPageInfo />;
  if (failure) {
    render = <p className={styles.error}>Something went wrong!</p>
  }
  if (success) {
    render = (
      <div className={styles.cardsWrapper}>
        {loyaltyItems.map(item => <SpendingForGift key={item.id} giftData={item} />)}
      </div>
    )
  }
  return (
    <div className={styles.wrapper}>
      <Field
        className={styles.width}
        name='points_per_customer'
        currency={currency}
        convertingRatio={(storeData as IStore).converting_ratio}
        placeholder='Points per customer'
        component={RenderPointsToMoneyField as any}
        label={'Points per customer'}
        validate={[required,minValue1]}
      />
      <div className={styles.separator} />
      <p className={styles.subH}>Suggestion: Choose enough points for your customers to unlock the first gift.</p>
      {render}
    </div>
  );
};

export default hot(module)(ShopXPointsIncentive);
