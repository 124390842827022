import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {IStore} from "../types";

export const getStoreProfile = (token: string): AxiosReturn<{store: IStore}> => {
    return axios.get(Requests.storeProfile, {
        headers: {
            token,
        }
    })
};
