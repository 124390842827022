import * as React from "react";
import * as styles from './styles.module.sass';
import BreadCrumbs, {ICrumbData} from "../../contexts/BreadCrumbs";
import {Link} from "react-router-dom";
import {useContext} from "react";


const Crumb: React.FC<ICrumbData> = ({name, url, disabled}) => {
  return (
    <>
      {disabled ? (
        <p className={styles.disabledCrumb}>{name}</p>
      ) : (
        <Link to={url} className={styles.crumb}>
          {name}
        </Link>
      )}
      <div className={styles.separator} />
    </>
  );
};

const Crumbs: React.FC = () => {
  const {crumbs} = useContext(BreadCrumbs);
  return (
    <div className={styles.crumbsWrapper}>
      {
        crumbs.map(c => <Crumb key={c.url} {...c}/>)
      }
    </div>
  );
};

const Header: React.FunctionComponent = () => {
  return (
    <header className={styles.header}>
      <Crumbs/>
    </header>
  );
};

export default Header;
