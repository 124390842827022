import * as React from "react";
import {Route, Switch} from "react-router";
import {hot} from "react-hot-loader";
import {HashRouter} from "react-router-dom";
import SMSCampaignsAuthenticatedRoutes from "./SMSCampaigns";
import Home from "../Home";
import Pricing from "../Pricing";
import Login from "../Login";
import {InfoPage} from "../info-page";
import OTOCampaignsAuthenticatedRoutes from "./OTOCampaigns";

const NotFoundContent = () => <h1 style={{margin: 'auto'}}>Not Found</h1>;

const AuthenticatedRoutesRoot: React.FunctionComponent = () => {
  return (
    <HashRouter>
        <Switch>
          <Route exact={true} path="/" component={Home}/>
          <Route exact={true} path="/buy" component={Pricing}/>
          <Route exact={true} path="/login" component={Login}/>
          <Route exact={true} path="/info" component={InfoPage}/>
          <Route path='/sms' component={SMSCampaignsAuthenticatedRoutes}/>
          <Route path="/online-to-offline" component={OTOCampaignsAuthenticatedRoutes}/>
          <Route render={NotFoundContent}/>
        </Switch>
    </HashRouter>
  );
};

export default hot(module)(AuthenticatedRoutesRoot);
