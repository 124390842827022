import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {ICreateOTOCampaign} from "./createOTOCampaign";


export interface IEditOTOCampaign extends ICreateOTOCampaign {
  id: string;
}

export interface IEditOTOPointsCampaign extends IEditOTOCampaign {
  points_per_customer: number,
}

export interface IEditOTOMenuItemCampaign extends IEditOTOCampaign {
  item_id: string,
}

export interface IEditOTOVoucherCampaign extends IEditOTOCampaign {
  voucher: {
    name: string;
    image: string;
  }
}

export type editCampaignDataType = IEditOTOPointsCampaign | IEditOTOVoucherCampaign | IEditOTOMenuItemCampaign

const genOTOUpdate = <DataType = IEditOTOCampaign>(req: (id: string) => string) => (token: string, data: editCampaignDataType): AxiosReturn<{ id: string }> => {
  return axios.put(req(data.id), data, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    }
  })
};

export const editOTOPointsCampaign = genOTOUpdate<IEditOTOPointsCampaign>(Requests.OTOCampaignPointsById);
export const editOTOMenuItemCampaign = genOTOUpdate<IEditOTOMenuItemCampaign>(Requests.OTOCampaignMenuItemById);
export const editOTOVoucherCampaign = genOTOUpdate<IEditOTOVoucherCampaign>(Requests.OTOCampaignVoucherById);
