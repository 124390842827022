import React from 'react';
import * as styles from './styles.module.sass';
import {connect} from "react-redux";
import PricingCard from "./PricingCard";
import {getQuotas} from "../../redux-store/actions/get-quotas-actions";
import {IRootReducerState} from "../../redux-store/rootReducer";
import {IQuota} from "../../types";
import {LoadingPageInfo} from "../LoadingPageInfo";
import {Title} from "react-head";

interface IProps {
    login: () => void;
    getQuotas: () => void;
    quotas: IQuota[];
    loading: boolean;
    loaded: boolean;
    failed: boolean;
}

class Pricing extends React.Component<IProps> {

    public componentDidMount() {
        this.props.getQuotas();
    }

    public render() {

        const {loaded, failed, quotas} = this.props;
        let render = <LoadingPageInfo/>;
        if (loaded) {
            render = (

                <div className={styles.wrapper}>

                    <Title>Pricing</Title>
                    <h1 className={styles.title}>Select your quota</h1>
                    <p className={styles.desc}>Select the quota fitting for your consumption, Confirm your choice, <br/>
                        and an account manager will be in touch to confirm and transfer the credit to you.</p>
                    <div className={styles.pricesCover}>
                        {quotas.length ? quotas.map(quota => <PricingCard {...{}} key={quota.id} quota={quota}/>)
                            : <div className={styles.rejectedText}><h1>There are no quotas.</h1></div>}
                    </div>
                    <footer className={styles.footer}>
                        <h1 className={styles.f_title}>FAQs</h1>
                        <div className={styles.f_Q}>
                            <div className={styles.questionWrapper}>
                                <p>Is VAT included in this price?</p>
                                <span>No, VAT is not included. It will be added to your final bill.</span>
                            </div>

                            <div className={styles.questionWrapper}>
                                <p>What are a points, and what are they worth?</p>
                                <span>Points is a Koinz reach currency. Every 1 point is worth 1 SMS count <br/>
                                Also, your points do not expire, you can use them however you like for as long as you want.</span>
                            </div>

                            <div className={styles.questionWrapper}>
                                <p>How to buy points?</p>
                                <span>Simply order a quota and confirm your choice, our account manager will contact <br/>
                                you within 24 hours to confirm your purchase. Once he does,  you'll find the requested points added to your account!</span>
                            </div>

                            <div className={styles.questionWrapper}>
                                <p>Are there any other costs involved when using Koinz Reach?</p>
                                <span>No. aside from the credit price+VAT Koinz reach operates on a prepaid basis. You simply purchase SMS credit bundles and <br/>
                                 use them as and when you need to. There is no setup fee, and no contract fee involved when using our SMS service.</span>
                            </div>
                        </div>
                    </footer>
                </div>
            );
        }
        if (failed) {
            render = (
                <div className={styles.rejectedText}><h1>Failed to load quotas</h1></div>);
        }
        return render;
    }
}

const mapStateToProps = (state: IRootReducerState) => ({
    quotas: state.quotasReducer.quotas,
    loaded: state.quotasReducer.loaded,
    loading: state.quotasReducer.loading,
    failed: state.quotasReducer.failed
});

const mapDispatchToProps = (dispatch) => ({
    getQuotas: () => dispatch(getQuotas())
});

export default connect(mapStateToProps, mapDispatchToProps)(Pricing) as any;
