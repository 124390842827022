import React, { useContext, useEffect } from "react";
import * as styles from "./styles.module.sass";
import HomeCard from "./HomeCard";
import { Aux } from "../../App";
import { Title } from "react-head";
import StoreData from "../../contexts/StoreData";
import BreadCrumbs from "../../contexts/BreadCrumbs";
import CampaignsHistory from "../SMSCampaigns/CampaignsHistory";
import RecentOTOs from "../OnlineToOfflineCampaigns/RecentOTOs";
import { trackEvent } from "../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../constants/events-for-tracking";

export interface IHomeCard {
  isNew?: boolean;
  wrapSubTitle?: boolean;
  title: string;
  subTitle: string;
  image: string;
  url: string;
  videoUrl: string | null;
}

const homeCampaignCards: IHomeCard[] = [
  {
    image: require("../../assets/icon-magnet.svg"),
    isNew: false,
    title: "Online-To-Offline",
    wrapSubTitle: true,
    subTitle: "acquire and track new customers to your business.",
    url: "/online-to-offline/new",
    videoUrl: "https://www.youtube.com/embed/_akZV9S1_vg"
  },
  {
    image: require("../../assets/icon-sms.svg"),
    title: "SMS",
    subTitle:
      "Send SMSs to your customers about your recent promotions, news " +
      "or bring back those who haven’t visited you in a while.",
    url: "/sms/new",
    videoUrl: "https://www.youtube.com/embed/_akZV9S1_vg"
  },
  {
    image: require("../../assets/giftbox_wrap.svg"),
    isNew: true,
    title: "OTO special occasions",
    wrapSubTitle: true,
    subTitle:
      "Acquire and track new customers to your business by making them sending gifts to each other",
    url: "/online-to-offline/new/special",
    videoUrl: null
  }
];

export const ReachBalance = () => {
  const { storeData } = useContext(StoreData);
  return (
    storeData && (
      <div className={styles.points}>
        <p>
          Your credit
          <br />
          balance
        </p>
        <span>
          {storeData.reach_points || 0}
          <span>Points</span>
        </span>
        <img src={require("../../assets/money.svg")} className={styles.img} />
      </div>
    )
  );
};

const NewCampaign: React.FunctionComponent = () => {
  const { setCrumbs } = useContext(BreadCrumbs);
  useEffect(() => {
    setCrumbs([
      {
        name: "Koinz Reach",
        url: "",
        disabled: true
      },
      {
        name: "Home",
        url: "/"
      }
    ]);
  }, []);
  useEffect(() => {
    const isRemoteAuthentication = localStorage.getItem(
      "is_remote_authentication"
    );
    if (!isRemoteAuthentication) {
      trackEvent(EVENTS_FOR_TRACKING["Visited reach"]);
    }
  }, []);
  return (
    <Aux>
      <div className={styles.wrapper}>
        <Title>Home</Title>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <h1>New Campaign</h1>
            <p>
              Our easy-to-use campaign builder will help you create the perfect
              <br /> campaign within minutes!
            </p>
          </div>
          <ReachBalance />
        </div>
        <div className={styles.cardsWrapper}>
          {homeCampaignCards.map((c, i) => (
            <HomeCard key={i} options={c} />
          ))}
        </div>
        <RecentOTOs subHistory={true} />
      </div>
      <CampaignsHistory subHistory={true} />
    </Aux>
  );
};

export default NewCampaign;
