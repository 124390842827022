import {
    IEditCampaignAction,
    IEditCampaignActionFailure,
    IEditCampaignActionSuccess, IResetFailAction,
    ISetCampaignEDitDataAction,
} from "../../types";
import * as actions from '../../constants';

export const setCampaignEditData = (data): ISetCampaignEDitDataAction => ({
    type: actions.setCampaignEditDataAction,
    payload: data
});
export const resetFail=():IResetFailAction =>({
    type: actions.resetFailAction
});
export const editCampaign = (): IEditCampaignAction => ({
    type: actions.editCampaignAction.requested,
});
export const editCampaignSuccess = (): IEditCampaignActionSuccess => ({
    type: actions.editCampaignAction.fulfilled,
});
export const editCampaignFailure = (error): IEditCampaignActionFailure => ({
    type: actions.editCampaignAction.rejected,
    payload: error
});