import { createCampaignDataType } from "../../../axios/createOTOCampaign";
import moment from "moment";
import { resizeImage } from "../GiftInboxCommon/AddSpecialItemForm";

export interface IOTOFormFieldsValues {
  name: string;
  incentive_number: number | "infinity";
  ending_date: number;
  gift_expiration_date?: number;
  heading_text: string;
  campaign_logo?: File[];
  description: string;
  main_color: { hex: string };
  text_color: { hex: string };
  download_title: string;
  points_per_customer?: string;
  voucher?: {
    name: string;
    image: File[];
  };
  item_id?: string;
}

export interface IOTOFormProps {
  isEdit?: boolean;
  initialUnlimitedIncentives: boolean;
  initialNewCustomersOnly: boolean;
  formValues: IOTOFormFieldsValues;
  currentCustomersCount?: number;
}

export enum CampaignFormScreens {
  CampaignName = 0,
  CampaignSettings = 1,
  CampaignTemplate = 2
}

export const screensInputNames = {
  [CampaignFormScreens.CampaignName]: ["name"],
  [CampaignFormScreens.CampaignSettings]: [
    "incentive_number",
    "ending_date",
    "tab_index",
    "points_per_customer",
    "item_id",
    "voucher",
    "voucher.name",
    "voucher.image"
  ],
  [CampaignFormScreens.CampaignTemplate]: [
    "heading_text",
    "description",
    "main_color",
    "text_color",
    "download_title"
  ],
  length: 3
};

export const formatFormToRequestData = async (
  values: IOTOFormFieldsValues,
  newCustomersOnly: boolean | null
): Promise<createCampaignDataType> => {
  const {
    points_per_customer = "0",
    name,
    incentive_number,
    ending_date,
    heading_text,
    text_color,
    main_color,
    description,
    item_id,
    voucher,
    download_title
  } = values;
  const sharedData = {
    campaign_page_description: description,
    campaign_page_main_color: main_color.hex,
    campaign_page_text_color: text_color.hex,
    campaign_page_title: heading_text,
    end_date: moment(ending_date, "x").format("DD-MM-YYYY"),
    max_users: String(incentive_number),
    name,
    only_first_store_visitors: Number(!!newCustomersOnly),
    campaign_page_download_title: download_title
  };
  if (voucher) {
    const image = await resizeImage(voucher.image[0]);
    return {
      ...sharedData,
      voucher: {
        ...voucher,
        image
      }
    };
  }
  if (item_id) {
    return {
      ...sharedData,
      item_id
    };
  }
  return {
    ...sharedData,
    points_per_customer: Number(points_per_customer)
  };
};
