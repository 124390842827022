import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import * as React from "react";
import { Field } from "redux-form";
import { required, minValue } from "../../../redux-store/validators";
import { RenderGenericField } from "../../FormElements/RenderGenericField";
import { useEffect } from "react";
import { SettingsSwitch } from "../SettingsSwitch";
import { RenderDatePicker } from "../../FormElements/RenderDatePicker";
import { normalizeDate } from "../../../redux-store/transformers";
import { CampaignLanguages } from "../OnlineToOfflineSpecialCampaignForm";
import TabNavigation from "../GiftInboxCommon/TabNavigation";
import TabItem from "../GiftInboxCommon/TabNavigation/components/TabItem";

interface IProps {
  change: (fieldName: "incentive_number", value: "infinity") => void;
  unlimitedIncentives: boolean;
  setUnlimitedIncentives: (value: boolean) => void;
  newCustomersOnly: boolean;
  setNewCustomersOnly: (value: boolean) => void;
  currentCustomersCount?: number;
  isSpecialEvents?: boolean;
  campaignLanguage?: CampaignLanguages;
  setCampaignLanguage?: (value: number | undefined) => void;
}

const OnlineToOfflineSettings: React.FunctionComponent<IProps> = ({
  change,
  unlimitedIncentives,
  setUnlimitedIncentives,
  setNewCustomersOnly,
  newCustomersOnly,
  currentCustomersCount = 1,
  isSpecialEvents,
  campaignLanguage,
  setCampaignLanguage
}) => {
  const minIsCurrentCustomerCount = React.useMemo(
    () => minValue(currentCustomersCount),
    [currentCustomersCount]
  );
  useEffect(() => {
    if (unlimitedIncentives) {
      change("incentive_number", "infinity");
    }
  }, [unlimitedIncentives]);
  return (
    <div className={styles.wrapper}>
      <p className={styles.header}>Setup campaign settings</p>
      <p className={styles.para}>
        Set the number of incentives & campaign's ending date.
      </p>
      <Field
        className={styles.width}
        name="incentive_number"
        type="number"
        disabled={unlimitedIncentives}
        placeholder="Maximum number of incentives"
        component={RenderGenericField}
        infinity={unlimitedIncentives}
        label="Maximum number of incentives"
        validate={[required, minIsCurrentCustomerCount]}
      />
      <p className={styles.info}>
        The campaign will expire automatically after reaching this number
      </p>
      <div className={styles.switch}>
        <div className={styles.textWrapper}>
          <p className={styles.switchH}>Unlimited incentives</p>
        </div>
        <SettingsSwitch
          checked={unlimitedIncentives}
          onChange={setUnlimitedIncentives}
        />
      </div>

      {isSpecialEvents && (
        <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Field
            className={styles.width}
            name="gift_expiration_date"
            component={RenderDatePicker}
            label="Gift expiration date"
            validate={[required]}
            normalize={normalizeDate}
          />

          <p className={styles.info}>
            After this date no one can redeem the gift he/she received
          </p>
        </div>
      )}

      <Field
        className={styles.width}
        name="ending_date"
        component={RenderDatePicker}
        label="Campaign Ending date"
        validate={[required]}
        normalize={normalizeDate}
      />
      <p className={styles.info}>
        The campaign will expire automatically after reaching this date
      </p>

      {isSpecialEvents && (
        <>
          <div className={styles.separator} />
          <div className={`${styles.switch} ${styles.dirCol}`}>
            <div className={styles.textWrapper}>
              <p className={styles.switchH}>
                Your Campaign Language :{" "}
                {campaignLanguage === CampaignLanguages.ar
                  ? "Arabic"
                  : "English"}
              </p>
              <p className={styles.switchD}>
                Choose the language that fits your customer segment best
              </p>
            </div>
            <TabNavigation
              initialTabIndex={
                campaignLanguage === CampaignLanguages.ar ? 1 : 0
              }
              onChangeTab={setCampaignLanguage ? setCampaignLanguage : () => {}}
            >
              <TabItem
                title={
                  <div className={styles.tab}>
                    <p className={styles.text}>{"English"}</p>
                  </div>
                }
              >
                <div />
              </TabItem>
              <TabItem
                title={
                  <div className={styles.tab}>
                    <p className={styles.text}>{"Arabic"}</p>
                  </div>
                }
              >
                <div />
              </TabItem>
            </TabNavigation>
          </div>
        </>
      )}

      <div className={styles.separator} />
      <div className={styles.switch}>
        <div className={styles.textWrapper}>
          <p className={styles.switchH}>Target new customers only</p>
          <p className={styles.switchD}>
            Only the customers who never visited your brand will <br /> receive
            the incentives
          </p>
        </div>
        <SettingsSwitch
          checked={newCustomersOnly}
          onChange={setNewCustomersOnly}
        />
      </div>
    </div>
  );
};

export default hot(module)(OnlineToOfflineSettings);
