import { MODES } from "..";
import React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
export interface IMiniAppProps {
  backgroundColor: string;
  textColor: string;
  mode: MODES;
}

const modesClasses = {
  [MODES.EXAMPLE]: styles.example,
  [MODES.MOCK_UP]: styles.mockUp,
  [MODES.LIVE_APP]: styles.liveApp,
};

const MiniApp: React.FC<IMiniAppProps> = ({
  children,
  mode,
  backgroundColor,
  textColor,
}) => {
  return (
    <div
      className={`${styles.wrapper} ${modesClasses[mode]}`}
      style={{ backgroundColor, color: textColor }}
    >
      {children}
    </div>
  );
};

export default hot(module)(MiniApp);
