import * as React from "react";
import * as styles from './styles.module.sass';
import {WrappedFieldProps} from 'redux-form';

interface IProps {
  label: string;
  placeholder: string;
  className?: string;
  note?: string;
}


export class RenderTextAreaGenericField extends React.Component<WrappedFieldProps & IProps> {
  public render() {
    const {
      input,
      meta: {touched, error, warning},
      placeholder,
      label,
      note,
      className
    } = this.props;
    return (
      <div className={`${styles.textAreaWrapper} ${className || ''}`}>
        <label htmlFor={input.name} className={styles.label}>
          {label}
          {touched && ((error && <span className={styles.formError}>{error}</span>) || (warning &&
            <span>{warning}</span>))}
        </label>
        <textarea autoComplete='off' className={styles.textArea} id={input.name} {...input}
                  placeholder={placeholder}/>
        {note && <p className={styles.notice}>{note}</p>}
      </div>
    );
  }
}
