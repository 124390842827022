import {call, put, select, takeLatest} from "redux-saga/effects";
import {requestQuotasAction} from "../../constants";
import {selectToken} from "../selectors";
import {IRequestQuotasAction} from "../../types";
import {requestQuotasFailure, requestQuotasSuccess} from "../actions/request-quotas-actions";
import {requestQuotas} from "../../axios";

function* requestQuotasSaga(action:IRequestQuotasAction) {
    try {
        const token = yield select(selectToken);
        yield call(requestQuotas,action.payload, token);
        yield put(requestQuotasSuccess());
    } catch (e) {
        yield put(requestQuotasFailure(e));
    }
}

export function* watchRequestQuotasSaga() {
    yield takeLatest(requestQuotasAction.requested, requestQuotasSaga);
}
