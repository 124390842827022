import React from 'react';


import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = () => (
    <ul className={classes.NavigationItems}>
        <NavigationItem link="/" exact>
          <img src={require('../../../assets/nav-home.svg')}/>
            <p>Home</p>
        </NavigationItem>
        <NavigationItem link="/sms/history" exact>
          <img src={require('../../../assets/nav-message.svg')}/>
            <p>SMS Campaigns</p>
        </NavigationItem>
        <NavigationItem link="/online-to-offline/history" exact>
          <img src={require('../../../assets/nav-oto.svg')}/>
            <p>Online-To-Offline campaigns</p>
        </NavigationItem>
        <NavigationItem link="/buy" exact>
          <img src={require('../../../assets/nav-buy.svg')}/>
            <p>Buy points</p>
        </NavigationItem>
    </ul>
);

export default navigationItems;
