import {
    IGetQuotasAction,
    IGetQuotasActionFailure,
    IGetQuotasActionSuccess,
    IQuota,
} from "../../types";
import * as actions from '../../constants';


export const getQuotas = (): IGetQuotasAction => ({
    type: actions.getQuotasAction.requested,
});
export const getQuotasSuccess = (quotas: IQuota[], hasPurchasedQuota: boolean): IGetQuotasActionSuccess => ({
    type: actions.getQuotasAction.fulfilled,
    payload: {quotas, hasPurchasedQuota}
});
export const getQuotasFailure = (error): IGetQuotasActionFailure => ({
    type: actions.getQuotasAction.rejected,
    payload: error
});