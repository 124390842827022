import * as constants from '../../constants';
import {IAuthAction, IDeleteCampaignActionSuccess, ILoginSuccessAction, IStore} from "../../types";
import {Map} from "immutable";
import {mutateState} from "../../helpers/mutate-state";


const TOKEN = 'token';
const IS_LOGGED_IN = 'isLoggedIn';
const STORE_DATA = 'storeData';

export interface IAuthReducerState {
    [TOKEN]: string;
    [IS_LOGGED_IN]: boolean;
    [STORE_DATA]: IStore
}

const initialState: IAuthReducerState = Map({
    [TOKEN]: '',
    [IS_LOGGED_IN]: false,
    [STORE_DATA]: {},
}).toJS();

const authReducer = (state: IAuthReducerState = initialState,
                     action: IAuthAction|IDeleteCampaignActionSuccess): IAuthReducerState => {
    switch (action.type) {
        case constants.loginAction.fulfilled: {
            return mutateState(state, map => {
                map.set(TOKEN, (action as ILoginSuccessAction).payload.token);
                map.set(IS_LOGGED_IN, true);
                map.set(STORE_DATA, {
                    ...(action as ILoginSuccessAction).payload,
                    token: undefined,
                });
            });
        }
        case constants.deleteCampaignAction.fulfilled:{
            return mutateState(state, map => {
                const oldStoreData=map.get(STORE_DATA) as IStore;
                const newStoreData= {...oldStoreData,reach_points:oldStoreData.reach_points+
                        (action as IDeleteCampaignActionSuccess).payload.old_points};
                map.set(STORE_DATA, newStoreData);
            });
        }
        default:
            return state;
    }
};


export default authReducer;
