import * as constants from '../../constants';
import {mutateState} from "../../helpers/mutate-state";
import {Map} from "immutable";
import {
    IEditCampaignActionSuccess, ISetCampaignEDitDataAction
} from "../../types";

export interface IEditCampaignReducerState {
    isEdited: boolean,
    isEditing: boolean,
    isFailed:boolean,
    closeModal:boolean,
    editedCampaign: {},
}

const IS_EDITED = 'isEdited';
const IS_FAILED = 'isFailed';
const EDITED_CAMPAIGN = 'editedCampaign';
const CLOSE_MODAL = 'closeModal';
const IS_EDITING = 'isEditing';

const initialState: IEditCampaignReducerState = Map({
    [IS_EDITED]: false,
    [IS_FAILED]: false,
    [IS_EDITING]: false,
    [CLOSE_MODAL]:false,
    [EDITED_CAMPAIGN]: {}
}).toJS();

const editCampaignReducer = (state: IEditCampaignReducerState = initialState, action: IEditCampaignActionSuccess): IEditCampaignReducerState => {
    switch (action.type) {
        case constants.editCampaignAction.fulfilled: {
            return mutateState(state, map => {
                map.set(IS_EDITED, true);
                map.set(IS_EDITING, false);
                map.set(IS_FAILED, false);
                map.set(CLOSE_MODAL, true);
            });
        }
        case constants.editCampaignAction.requested: {
            return mutateState(state, map => {
                map.set(IS_EDITED, false);
                map.set(IS_EDITING, true);
                map.set(IS_FAILED, false);
                map.set(CLOSE_MODAL, false);
            });
        }
        case constants.editCampaignAction.rejected: {
            return mutateState(state, map => {
                map.set(IS_FAILED, true);
                map.set(IS_EDITING, false);
                map.set(IS_EDITED, false);
                map.set(CLOSE_MODAL, true);
            });
        }
        case constants.resetFailAction: {
            return mutateState(state, map => {
                map.set(IS_FAILED, false);
                map.set(IS_EDITING, false);
                map.set(IS_EDITED, false);
                map.set(CLOSE_MODAL, false);
            });
        }
        case constants.setCampaignEditDataAction: {
            return mutateState(state, map => {
                map.set(EDITED_CAMPAIGN, (action as ISetCampaignEDitDataAction).payload);
                map.set(IS_EDITED, false);
            });
        }
        default:
            return state;
    }
};


export default editCampaignReducer;
