import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {IQuota} from "../types";




export const getQuotas = (token:string): AxiosReturn<{response:IQuota[]}> => {
  return axios.get(Requests.getQuotas, {
    baseURL:process.env.LUMEN_ENDPOINT,
    headers:{
      token,
    }
  })
};
