import * as styles from './styles.module.sass'
import React, {useContext, useEffect, useState} from 'react';
import {OTOStatPanelTitle} from '../SocialMediaConversion';
import {numberFormatter} from '../../../../Utils/numberFormatter';
import {getOTOCampaignAddedUsersLocations} from "../../../axios/getOTOCampaignAddedUsersLocations";
import StoreData from "../../../contexts/StoreData";
import {useProgressState} from "../../../../Utils/custom-hooks/useProgressState";
import {LoadingPageInfo} from "../../LoadingPageInfo";

interface ICustomerLocation {
  address: string;
  number: number;
}

const CustomersLocationsDist: React.FC<{ campaignId: string }> = ({campaignId}) => {
  const {token} = useContext(StoreData);
  const {success, setFailure, setSuccess, setLoading, failure} = useProgressState();
  const [customersLocations, setCustomersLocations] = useState<ICustomerLocation[]>([]);
  useEffect(() => {
    setLoading();
    getOTOCampaignAddedUsersLocations(token, campaignId).then(({data}) => {
      setCustomersLocations(Object.keys(data).map(key => ({address: key, number: data[key]})));
      setSuccess();
    }).catch(setFailure);
  }, []);
  let render = <LoadingPageInfo/>;
  if (success) {
    render = (
      <>
        {
          customersLocations.length > 0 ? customersLocations.map((locationData) =>
            <div className={styles.row} key={locationData.address}>
              <div className={styles.name}>{locationData.address}</div>
              <div className={styles.value}>{numberFormatter(locationData.number, 0)}</div>
            </div>
          ) : <div className={styles.noStats}>No statistics available yet!</div>
        }
      </>
    )
  }
  if (failure) {
    render = (
      <p className={styles.rejectedText}>Something went wrong!</p>
    )
  }
  return (
    <div className={styles.wrapper}>
      <OTOStatPanelTitle
        title={'Customers location'}
        subTitle={'Where does your customers come from'}/>
      <div className={styles.citiesTable}>
        <div className={styles.header}>
          <div className={styles.name}>location</div>
          <div className={styles.value}>users</div>
        </div>
        {render}
      </div>
    </div>
  )
};

export default CustomersLocationsDist;
