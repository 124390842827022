import {Moment} from "moment-timezone";
import {CAIRO_TIMEZONE, FULL_DATE_FORMAT, FULL_TIME_FORMAT} from "../src/constants";
import * as moment from "moment-timezone";
import imageCompression from 'browser-image-compression';
import { CURRENCIES } from "./currencies";
import memoize from "lodash/memoize";


export const getFullDate = (m: Moment): string => m.format(FULL_DATE_FORMAT);
export const getFullTime = (m: Moment): string => m.format(FULL_TIME_FORMAT);
export const getMomentFromDateAndTime = (date: string, time: string, timezone?: string): Moment => moment.tz(`${date} ${time}`, `${FULL_DATE_FORMAT} ${FULL_TIME_FORMAT}`, timezone as any);
export const getTimeZonedTime = (m: Moment, timezone: string): Moment => moment.tz(m, `${timezone}`);
export const convertToCairoTimeZone = (timeZonedMoment: Moment): Moment => timeZonedMoment.tz(CAIRO_TIMEZONE);
export const getMomentTimeStamp = (m: Moment): number => +(m.format('x'));
export const combineDateAndTimeMoments = (dateMoment: Moment, timeMoment: Moment, timezone?: string) => {
    const date = getFullDate(dateMoment);
    const time = getFullTime(timeMoment);
    return getMomentFromDateAndTime(date, time, timezone);
};
export const getServerTime = (dateMoment: Moment, timeMoment: Moment, timezone: string): Moment => {
    const combinedDateTime = combineDateAndTimeMoments(dateMoment, timeMoment, timezone);
    return convertToCairoTimeZone(combinedDateTime);
};
export const getNewCampaignData = (campaign_name: string, launch_timestamp: number, launch_timezone: string) => ({ campaign_name, launch_timestamp, launch_timezone });

export const diffFromNow = (dateMoment: Moment, timeMoment: Moment, timezone: string): number => {
  const timeZonedTime = combineDateAndTimeMoments(dateMoment, timeMoment, timezone);
  return timeZonedTime.diff(moment.tz(timezone), 'minute');
};

export const convertDataURLToFile = (url: string): Promise<File[]> => {
  const isDataUrl = url.slice(0, 4) === 'data';
  const urlTrail = isDataUrl ? '' : '?_=' + new Date().getTime();
  return fetch(url + urlTrail)
    .then(res => res.blob())
    .then(blob => {
      return [new File([blob], "File name", {type: 'image'})];
    });
};

export const readAsBase64 = (file: File): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = (ev: any) => resolve(ev.target.result);
    reader.readAsDataURL(file);
  })
};


export const compressBase64Images = (imageFile: Blob | File): Promise<string> => {
  return new Promise(resolve => {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    imageCompression(imageFile, options).then(
      cp => {
        readAsBase64(cp).then(
          cpBase64 => resolve(cpBase64)
        );
      }
    );
  });
};


export const getLocalizedCurrency = memoize((iso_code: string) => {
  const langCurrencies = CURRENCIES.en;
  return langCurrencies[iso_code] || CURRENCIES.en[iso_code];
});

export const imagesURL = process.env.IMAGE_ENDPOINT as string;

export const getOptimisticImageURL = (item) => {
  if (item.image === undefined) {
      return require('../src/components/OnlineToOfflineCampaigns/GiftInboxCommon/assets/placeholder.svg');
  }
  const hasDataURL = (item.image as string).startsWith('data:');
  const hasBlobURL = item.image.startsWith('blob:');
  return (hasDataURL || hasBlobURL) ? item.image : imagesURL + item.image;
};
