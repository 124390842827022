import * as React from "react";
import * as styles from './styles.module.sass';
import {WrappedFieldProps} from 'redux-form';

interface IProps {
  placeholder: string;
  label: string;
  className?: string;
  currency?: string;
  convertingRatio?: string;
  disabled?: boolean;
}

const convertPointsToPrice = (points: number, convertingRatio) => {
  return Math.floor(points / 4 / convertingRatio);
};

export class RenderPointsToMoneyField extends React.Component<WrappedFieldProps & IProps> {

  public render() {
    const {
      input,
      convertingRatio,
      currency,
      placeholder,
      meta: {touched, error, warning},
      disabled,
      className,
      label
    } = this.props;
    const convertedPoints = convertPointsToPrice(input.value, convertingRatio);
    return (
      <div className={`${styles.inputWrapper} ${className || ''}`}>
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
          {touched && ((error && <span className={styles.formError}>{error}</span>) || (warning &&
            <span>{warning}</span>))}
        </label>
        <div className={styles.pointsWrapper}>
          <input autoComplete='off' placeholder={placeholder} disabled={disabled}
                 className={styles.input} id={input.name} {...input}
                 type='number'/>
          <p className={styles.pointsToMoney}>&nbsp;=&nbsp;{convertedPoints}&nbsp;{currency}/customer</p>
        </div>
      </div>
    );
  }
}
