import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';

export const getTotalUsersCount = (token: string): AxiosReturn<{total_users: number}> => {
    return axios.post(Requests.totalUsersCount, {}, {
        headers: {
            token,
        }
    })
};
