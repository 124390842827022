import * as React from "react";
import {Route} from "react-router";
import CampaignCreation from "../SMSCampaigns/CampaignCreation";
import CampaignsHistory from "../SMSCampaigns/CampaignsHistory";
import CampaignEditing from "../SMSCampaigns/CampaignEditing";


class SMSCampaignsAuthenticatedRoutes extends React.Component {
  public render() {
    return (
      <>
        <Route exact={true} path="/sms/new" component={CampaignCreation}/>
        <Route exact={true} path="/sms/history" component={CampaignsHistory}/>
        <Route exact={true} path="/sms/edit" component={CampaignEditing}/>
      </>
    );
  }
}


export default SMSCampaignsAuthenticatedRoutes;
