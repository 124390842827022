import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {IQuota} from "../types";




export const requestQuotas = (quota_id:number,token:string): AxiosReturn<{response:IQuota}> => {
  return axios.post(Requests.requestQuotas, {
      quota_id
  },{
    baseURL:process.env.LUMEN_ENDPOINT,
    headers:{
      token,
    }
  })
};
