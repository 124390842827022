import {Modal, ModalBody} from "reactstrap";
import * as React from "react";
import * as styles from "./styles.module.sass";
import {RouteComponentProps, withRouter} from "react-router";


interface IProps {
    modal: boolean;
    paragraph: string;
}

class FailedModal extends React.Component<IProps & RouteComponentProps> {
    public handleSubmit = (e) => {
        e.preventDefault();
        this.props.history.push('/buy');
    };

    public render() {
        return (
            <Modal isOpen={this.props.modal} className={styles.content}>
                <ModalBody className={styles.body}>
                    <div className={styles.wrapper}>
                        <div className={styles.title}>
                            Not enough points!
                        </div>
                        <div className={styles.paragraph}>
                            {this.props.paragraph}
                        </div>
                        <img className={styles.logo} src={require('../../../assets/no-points.svg')}/>
                        <button onClick={this.handleSubmit} className={styles.confirmBtn}>Buy more points</button>
                    </div>
                </ModalBody>
            </Modal>

        );
    }
}


export default withRouter(FailedModal);
