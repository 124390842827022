import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {ICampaignLog} from "../types";




export const editCampaign = (data:ICampaignLog,token:string): AxiosReturn<{}> => {
  return axios.put(Requests.editCampaign(data.id), data,{
      baseURL:process.env.LUMEN_ENDPOINT,
    headers:{
      token,
    }
  })
};
