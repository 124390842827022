import {
    IGetCampaignsLogAction,
    IGetCampaignsLogActionFailure,
    IGetCampaignsLogActionSuccess,
    IGetCampaignAction,
    IGetCampaignActionFailure,
    IGetCampaignActionSuccess
} from "../../types";
import {
    getCampaignAction,
    getCampaignsLogAction
} from "../../constants";

export const getCampaignsLog = (): IGetCampaignsLogAction => ({
    type: getCampaignsLogAction.requested,
});
export const getCampaignsLogSuccess = (data): IGetCampaignsLogActionSuccess => ({
    type: getCampaignsLogAction.fulfilled,
    payload: data
});
export const getCampaignsLogFailure = (error): IGetCampaignsLogActionFailure => ({
    type: getCampaignsLogAction.rejected,
    payload: error
});


export const getCampaign = (id: string): IGetCampaignAction => ({
    type: getCampaignAction.requested,
    payload: id
});
export const getCampaignSuccess = (data): IGetCampaignActionSuccess => ({
    type: getCampaignAction.fulfilled,
    payload: data
});
export const getCampaignFailure = (error): IGetCampaignActionFailure => ({
    type: getCampaignAction.rejected,
    payload: error
});

