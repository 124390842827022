import React from "react";
import * as styles from './styles.module.sass'

interface IProgressBarProps {
    percentage: number;
    completionText?: string;
    wrapperClassName?: string;
    progressClassName?: string;
}

const ProgressBar: React.FC<IProgressBarProps> = props => {
    const { percentage, completionText, progressClassName, wrapperClassName} = props;

    return (
        <div className={`${styles.progressBarWrapper} ${wrapperClassName || ''}`}>
            <div className={`${styles.progress} ${progressClassName || ''}`} style={{ width: `${percentage * 100}%` }}>
                {percentage === 1 ? completionText : ''}
            </div>
        </div>
    );
};

export default ProgressBar;