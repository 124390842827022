import React from "react";
import * as styles from "./styles.module.sass";
import { MODES } from "../..";
import { useKindaTranslate } from "../PhoneEntry";

export interface IThankYouProps {
  store: {
    image: string;
  };
  mode: MODES;
  campaignPoints?: number;
  backgroundColor: string;
  OS: "IOS" | "ANDROID" | null;
  formatText?: (str: string, { count: number }?) => string;
  isSelected?: boolean;
  onSendEmail?: (email: string) => Promise<any>;
  isPointsIncentive?: boolean;
  campaign_gift_name?: string;
  download_title: string;
  campaignLanguage?: string;
  secondPageHeading?: string;
  campaign_logo?: string;
}

const modesClasses = {
  [MODES.EXAMPLE]: styles.example,
  [MODES.MOCK_UP]: styles.mockUp,
  [MODES.LIVE_APP]: styles.liveApp,
};

export const ThankYouLayout: React.FC<IThankYouProps> = ({
  mode,
  store: { image },
  children,
  formatText,
  campaignPoints = 0,
  isPointsIncentive = true,
  campaign_gift_name = "",
  campaignLanguage,
  secondPageHeading,
  campaign_logo,
}) => {
  const t = useKindaTranslate(formatText);
  return (
    <>
      <div className={`${styles.mainContent} ${modesClasses[mode]}`}>
        <div className={`${styles.lowerPanel} ${modesClasses[mode]}`}>
          <div className={styles.wrapper}>
            <img
              src={campaign_logo ? campaign_logo : image}
              className={styles.storeLogo}
            />
            <div>
              <h2 className={styles.campaignTitle}>{secondPageHeading}</h2>
              <p className={styles.campaignDescription}>
                {campaignLanguage === "ar"
                  ? `ارسلنا لها رسالة بإسمك عشان تستلم هديتها من أقرب فرع
                  `
                  : `Hurray! Your gift has been sent!
                  Don’t forget to remind her to get her special gift from the nearest Monginis branch.
                  `}
              </p>
              <br />
              <p className={styles.campaignDescription}>
                {campaignLanguage === "ar" ? "" : ``}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ThankYouSpecialOTO: React.FC<IThankYouProps> = (props) => (
  <ThankYouLayout {...props} />
);

export default ThankYouSpecialOTO;
