import memoize from 'lodash/memoize';
import {CURRENCIES} from "./currencies";
import {useContext, useMemo} from "react";
import StoreData from "../src/contexts/StoreData";


export const getLocalizedCurrency = memoize((iso_code: string) => {
  const lang = 'en';
  const langCurrencies = CURRENCIES[lang.slice(0, 2)];
  return (langCurrencies && langCurrencies[iso_code]) || CURRENCIES.en[iso_code];
});


export const useGetCurrency = () => {
  const {storeData} = useContext(StoreData);
  const countryIsoCode = (storeData && storeData.country_iso_code || 'eg');
  return useMemo(() => {
    return getLocalizedCurrency(countryIsoCode);
  }, ['en', storeData]);
};
