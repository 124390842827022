import * as React from "react";
import * as styles from './styles.module.sass';
import { hot } from "react-hot-loader";
import TabItem from "../TabNavigation/components/TabItem";
import TabNavigation from "../TabNavigation";
import AddCustomGiftForm from "../AddSpecialItemForm";
import {ActionTypes, change, clearFields, WrappedFieldProps} from 'redux-form';
import AddFromMenu from "../AddFromMenu";
import { Dispatch, Action } from "redux";
import { LoyaltyItem } from "../../../../models/LoyaltyItem";
import { connect } from "react-redux";
const Strings = {
  sendMenuItemTabTitle: 'Menu item',
  sendCustomGiftCompensationTitle: 'Send custom gift',
};

export interface IVoucherData {
  name: string;
  image: string;
}

interface IProps {
  setMenuItem: (item: LoyaltyItem | undefined) => void;
  clearAllFields: () => void;
  disabled?: boolean;
}


const GiftItemsHandler: React.FC<WrappedFieldProps & IProps> = ({setMenuItem, clearAllFields, disabled, ...props}) => {
  const handleChangeTabs = React.useCallback((nextTab: number) => {
    clearAllFields();
    props.input.onChange(nextTab);
  }, [clearAllFields])
  return (
    <>
      <div className={`${styles.wrapper} ${styles.left}`}>
        <TabNavigation disabled={disabled} initialTabIndex={props.input.value} onChangeTab={handleChangeTabs}>
          <TabItem title={
            <div className={styles.tab}>
              <p className={styles.text}>{Strings.sendMenuItemTabTitle}</p>
            </div>
          }>
            <AddFromMenu/>
          </TabItem>
          <TabItem title={(
            <div className={styles.tab}>
              <p className={styles.text}>{Strings.sendCustomGiftCompensationTitle}</p>
            </div>
          )}>
            <AddCustomGiftForm />
          </TabItem>
        </TabNavigation>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action<ActionTypes>>) => ({
  setMenuItem: (item: LoyaltyItem | undefined) => {
    if (item) {
      dispatch(change('onlineToOfflineForm', 'item_id', item));
    } else {
      dispatch(clearFields('onlineToOfflineForm', false, false, 'item_id'))
    }
  },
  clearAllFields: () => {
    dispatch(clearFields('onlineToOfflineForm', false, false, 'item_id', 'voucher'))
  },
});

export default hot(module)(connect(null, mapDispatchToProps)(GiftItemsHandler));
