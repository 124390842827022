import * as styles from './styles.module.sass'
import React, { useMemo } from "react";
import { numberFormatter } from '../../../../../Utils/numberFormatter';
import ProgressBar from '../../../oto-campaign-mini-app/ProgressBar';
import { IOTOCampaign } from "../index";
import { RouteComponentProps, withRouter } from "react-router";
import { noop } from "redux-saga/utils";
import { Tooltip } from 'reactstrap';
import { useToggleState } from '../../../../../Utils/custom-hooks/useToggleState';

export interface IAcquisitionCampaignCardProps extends RouteComponentProps {
  campaignData: IOTOCampaign;
  hasEdit?: boolean;
  style?: React.CSSProperties;
}


export const useIsCampaignExpired = (days_left: number, campaignCustomersNumber: number, max_users: string, isInfinite: boolean) => {
  return useMemo(() => {
    const isPastExpirationDate = days_left <= 0;
    const hasReachedTarget = !isInfinite && campaignCustomersNumber >= +max_users;
    return isPastExpirationDate || hasReachedTarget;
  }, [days_left, campaignCustomersNumber, max_users, isInfinite,]);
}

export const isCampaignIfinite = (max_users: string) => max_users === 'infinity';

const useTruncate = (str: string, max: number) => {
  const stringIsLarge = str && str.length > max;
  return useMemo(() => stringIsLarge ? `${str.substr(0, max)}...`  : str, [str]);
}


const OTOCard: React.FC<IAcquisitionCampaignCardProps> = props => {
  const { name, days_left, max_users, id, customers_count } = props.campaignData;
  const campaignCustomersNumber = customers_count || 0;
  const isInfinite = useMemo(() => isCampaignIfinite(max_users), [max_users]);
  const usersPercentage = useMemo(() => Number(!isInfinite) && campaignCustomersNumber / +max_users, [isInfinite, max_users, campaignCustomersNumber]);
  const campaignExpired = useIsCampaignExpired(days_left, campaignCustomersNumber, max_users, isInfinite)
  const goToEdit = () => props.history.push('/online-to-offline/edit?id=' + id);
  const goToView = () => props.history.push('/online-to-offline/view?id=' + id);
  const [tooltip, toggleTooltip] = useToggleState();
  return (
    <div
      onClick={props.hasEdit ? noop : goToView}
      style={{ ...props.style, cursor: props.hasEdit ? 'default' : 'pointer' }}
      className={styles.OTOCardwrapper}>
      <div className={styles.upperContent}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div id={`oto-${id}`} className={styles.campaignName}>
            {useTruncate(name, 60)}
          </div>
          <Tooltip isOpen={tooltip} toggle={toggleTooltip} trigger='hover' style={{ fontSize: '1.8rem' }} placement="top" target={`#oto-${id}`}>
            {name}
          </Tooltip>
          {
            props.hasEdit && !campaignExpired &&
            <div onClick={goToEdit} className={styles.edit}>
              <img src={require('../../../../assets/oto-pen.svg')} />
            </div>
          }
        </div>
        <div className={styles.campaignStatsWrapper}>
          <div className={styles.timeStatus}>
            <img src={require('../../../../assets/time.svg')} className={styles.time} />
            <div className={styles.daysLeft}>
              <div className={styles.daysNumber}>{days_left > 0 ? Math.round(days_left) : 0}</div>
              <div className={styles.subTitle}>Days left</div>
            </div>
          </div>
          <div className={styles.campaignStatus}>
            <div className={`${styles.statusBadge} ${campaignExpired ? styles.expired : styles.running}`}>
              {campaignExpired ? 'Finished' : 'Running'}
            </div>
            <div className={styles.subTitle}>
              Campaign Status
            </div>
          </div>
        </div>
      </div>
      <div className={styles.lowerPart}>
        <div className={styles.campaignCustomersStats}>
          <div className={styles.campaignCustomersNumber}>
            <div className={styles.number}>{numberFormatter(campaignCustomersNumber, 0)}</div>
            <div className={styles.subTitle}>Phone numbers added</div>
          </div>
          <div className={styles.maxCampaignCustomers}>
            <div className={styles.number}>
              {isInfinite ? '∞' : numberFormatter(+max_users, 0)}
            </div>
            <div className={styles.subTitle}>Goal</div>
          </div>
        </div>
        {!isInfinite && <ProgressBar percentage={usersPercentage} completionText='Target achieved!' />}
      </div>
    </div>
  );
};

export default withRouter(OTOCard);
