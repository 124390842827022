import React from 'react';
import * as styles from './styles.module.sass';
import {RouteComponentProps, withRouter} from "react-router";

interface IProps extends RouteComponentProps {
  message: string;
  url: string;
}

const NoCampaignCreated: React.FunctionComponent<IProps> = ({url, history: {push}, message}) => {
  const goTo = () => push(url);
  return (
    <div className={styles.wrapper}>
      <img src={require('../../assets/not-created.svg')} className={styles.img}/>
      <h1 className={styles.message}>{message}</h1>
      <button className={styles.btn} onClick={goTo}>
        <span>+</span>Create
      </button>
    </div>
  );
};

export default withRouter(NoCampaignCreated);
