import {Modal, ModalBody} from "reactstrap";
import * as React from "react";
import * as styles from "./styles.module.sass";
import {RouteComponentProps, withRouter} from "react-router";


interface IProps {
    modal: boolean;
    paragraph: string;
}

class SuccessModal extends React.Component<IProps & RouteComponentProps> {
    public handleSubmit = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    };

    public render() {
        const {modal, paragraph} = this.props;
        return (
            <Modal isOpen={modal} className={styles.content}>
                <ModalBody className={styles.body}>
                    <div className={styles.wrapper}>
                        <div className={styles.title}>
                            Succeed
                        </div>
                        <div className={styles.paragraph}>
                            {paragraph}
                        </div>
                        <img className={styles.logo} src={require('../../../assets/succeed.svg')}/>
                        <button onClick={this.handleSubmit} className={styles.confirmBtn}><span
                            className={styles.arrow}>&lt;</span>Return to home
                        </button>
                    </div>
                </ModalBody>
            </Modal>

        );
    }
}


export default withRouter(SuccessModal);
