import React from 'react';
import { motion, MotionProps, HTMLMotionProps } from 'framer-motion';
import ClipLoader from 'react-spinners/ClipLoader';

export interface IExtraTouchableGestureProps extends HTMLMotionProps<'button'>, MotionProps {
    loading?: boolean
}


const TouchableGesture: React.FC<IExtraTouchableGestureProps> = ({ children, loading, ...props }) => {
    const disabled = loading || props.disabled;
    return (
        <motion.button
            {...props}
            disabled={disabled}
            whileTap={{
                scale: 0.9,
                opacity: 0.8
            }}>
            {loading ? <ClipLoader color={'#fff'} size={12} /> : children}
        </motion.button>
    )
}

export default TouchableGesture;
