import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {ICampaignLog} from "../types";


export const campaignsLog = (token: string): AxiosReturn<{ response: ICampaignLog[] }> => {
    return axios.get(Requests.campaignsLog, {
        baseURL: process.env.LUMEN_ENDPOINT,
        headers: {
            token,
        }
    })
};
