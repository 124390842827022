import * as React from "react";
import * as styles from './styles.module.sass';
import StepProgressBar from "../StepProgressBar";
import NameCreation from "./NameCreation";
import {IRootReducerState} from "../../../redux-store/rootReducer";
import {connect} from "react-redux";
import ConfirmationModal from "../ConfirmationModal";
import SuccessModal from "../SuccessModal";
import FailedModal from "../FailedModal";
import {Dispatch} from "redux";
import {createCampaign, resetFail, setCampaignName} from "../../../redux-store/actions/create-campaign-actions";
import {filterReset} from "../../../redux-store/actions/filter-campaign-actions";
import {Title} from "react-head";
import {RouteComponentProps, withRouter} from "react-router";
import FilterCampaignForm from '../FilterCampaignForm';

interface IProps {
    isCreated: boolean;
    isCreating: boolean;
    closeModal: boolean;
    isFailed: boolean;
    createCampaign: () => void;
    resetFail: () => void;
    filterReset: () => void;
    setCampaignName: (name: string) => { void };
}

interface IState {
    nameCreated: boolean;
    percent: number;
    confirmationModal: boolean;
}

class CampaignCreation extends React.Component<IProps & RouteComponentProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            nameCreated: false,
            percent: 0,
            confirmationModal: false,
        }
    }

    public toggleConfirmationModal = () => this.setState(prevState => ({
        confirmationModal:
            !prevState.confirmationModal
    }));

    public componentWillUnmount() {
        this.props.resetFail();
        this.props.filterReset();
    }

    public handleNextStep = () => {
        this.setState({nameCreated: true, percent: 100});
    };
    public createCampaign = () => {
        this.props.createCampaign();
    };

    public render() {
        const {isFailed, isCreated, isCreating, closeModal} = this.props;
        const {nameCreated, confirmationModal, percent} = this.state;
        return (
            <div className={styles.wrapper}>
                <Title>New campaign</Title>
                <div className={styles.progressBarWrapper}>
                    {!isCreated && <StepProgressBar count={2} percent={percent}/>}
                </div>
                {nameCreated ?
                    <FilterCampaignForm onSubmit={this.toggleConfirmationModal}/>
                    :
                    <NameCreation setCampaignName={this.props.setCampaignName}
                                  handleNextStep={this.handleNextStep}/>
                }
                {!closeModal && <ConfirmationModal paragraph='Please confirm your campaign'
                                                   disabled={isCreating} action={this.createCampaign}
                                                   title='Create campaign' modal={confirmationModal}
                                                   toggle={this.toggleConfirmationModal}/>}
                {isCreated && <SuccessModal {...{}} paragraph='Your campaign is being reviewed'
                                            modal={true}/>}
                {isFailed && <FailedModal {...{}} paragraph='Please recharge your points to create the campaign'
                                          modal={true}/>}
            </div>
        );
    }
}

const mapStateToProps = (state: IRootReducerState) => ({
    isCreated: state.createCampaignReducer.isCreated,
    isFailed: state.createCampaignReducer.isFailed,
    isCreating: state.createCampaignReducer.isCreating,
    closeModal: state.createCampaignReducer.closeModal
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    createCampaign: () => dispatch(createCampaign()),
    resetFail: () => dispatch(resetFail()),
    filterReset: () => dispatch(filterReset()),
    setCampaignName: (name) => dispatch(setCampaignName(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CampaignCreation));
