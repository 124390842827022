export const createCampaign = "/api/reach-campaign";
export const editCampaign = (id: string) => `/api/reach-campaign/${id}`;
export const filterCampaign = "/api/filter-reach-campaigns";
export const campaignsLog = "/api/reach-campaign";
export const storeProfile = "/api/manager/store";
export const totalUsersCount = "/api/manager/get-branch-total-users-count";
export const getCampaign = (id: string) => `/api/reach-campaign/${id}`;
export const deleteCampaign = (id: string) => `/api/reach-campaign/${id}`;
export const getQuotas = "/api/quotas";
export const requestQuotas = `/api/purchase-quotas`;
export const purchasedQuotas = `/api/purchased-quotas`;
export const loyalityItems = `/api/manager/brand-gifts`;
export const branches = "api/manager/branch";
export const OTOCampaign = "/api/online-to-offline-campaign";
export const OTOSpecialEventsCampaign = "/api/special-events-oto";

export const campaignCustomer = "/api/campaign-customer";
export const specialOTOCampaignCustomer =
  "/api/special-events-oto/campaign-customer";

export const campaignDataById = (id: string) =>
  `/api/campaign-customer/creation-data/${id}`;

//#region for OTO campaigns
export const OTOCampaignPoints = `${OTOCampaign}/points`;
export const OTOCampaignPointsById = (id: string) =>
  `${OTOCampaign}/points/${id}`;
export const OTOCampaignMenuItem = `${OTOCampaign}/menu-item`;
export const OTOCampaignMenuItemById = (id: string) =>
  `${OTOCampaign}/menu-item/${id}`;
export const OTOCampaignVoucher = `${OTOCampaign}/voucher`;
//#endregion

//#region for OTO special events campaigns
export const OTOSpecialEventsCampaignPoints = `${OTOSpecialEventsCampaign}/points`;
export const OTOSpecialEventsCampaignPointsById = (id: string) =>
  `${OTOSpecialEventsCampaign}/points/${id}`;
export const OTOSpecialEventsCampaignMenuItem = `${OTOSpecialEventsCampaign}/menu-item`;
export const OTOSpecialEventsCampaignMenuItemById = (id: string) =>
  `${OTOSpecialEventsCampaign}/menu-item/${id}`;
export const OTOSpecialEventsCampaignVoucher = `${OTOSpecialEventsCampaign}/voucher`;
//#endregion

export const OTOCampaignVoucherById = (id: string) =>
  `${OTOCampaign}/voucher/${id}`;
export const OTOCampaignByID = (id: string) =>
  `/api/online-to-offline-campaign/${id}`;
export const OTOCampaignClicksNumberById = (id: string) =>
  `/api/online-to-offline-campaign/${id}/user-total-clicks`;
export const OTOCampaignClicksNumberChartById = (id: string) =>
  `/api/online-to-offline-campaign/${id}/user-clicks-per-day`;
export const OTOCampaignPhoneNumbersAddedNumberById = (id: string) =>
  `/api/online-to-offline-campaign/${id}/added-users-count`;
export const OTOCampaignPhoneNumbersAddedNumberChartById = (id: string) =>
  `/api/online-to-offline-campaign/${id}/added-users-per-day`;
export const OTOCampaignOfflineVisitorsNumberById = (id: string) =>
  `/api/online-to-offline-campaign/${id}/visitors-count`;
export const OTOCampaignOfflineVisitorsNumberChartById = (id: string) =>
  `/api/online-to-offline-campaign/${id}/visitors-per-day`;
export const OTOCampaignAddedUsersLocations = (id: string) =>
  `/api/online-to-offline-campaign/${id}/added-users-locations`;
export const OTOCampaignSocialMediaConversion = (id: string) =>
  `/api/online-to-offline-campaign/${id}/added-users-social-channel`;
export const storeItems = "/api/manager/items";
export const specialItems = "api/manager/special-items";
