import * as React from "react";
import Tabs from "@material-ui/core/Tabs/Tabs";
import * as styles from "./styles.module.sass";
import Tab from "@material-ui/core/Tab/Tab";
import { WrappedFieldProps, clearFields } from "redux-form";
import { connect } from "react-redux";

const VouchersTabContent = (
  <div className={styles.incentiveWrapper}>
    <div className={styles.dotWrapper}>
      <div className={styles.dot} />
    </div>
    <img src={require('../../../assets/voucher.svg')} />
    <div className={styles.textWrapper}>
      <p className={styles.title}>
        Vouchers
      </p>
      <p className={styles.subTitle}>
        Send a voucher or gift to <br />your customers
      </p>
    </div>
  </div>
)

interface IProps {
  onChangeTabs?: (nextTabIndex: number) => void;
  disabled?: boolean;
}

const OnlineToOfflineIncentive: React.FunctionComponent<WrappedFieldProps & IProps> = (
  {
    meta: { touched, error },
    input,
    children,
    onChangeTabs,
    disabled
  }) => {

  const handleChange = (e, nextTabIndex) => {
    if (input.value !== nextTabIndex) {
      input.onChange(nextTabIndex);
      if (onChangeTabs) {
        onChangeTabs(nextTabIndex);
      }
    }
  };
  return (
    <div className={styles.wrapper}>
      {touched && (error && <span className={styles.formError}>{error}</span>)}
      <div className={styles.root}>
        <Tabs
          disabled={disabled}
          value={input.value}
          onChange={handleChange}
          classes={
            {
              indicator: styles.indicator,
              root: styles.fullWidth
            }
          }>
          <Tab disabled={disabled} className={styles.tab} classes={{
            selected: styles.selectedTab,
            disabled: styles.disabled,
          }}
            label={
              <div className={styles.incentiveWrapper}>
                <div className={styles.dotWrapper}>
                  <div className={styles.dot} />
                </div>
                <img src={require('../../../assets/logo-no-text.svg')} />
                <div className={styles.textWrapper}>
                  <p className={styles.title}>Koinz points</p>
                  <p className={styles.subTitle}>
                    Send a fixed amount of points to your customers
                  </p>
                </div>
              </div>
            } />
          <Tab disabled={disabled} className={styles.tab} classes={{
            selected: styles.selectedTab,
            disabled: styles.disabled,
          }}
            label={VouchersTabContent} />
        </Tabs>
      </div>
      {
        React.Children.map(children, (child, index) => {
          if (index === input.value) {
            return child
          }
          return null;
        })
      }
    </div>
  )
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeTabs: () => {
      dispatch(clearFields('onlineToOfflineForm', false, false,
        'item_id',
        'voucher_image',
        'voucher_name',
        'points_per_customer',
        'gift_tab_index',
      ));
    }
  }
}

export default connect(null, mapDispatchToProps)(OnlineToOfflineIncentive);
