import * as React from "react";
import * as styles from './styles.module.sass';

interface IProps {
    openConfirmationModal?: () => void;
    createCampaign: () => void;
    isFiltered: boolean;
    isFiltering: boolean;
    totalCost: number;
    totalUsers: number;
    btnName: string;
    disabled?: boolean;
    isEdited?: boolean
}

export class Footer extends React.Component<IProps> {
    public handleClick = () => {
        (this.props.openConfirmationModal as any)();
    };
    public handleFilterClick = () => {
        this.props.createCampaign();
    };

    public render() {
        const {btnName, isFiltered, isFiltering, totalCost, totalUsers, disabled} = this.props;
        let show = (
            <div className={styles.footerWrapper}>

                <div className={styles.navBar}/>
                <footer className={styles.footer}>
                    <div/>
                    <button onClick={this.handleFilterClick}
                            type='button'
                            disabled={isFiltering || disabled}
                            className={!isFiltering && !disabled ? styles.btn : styles.btnDisabled}>Calculate
                    </button>
                </footer>
            </div>);
        if (isFiltered) {
            show = (
                <div className={styles.footerWrapper}>

                    <div className={styles.navBar}/>
                    <footer className={styles.footer}>
                        <div className={styles.totalUsers}>
                            <p style={{fontSize: '1.8rem'}}>
                                Total selected users:&nbsp;
                            </p>
                            <p>
                                <strong style={{fontSize: '1.8rem'}}> {totalUsers}</strong>
                            </p>
                        </div>
                        <div className={styles.costWrapper}>

                            <div className={styles.totalCost}>
                                <p style={{fontSize: '1.8rem', fontWeight: 600}}>
                                    Total Cost
                                </p>
                                <p className={styles.money}>
                                    <strong
                                        style={{fontSize: '3.8rem'}}>{totalUsers * totalCost}</strong>
                                    <span style={{fontSize: '1.8rem', margin: '0 .5rem'}}>Points</span>
                                </p>
                            </div>
                            <button onClick={this.handleClick}
                                    type='button'
                                    disabled={disabled || !totalUsers}
                                    className={(!disabled && totalUsers) ? styles.btn : styles.btnDisabled}>
                                {btnName} <strong>&rarr;</strong></button>

                        </div>

                    </footer>
                </div>);
        }
        return show;
    }
}
