import * as React from "react";
import * as styles from './styles.module.sass';
import { hot } from "react-hot-loader";
import { Tooltip } from "reactstrap";
import { getLocalizedCurrency, getOptimisticImageURL } from "../../../../../Utils/helpers";
import { IStoreItem } from "../../../../types/wizard-types";
const ReviewsStrings = {
  itemIsSelected: 'Selected!',
};

interface IProps {
  iso_code: string;
  item: IStoreItem;
  selectedItemId?: string;
  setSelectedItemId: (selectedItemId?: string) => void;
}

interface IState {
  tooltipOpen: boolean;
}


class GiftListItem extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
  }

  public toggleToolTip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };
  public onItemClicked = () => {
    const { setSelectedItemId, selectedItemId, item } = this.props;
    const isSelected = Boolean(selectedItemId && selectedItemId === item.id);
    setSelectedItemId(isSelected ? undefined : this.props.item.id)
  };
  public render() {
    const { item, iso_code, selectedItemId } = this.props;
    const currency = getLocalizedCurrency(iso_code);
    const imageURL = getOptimisticImageURL(item);
    const itemClass = selectedItemId ? selectedItemId === item.id ? styles.active : styles.blur : '';
    return (
      <>
        <div className={`${styles.wrapper} ${itemClass}`}>
          <div onClick={this.onItemClicked} className={styles.cardWrapper}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <img className={styles.itemImg} src={imageURL} />
              <div className={styles.dataWrapper}>
                <p className={styles.itemName} id={`tt${item.id}`}>{item.name.substring(0, 15)}
                  {item.name.length > 15 && '...'}</p>
                <Tooltip style={{ fontSize: '1.8rem' }} placement="top"
                  target={`#tt${item.id}`} toggle={this.toggleToolTip}
                  isOpen={this.state.tooltipOpen}>
                  {item.name}
                </Tooltip>
                <p className={styles.itemPrice}>{item.price && (+item.price).toFixed(2)} {currency}
                  {selectedItemId && selectedItemId === item.id && <span>{ReviewsStrings.itemIsSelected}</span>}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default hot(module)(GiftListItem);
