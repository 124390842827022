import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import * as React from "react";
import StepsWithProgress from "../StepsWithProgress";
import OnlineToOfflineName from "../OnlineToOfflineName";
import {
  InjectedFormProps,
  reduxForm,
  Field,
  formValueSelector
} from "redux-form";
import { useEffect } from "react";
import moment from "moment";
import OnlineToOfflineSettings from "../OnlineToOfflineSettings";
import { useState } from "react";
import { selectedTab } from "../../../redux-store/validators";
import OnlineToOfflineTemplate from "../OnlineToOfflineTemplate";
import BreadCrumbs from "../../../contexts/BreadCrumbs";
import StoreData from "../../../contexts/StoreData";
import { connect } from "react-redux";
import OnlineToOfflinePublish from "../OnlineToOfflinePublish";
import { useCampaignEditAndCreate } from "./hooks";
import { OTOTabNavigation } from "./OTOTabNav";
import {
  IOTOFormProps,
  screensInputNames,
  CampaignFormScreens,
  formatFormToRequestData
} from "./lib";
import { RouteComponentProps, withRouter } from "react-router";
import formElementsStyles from "../../FormElements/styles.module.sass";
import { resizeImage } from "../GiftInboxCommon/AddSpecialItemForm";

export enum CampaignLanguages {
  en = "en",
  ar = "ar"
}

const genHeadingText = (isPoints: boolean, storeName = "") => {
  return `On Mother’s day, show her that you care! Send a special Mother’s day gift to your loved one`;
};
const genDescriptionText = (isPoints: boolean) => {
  return `Enter your name, and her name and number, 
  and she will receive a message sent specially from you so she can redeem her gift from the nearest branch!`;
};

const genArabicHeadingText = (isPoints: boolean, storeName = "") => {
  return `عشان ست الحبايب تستاهل حاجة حلوة
  عبر لها عن حبك وارسل لها هدية في يوم الأم
  `;
};
const genArabicDescriptionText = (isPoints: boolean) => {
  return `اكتب اسمك وبياناتها 
  وبيوصل لها رسالة بإسمك عشان تقدر تستلم هديتها من أقرب فرع
  `;
};

const OnlineToOfflineMain: React.FunctionComponent<
  InjectedFormProps & IOTOFormProps & RouteComponentProps
> = ({
  valid,
  touch,
  initialize,
  change,
  formValues,
  isEdit,
  initialValues,
  initialUnlimitedIncentives,
  initialNewCustomersOnly,
  history,
  currentCustomersCount
}) => {
  const { setCrumbs } = React.useContext(BreadCrumbs);
  const { storeData } = React.useContext(StoreData);
  const [unlimitedIncentives, setUnlimitedIncentives] = useState<boolean>(
    initialUnlimitedIncentives
  );
  const [newCustomersOnly, setNewCustomersOnly] = useState<boolean>(
    initialNewCustomersOnly
  );
  const [campaignLanguage, setCampaignLanguage] = useState<CampaignLanguages>(
    CampaignLanguages.en
  );
  const {
    createdCampaignId,
    loading,
    createMenuItemCampaign,
    createPointsCampaign,
    createVoucherCampaign,
    editMenuItemCampaign,
    editPointsCampaign,
    editVoucherCampaign
  } = useCampaignEditAndCreate();
  const setCrumbsToCreateCampaign = React.useCallback(() => {
    setCrumbs([
      {
        name: "Koinz Reach",
        url: "",
        disabled: true
      },
      {
        name: "Create OTO special occasions",
        url: "/"
      }
    ]);
  }, [setCrumbs]);

  const initializeNewCampaignFrom = React.useCallback(() => {
    if (storeData) {
      initialize({
        name: "new campaign - " + moment().format("DD MMM YYYY"),
        main_color: {
          hex: storeData.background_color || "#ffffff"
        },
        text_color: {
          hex: "#ffffff"
        },
        campaign_logo: "",
        incentive_number: 1000,
        only_first_store_visitors: false,
        heading_text:
          campaignLanguage === CampaignLanguages.ar
            ? genArabicHeadingText(true, storeData.name)
            : genHeadingText(true, storeData.name),
        description:
          campaignLanguage === CampaignLanguages.ar
            ? genArabicDescriptionText(true)
            : genDescriptionText(true),
        download_title:
          campaignLanguage === CampaignLanguages.ar
            ? "بنتمنى يوم أم سعيد لك و لعيلتك"
            : "Happy Mother’s day to you and your loved ones!"
      });
    }
  }, [initialize]);

  useEffect(() => {
    if (storeData) {
      change(
        "heading_text",
        campaignLanguage === CampaignLanguages.ar
          ? genArabicHeadingText(true, storeData.name)
          : genHeadingText(true, storeData.name)
      );
      change(
        "description",
        campaignLanguage === CampaignLanguages.ar
          ? genArabicDescriptionText(true)
          : genDescriptionText(true)
      );
      change(
        "download_title",
        campaignLanguage === CampaignLanguages.ar
          ? "بنتمنى يوم أم سعيد لك و لعيلتك "
          : "Happy Mother’s day to you and your loved ones!"
      );
    }
  }, [campaignLanguage, storeData]);

  const isSendingGiftInbox = React.useMemo(
    () =>
      !formValues.points_per_customer &&
      (formValues.voucher || formValues.item_id),
    [formValues]
  );
  useEffect(() => {
    if (storeData && !isEdit) {
      const isHeadingPlaceholder =
        genHeadingText(!!isSendingGiftInbox, storeData.name) ===
        formValues.heading_text;
      const isDescriptionPlaceholder =
        genHeadingText(!!isSendingGiftInbox, storeData.name) ===
        formValues.description;
      if (isHeadingPlaceholder) {
        change(
          "heading_text",
          genHeadingText(!isSendingGiftInbox, storeData.name)
        );
      }
      if (isDescriptionPlaceholder) {
        change("description", genDescriptionText(!isSendingGiftInbox));
      }
    }
  }, [isSendingGiftInbox, storeData, isEdit]);

  const setCampaignLanguageFromBoolean = (isArabic: number) => {
    setCampaignLanguage(
      isArabic === 1 ? CampaignLanguages.ar : CampaignLanguages.en
    );
    localStorage.setItem(
      "campaign_language",
      isArabic ? CampaignLanguages.ar : CampaignLanguages.en
    );
  };

  useEffect(() => {
    if (!isEdit) {
      setCrumbsToCreateCampaign();
      initializeNewCampaignFrom();
    }
  }, [isEdit]);
  const scrollToError = React.useCallback(() => {
    setTimeout(() => {
      const elem = document.querySelector(`.${formElementsStyles.formError}`);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }, [valid]);

  const goToCampaignInfo = React.useCallback(
    (id: string) => history.push("/online-to-offline/view?id=" + id),
    [history]
  );
  const { createCampaign, editCampaign } = React.useMemo(() => {
    let create = createPointsCampaign;
    let edit = editPointsCampaign;
    if (formValues.voucher) {
      create = createVoucherCampaign;
      edit = editVoucherCampaign;
    } else if (formValues.item_id) {
      create = createMenuItemCampaign;
      edit = editMenuItemCampaign;
    }
    return {
      createCampaign: create,
      editCampaign: edit
    };
  }, [formValues]);

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const onSaveClicked = async () => {
    let mappedValues = await formatFormToRequestData(
      formValues,
      newCustomersOnly,
      campaignLanguage
    );

    if (mappedValues.campaign_logo === "") {
      mappedValues.campaign_logo = undefined;
    } else {
      if (
        mappedValues.campaign_logo &&
        typeof mappedValues.campaign_logo === "object"
      ) {
        const base64CampaignLogo = await toBase64(
          mappedValues.campaign_logo[0]
        );
        if (typeof base64CampaignLogo == "string") {
          mappedValues.campaign_logo = base64CampaignLogo;
        }
      }
    }

    if (validateScreenFields(screensInputNames.length - 1)) {
      (isEdit
        ? editCampaign({ ...mappedValues, id: (initialValues as any).id })
        : createCampaign(mappedValues)
      ).then(id => {
        goToCampaignInfo(id as string);
      });
    }
  };
  const validateScreenFields = (screenIndex: CampaignFormScreens) => {
    touch(...screensInputNames[screenIndex]);
    if (!valid) {
      scrollToError();
    }
    return valid;
  };

  const getAndSetPreviewImageForPreviewInBrowser = async () => {
    if (formValues.campaign_logo) {
      const previewImageBase64 = await toBase64(formValues.campaign_logo[0]);
      localStorage.setItem("campaign_logo", `${previewImageBase64}`);
    }
  };

  React.useEffect(() => {
    getAndSetPreviewImageForPreviewInBrowser();
  }, [formValues.campaign_logo]);

  return createdCampaignId ? (
    <OnlineToOfflinePublish campaignId={createdCampaignId} />
  ) : (
    <form className={styles.form}>
      <p className={styles.title}>
        {isEdit ? "Edit" : "Create"} OTO special occasions campaign
      </p>
      <StepsWithProgress
        saving={loading}
        onSaveClicked={onSaveClicked}
        shouldGoNext={validateScreenFields}
        titles={["Campaign Name", "Campaign Settings", "Campaign Template"]}
      >
        <OnlineToOfflineName />
        <>
          <Field
            name={"tab_index"}
            isEdit={isEdit}
            validate={selectedTab}
            component={OTOTabNavigation}
          />
          <OnlineToOfflineSettings
            currentCustomersCount={currentCustomersCount}
            unlimitedIncentives={unlimitedIncentives}
            setUnlimitedIncentives={setUnlimitedIncentives}
            newCustomersOnly={newCustomersOnly}
            setNewCustomersOnly={setNewCustomersOnly}
            campaignLanguage={campaignLanguage}
            setCampaignLanguage={setCampaignLanguageFromBoolean}
            change={change}
            isSpecialEvents={true}
          />
        </>
        <OnlineToOfflineTemplate
          isSpecialOTO={true}
          campaign_language={campaignLanguage}
          secondPageHeading={formValues.download_title}
          campaign_logo={formValues.campaign_logo}
        />
      </StepsWithProgress>
    </form>
  );
};
let OnlineToOfflineFormMain = reduxForm({
  form: "onlineToOfflineForm"
})(OnlineToOfflineMain as any) as any;

const selector = formValueSelector("onlineToOfflineForm");

OnlineToOfflineFormMain = connect(state => {
  const formFields = Object.keys(screensInputNames)
    .map(k => screensInputNames[k])
    .flat(1);
  const values = selector(state, ...formFields);
  return {
    formValues: values
  };
})(OnlineToOfflineFormMain);

OnlineToOfflineFormMain = withRouter(OnlineToOfflineFormMain);

export default hot(module)(OnlineToOfflineFormMain);
