import React, { useMemo, useState, useCallback } from "react";
import { MODES } from "../../";
import * as styles from "./styles.module.sass";
import ProgressBar from "../../ProgressBar";
import { hot } from "react-hot-loader";
import TouchableGesture from "../../TouchableGesture";
import { useProgressState } from "../../../../../Utils/custom-hooks/useProgressState";
import { notify } from "react-notify-toast";

export interface IPhoneEntryProps {
  store: {
    image: string;
    country_iso_code?: string;
  };
  campaign: {
    campaign_page_title: string;
    campaign_page_description: string;
    backgroundColor: string;
    textColor: string;
    max_users: string | "infinity";
    current_users_count: number;
  };
  onSendPhoneNumber?: (phoneNumber: string) => void | Promise<void>;
  mode: MODES;
  formatText?: (str: string, { count: number }?) => string;
  isPointsIncentive?: boolean;
}

const modesClasses = {
  [MODES.EXAMPLE]: styles.example,
  [MODES.MOCK_UP]: styles.mockUp,
  [MODES.LIVE_APP]: styles.liveApp,
};

export const useKindaTranslate = (
  translateFn?: (
    str: string,
    { count: number }?: { count?: number; [x: string]: any }
  ) => string
) => {
  return useCallback(
    translateFn ||
      ((text: string, options: { count: number } = { count: 0 }) => {
        const { count } = options;
        return `${count ? count + " " : ""}${text}`;
      }),
    [translateFn]
  );
};

const PhoneEntry: React.FC<IPhoneEntryProps> = (props) => {
  const {
    mode,
    campaign: {
      campaign_page_description,
      campaign_page_title,
      current_users_count,
      backgroundColor,
      textColor,
      max_users,
    },
    store: { image },
    onSendPhoneNumber,
    formatText,
    isPointsIncentive = true,
  } = props;
  const t = useKindaTranslate(formatText);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const isInfinity = useMemo(() => max_users === "infinity", [max_users]);
  const giftsLeft = useMemo(() => {
    if (!isInfinity) {
      return +max_users - current_users_count;
    }
    return 0;
  }, [max_users, current_users_count, isInfinity]);
  const giftsPercentage = useMemo(() => {
    if (!isInfinity) {
      return current_users_count / +max_users;
    }
    return 0;
  }, [current_users_count, max_users, isInfinity]);
  const { loading, setLoading, setSuccess, setFailure } = useProgressState();
  const handleSubmit = useCallback(() => {
    if (!phoneNumber && MODES.LIVE_APP) {
      notify.show(t("Please enter your phone number"), "error");
      return;
    }
    if (onSendPhoneNumber) {
      setLoading();
      const promise = onSendPhoneNumber(phoneNumber) || Promise.resolve();
      promise.then(setSuccess).catch(setFailure);
    }
  }, [setSuccess, onSendPhoneNumber]);
  const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value.replace(/[\.e]/g, ""));
  };

  const preventEAndDot = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 69 || e.keyCode === 190) {
      e.preventDefault();
    }
  };
  return (
    <>
      {max_users !== "infinity" && (
        <div className={`${styles.header} ${modesClasses[mode]}`}>
          <div className={styles.titleAndRemainingGifts}>
            <p style={{ color: backgroundColor }} className={styles.title}>
              {t("HURRY UP")}!
            </p>
            <p className={styles.remainingGifts}>
              {t("gifts left", { count: giftsLeft })}!
            </p>
          </div>
          <ProgressBar
            percentage={giftsPercentage}
            wrapperClassName={styles.progressBarWrapper}
            progressClassName={styles.progress}
          />
        </div>
      )}
      <div className={`${styles.mainContent} ${modesClasses[mode]}`}>
        <div className={styles.campaignData}>
          <img src={image} className={styles.storeImage} />
          <h1 className={styles.campaignTitle}>{campaign_page_title}</h1>
          <p className={styles.campaignDescription}>
            {campaign_page_description}
          </p>
        </div>
        <div className={`${styles.lowerPanel} ${modesClasses[mode]}`}>
          <div className={styles.phoneNumberFieldWrapper}>
            <label className={styles.phoneNumberLabel}>{t("Your phone")}</label>
            <input
              style={{ borderBottomColor: backgroundColor }}
              required={true}
              disabled={mode === MODES.MOCK_UP}
              step={0}
              type="number"
              maxLength={15}
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              placeholder={
                props.store.country_iso_code === "eg"
                  ? "01x xxx xxxx"
                  : "05x xxx xxxx"
              }
              onKeyDown={preventEAndDot}
              className={styles.phoneNumberInput}
            />
          </div>
          <TouchableGesture
            disabled={mode === MODES.MOCK_UP}
            loading={loading}
            style={{ backgroundColor, color: textColor }}
            onClick={handleSubmit}
            type="button"
            className={styles.phoneNumberSubmit}
          >
            {isPointsIncentive ? t("Get free points!") : t("Get a free gift!")}
          </TouchableGesture>
        </div>
      </div>
    </>
  );
};

export default hot(module)(PhoneEntry);
