import * as React from "react";
import * as styles from './styles.module.sass';

interface IProps {
    handleNextStep: () => void;
    setCampaignName: (name: string) => { void }
}

interface IState {
    value: string,
    show: boolean
}

class NameCreation extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            show: false
        };
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
        value: event.target.value,
        show: true
    });

    public handleSubmit = (event) => {
        event.preventDefault();
        const {handleNextStep, setCampaignName} = this.props;
        const {value} = this.state;
        setCampaignName(value);
        handleNextStep();
    };

    public render() {
        const {value, show} = this.state;
        const isEmpty = !value.trim().length;
        return (
            <div className={styles.wrapper}>
                <div className={styles.textWrapper}>
                    <h1>Starting a New Campaign...</h1>
                    <p>Please enter your campaign name below</p>
                </div>
                <form onSubmit={this.handleSubmit} className={styles.formWrapper}>
                    <h1>Campaign Name</h1>
                    <input className={styles.input} maxLength={70} placeholder='Campaign Name' type="text"
                           value={value} onChange={this.handleChange}/>
                    <button disabled={isEmpty}
                            type='submit'
                            className={!isEmpty ? styles.btn : styles.btnDisabled}>
                        Next &rarr;
                    </button>
                    {isEmpty && show &&
                    <span className={styles.error}>Campaign name can't be empty</span>}
                </form>
            </div>
        );
    }
}

export default NameCreation;
