import * as styles from './styles.module.sass';
import { hot } from "react-hot-loader";
import * as React from "react";
import { useRef } from "react";
import { notify } from "react-notify-toast";
import { useRecoilValue } from "recoil";
import { remoteAuthenticationAtom } from "../../../atoms/remote-authentication.atom";

interface IProps {
  campaignId: string;
}

const OnlineToOfflinePublish: React.FunctionComponent<IProps> = ({
  campaignId,
}) => {
  const urlRef = useRef<HTMLInputElement>(null);
  const onCopyClicked = () => {
    if (urlRef.current) {
      urlRef.current.select();
      document.execCommand("copy");
      notify.show("Copied!", "success");
    }
  };
  const remoteAuthenticationValue = useRecoilValue(remoteAuthenticationAtom);
  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <img
          src={require("../../../assets/icon-publish.svg")}
          className={styles.img}
        />
        <h1 className={styles.header}>Publish your campaign</h1>
        <p className={styles.para}>
          Copy the link below and post it on your social <br /> media channels.
        </p>
        <div className={styles.clipWrapper}>
          <label className={styles.label}>Campaign link</label>
          <div className={styles.input}>
            <div className={styles.inputWrapper}>
              <input
                ref={urlRef}
                onClick={onCopyClicked}
                value={`${
                  remoteAuthenticationValue
                    ? process.env.REMOTE_CAMPAIGN_LINK +
                      campaignId +
                      "remoteAuthentication"
                    : process.env.CAMPAIGN_LINK + campaignId
                }`}
                className={styles.url}
              />
              <div className={styles.shadow} />
            </div>
            <div onClick={onCopyClicked} className={styles.clip}>
              <img
                className={styles.icon}
                src={require("../../../assets/icon-link.svg")}
              />
              <p className={styles.text}>Copy to clipboard</p>
            </div>
          </div>
        </div>
      </div>
      <button className={styles.btn}>See campaign analytics</button>
    </div>
  );
};

export default hot(module)(OnlineToOfflinePublish);
