import React, { useMemo, useState, useCallback } from "react";
import { MODES } from "../..";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import TouchableGesture from "../../TouchableGesture";
import { useProgressState } from "../../../../../Utils/custom-hooks/useProgressState";
import { CampaignLanguage } from "../../../../axios/getCampaignData";
const ERROR_CODE_CAMPAIGN_EXPIRED = 0;
const ERROR_CODE_PHONE_FORMAT_INVALID = 1;
const ERROR_CODE_PHONE_ALREADY_REGISTERED = 2;
const ERROR_CODE_CUSTOMER_IS_NOT_NEW_TO_THE_BRAND = 3;
const ERROR_CODE_CUSTOMER_IS_BLOCKED = 4;
export interface ISpecialOTOEntryProps {
  store: {
    image: string;
    country_iso_code?: string;
  };
  campaign: {
    campaign_page_title: string;
    campaign_page_description: string;
    campaign_language?: CampaignLanguage;
    backgroundColor: string;
    textColor: string;
    max_users: string | "infinity";
    current_users_count: number;
    campaign_logo?: string;
  };
  onSendPhoneNumber?: (phoneNumber: string) => void | Promise<void>;
  onSend?: (
    sender_name: string,
    receiver_name: string,
    phoneNumber: string
  ) => void | Promise<void>;
  mode: MODES;
  formatText?: (str: string, { count: number }?) => string;
  isPointsIncentive?: boolean;
  errorMessage?: string;
  errorCode?: number;
  setErrorCode?: any;
}

const modesClasses = {
  [MODES.EXAMPLE]: styles.example,
  [MODES.MOCK_UP]: styles.mockUp,
  [MODES.LIVE_APP]: styles.liveApp,
};

export const useKindaTranslate = (
  translateFn?: (
    str: string,
    { count: number }?: { count?: number; [x: string]: any }
  ) => string
) => {
  return useCallback(
    translateFn ||
      ((text: string, options: { count: number } = { count: 0 }) => {
        const { count } = options;
        return `${count ? count + " " : ""}${text}`;
      }),
    [translateFn]
  );
};

const SpecialOTOEntry: React.FC<ISpecialOTOEntryProps> = (props) => {
  const {
    mode,
    campaign: {
      campaign_language = CampaignLanguage.en,
      campaign_page_description,
      campaign_page_title,
      current_users_count,
      backgroundColor,
      textColor,
      campaign_logo,
      max_users,
    },
    store: { image },
    onSend,
    formatText,
    isPointsIncentive = true,
    errorCode,
    setErrorCode,
  } = props;
  const t = useKindaTranslate(formatText);
  const [yourName, setYourName] = useState<string>("");
  const [yourLoverName, setYourLoverName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const isInfinity = useMemo(() => max_users === "infinity", [max_users]);
  const giftsLeft = useMemo(() => {
    if (!isInfinity) {
      return +max_users - current_users_count;
    }
    return 0;
  }, [max_users, current_users_count, isInfinity]);

  const errorMsgsMap = useMemo(() => {
    return {
      [ERROR_CODE_PHONE_FORMAT_INVALID]:
        campaign_language === CampaignLanguage.en
          ? "Phone number format is invalid"
          : "رقم الجوال الذي أدخلته غير صحيح",
      [ERROR_CODE_PHONE_ALREADY_REGISTERED]:
        campaign_language === CampaignLanguage.en
          ? "Sorry, The gift is not valid for this number."
          : "عفوا، الهدية غير صالحة على هذا الرقم",
      [ERROR_CODE_CUSTOMER_IS_NOT_NEW_TO_THE_BRAND]:
        campaign_language === CampaignLanguage.en
          ? "Sorry, this campaign is for first time visitors only."
          : "عفوا، الهدية صالحة للعملاء الجدد فقط",
      [ERROR_CODE_CUSTOMER_IS_BLOCKED]:
        campaign_language === CampaignLanguage.en
          ? "Your number is blocked, please contact us to resolve your issue."
          : "رقم الجوال تم حظره من المتجر، قم بالتواصل معنا لمتابعة مشكلتك.",
      [ERROR_CODE_CAMPAIGN_EXPIRED]:
        campaign_language === CampaignLanguage.en
          ? "Sorry, the campaign has ended, we will keep you updated with our upcoming offers."
          : "نعتذر لك انتهى العرض في الوقت الحالى، سنوافيكم بالعروض الجديدة قريبا",
    };
  }, [campaign_language]);

  React.useEffect(() => {
    if (errorCode) {
      setError(errorMsgsMap[errorCode]);
    }
  }, [errorCode, campaign_language]);

  const { loading, setLoading, setSuccess, setFailure } = useProgressState();
  const handleSubmit = useCallback(() => {
    // form validation
    if ((!phoneNumber || !yourName || !yourLoverName) && MODES.LIVE_APP) {
      setError(
        campaign_language === CampaignLanguage.ar
          ? "من فضلك اكمل البيانات"
          : "Please fill your data"
      );
      return;
    }
    if (onSend) {
      setLoading();
      const promise =
        onSend(yourName, yourLoverName, phoneNumber) || Promise.resolve();
      promise.then(setSuccess).catch(setFailure);
    }
  }, [setSuccess, onSend]);

  const onYourNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYourName(e.target.value);
  };
  const onYourLoverNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYourLoverName(e.target.value);
  };

  function parseArabicNumbers(value) {
    value = Number(
      value
        .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function(d) {
          return d.charCodeAt(0) - 1632;
        })
        .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function(d) {
          return d.charCodeAt(0) - 1776;
        })
    );
    return value;
  }

  const onYourLoverPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/^\d*\.?\d*$/.test(e.target.value)) {
      setPhoneNumber(e.target.value.replace(/[\.e]/g, ""));
    } else {
      const parsedNumbers = parseArabicNumbers(e.target.value);
      if (!isNaN(parsedNumbers)) {
        let prefix = "";
        if (`${parsedNumbers}` !== "0") {
          prefix = "0";
        }
        setPhoneNumber(`${prefix}${parsedNumbers}`.replace(/[\.e]/g, ""));
      }
    }
  };

  const preventEAndDot = (e: React.KeyboardEvent<HTMLInputElement>) => {};

  React.useEffect(() => {
    if (phoneNumber && yourName && yourLoverName && setErrorCode) {
      setError(null);
      setErrorCode(undefined);
    }
  }, [phoneNumber, yourName, yourLoverName]);

  return (
    <>
      <div
        className={`${styles.mainContent} ${modesClasses[mode]}`}
        dir={campaign_language === CampaignLanguage.ar ? "rtl" : "ltr"}
        style={{
          direction: campaign_language === CampaignLanguage.ar ? "rtl" : "ltr",
          textAlign:
            campaign_language === CampaignLanguage.ar ? "right" : "left",
        }}
      >
        <div className={styles.campaignData}>
          <img
            src={campaign_logo ? campaign_logo : image}
            className={`${styles.storeImage} ${
              mode === MODES.MOCK_UP ? styles.storeImageMockup : ""
            }`}
          />
          <h1
            className={`${styles.campaignTitle} ${
              mode === MODES.MOCK_UP ? styles.campaignTitleMockup : ""
            }`}
          >
            {campaign_page_title}
          </h1>
          <p
            className={`${styles.campaignDescription} ${
              mode === MODES.MOCK_UP ? styles.campaignDescriptionMockup : ""
            }`}
          >
            {campaign_page_description}
          </p>
        </div>
        <div
          className={`${styles.giftBox} ${
            mode === MODES.MOCK_UP ? styles.giftBoxMockup : ""
          } ${mode === MODES.EXAMPLE ? styles.giftBoxBrowser : ""} ${
            mode === MODES.LIVE_APP ? styles.giftBoxLive : ""
          } `}
        >
          {/* Mother gift png , white box */}
          <img src={require("../../assets/mother-gift1.png")} />
        </div>
        <div className={`${styles.lowerPanel} ${modesClasses[mode]}`}>
          {error && <div className={styles.error}>{error}</div>}
          <div className={styles.phoneNumberFieldWrapper}>
            <label className={styles.phoneNumberLabel}>
              {campaign_language === CampaignLanguage.ar
                ? t("اسمك")
                : t("Your Name")}
            </label>
            <input
              style={{
                textAlign:
                  campaign_language === CampaignLanguage.ar ? "right" : "left",
              }}
              required={true}
              disabled={mode === MODES.MOCK_UP}
              maxLength={15}
              type="text"
              value={yourName}
              onChange={onYourNameChange}
              placeholder={
                campaign_language === CampaignLanguage.ar
                  ? "ادخل اسمك"
                  : "Enter your name"
              }
              onKeyDown={preventEAndDot}
              className={styles.phoneNumberInput}
            />
          </div>
          <div className={styles.phoneNumberFieldWrapper}>
            <label className={styles.phoneNumberLabel}>
              {campaign_language === CampaignLanguage.ar
                ? t("اسم ست الحبايب")
                : t("Your loved one’s name")}
            </label>
            <input
              style={{
                textAlign:
                  campaign_language === CampaignLanguage.ar ? "right" : "left",
              }}
              required={true}
              disabled={mode === MODES.MOCK_UP}
              maxLength={15}
              type="text"
              value={yourLoverName}
              onChange={onYourLoverNameChange}
              placeholder={
                campaign_language === CampaignLanguage.ar
                  ? "ادخل اسم ست الحبايب"
                  : "Enter your loved one’s name"
              }
              onKeyDown={preventEAndDot}
              className={styles.phoneNumberInput}
            />
          </div>
          <div className={styles.phoneNumberFieldWrapper}>
            <label className={styles.phoneNumberLabel}>
              {campaign_language === CampaignLanguage.ar
                ? t("رقمها")
                : t("Her number")}
            </label>
            <input
              style={{
                textAlign:
                  campaign_language === CampaignLanguage.ar ? "right" : "left",
              }}
              required={true}
              disabled={mode === MODES.MOCK_UP}
              type="text"
              maxLength={15}
              value={phoneNumber}
              onChange={onYourLoverPhoneChange}
              placeholder={
                props.store.country_iso_code === "eg"
                  ? "01x xxx xxxx"
                  : "05x xxx xxxx"
              }
              onKeyDown={preventEAndDot}
              className={styles.phoneNumberInput}
            />
          </div>
          <TouchableGesture
            disabled={mode === MODES.MOCK_UP}
            loading={loading}
            style={{ backgroundColor, color: textColor }}
            onClick={handleSubmit}
            type="button"
            className={styles.formSubmit}
          >
            {isPointsIncentive
              ? t(
                  campaign_language === CampaignLanguage.ar
                    ? "ارسل هديتك"
                    : "Send your gift"
                )
              : t(
                  campaign_language === CampaignLanguage.ar
                    ? "ارسل هديتك"
                    : "Send your gift"
                )}
          </TouchableGesture>
        </div>
      </div>
    </>
  );
};

export default hot(module)(SpecialOTOEntry);
