import {call, put, select, takeLatest} from "redux-saga/effects";
import {getQuotasAction} from "../../constants";
import {getQuotas} from "../../axios";
import {selectToken} from "../selectors";
import {getQuotasFailure, getQuotasSuccess} from "../actions/get-quotas-actions";
import {IQuota} from "../../types";
import {getPurchasedQuotas} from "../../axios/getPurchasedQuotas";

function* getQuotasSaga() {
    try {
        const token = yield select(selectToken);
        const res = yield call(getQuotas, token);
        const quotas: IQuota[] = res.data.quotas.sort((a, b) => (a.points - b.points)
            + (a.points_comparison_criteria - b.points_comparison_criteria))
            .map((currentQuota, index, allQuotas) => {
                if (index > 0 && index < allQuotas.length) {
                    currentQuota={
                        ...currentQuota,
                        off:((allQuotas[0].sms_price-currentQuota.sms_price)/allQuotas[0].sms_price)
                    }
                }
                return currentQuota;
            });
        const purchasedQuotasRes = yield call(getPurchasedQuotas, token);
        yield put(getQuotasSuccess(quotas,!!purchasedQuotasRes.data.total_quotas));
    } catch (e) {
        yield put(getQuotasFailure(e));
    }
}

export function* watchGetQuotasSaga() {
    yield takeLatest(getQuotasAction.requested, getQuotasSaga);
}
