import * as React from "react";
import * as styles from './styles.module.sass';

interface IProps {
  number: number;
  title: string;
  img: string;
  index: number;
  isBig?: boolean;
  loading?: boolean;
}

const Card: React.FunctionComponent<IProps> = ({isBig ,img, title, number, index, loading }) => {
  return (
    <div className={`${styles.wrapper} ${isBig ? styles.bigCard: styles.smallCard}`}>
      <div className={styles.indexWrapper}>
        {index}
      </div>
      <img className={styles.statIcon} src={img} />
      <div className={styles.textWrapper}>
        <p className={styles.title}>
          {title}
        </p>
        {!loading && <span className={styles.statNumber}>{number}</span>}
      </div>
    </div>
  );
}

export default Card;
