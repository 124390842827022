import * as React from "react";
import * as styles from './styles.module.sass';
import {WrappedFieldProps} from 'redux-form';

interface IProps {
  type: string;
  placeholder: string;
  label: string;
  className?: string;
  note?: string;
  disabled?: boolean;
  infinity?: boolean;
}

export class RenderGenericField extends React.Component<WrappedFieldProps & IProps> {

  public render() {
    const {
      input,
      type,
      placeholder,
      note,
      meta: {touched, error, warning},
      disabled,
      infinity,
      className,
      label
    } = this.props;
    return (
      <div className={`${styles.inputWrapper} ${className || ''}`}>
        {label && <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
          {touched && ((error && <span className={styles.formError}>{error}</span>) || (warning &&
            <span>{warning}</span>))}
        </label>}
        {infinity ?
          <div
            style={{
              background: '#ebebeb', display: 'flex', alignItems: 'center',
              fontSize: '1.6rem', fontWeight: 500, color: '#2d2d2d'
            }}
            className={styles.input}>&#8734;
          </div> :
          <input autoComplete='off' placeholder={placeholder} disabled={disabled}
                 className={styles.input} id={input.name} {...input}
                 type={type}/>}
        {note && <p className={styles.notice}>{note}</p>}
        {!label && touched && ((error &&
          <span className={styles.formError}>{error}</span>) || (warning &&
          <span>{warning}</span>))}
      </div>
    );
  }
}
