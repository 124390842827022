import * as React from "react";
import * as styles from './styles.module.sass';
import {WrappedFieldProps} from 'redux-form';
import {countries} from 'country-data';
import ReactTooltip from 'react-tooltip';
import IcoMoon from 'react-icomoon';

interface IProps {
    label: string;
    type: string;
    placeholder1: string;
    placeholder2: string;
    className?: string;
    country_ISO?: string;
    disabled?: boolean;
}

export class RenderTwoGenericFields extends React.Component<WrappedFieldProps & IProps> {

    public handleFromChange = (event) => {
        const {value, onChange} = this.props.input;
        onChange({...value, value1: event.target.value});
    };

    public handleToChange = (event) => {
        const {value, onChange} = this.props.input;
        onChange({...value, value2: event.target.value});

    };

    public render() {
        const {
            input,
            label,
            type,
            placeholder1,
            placeholder2,
            meta: {touched, error, warning},
            className,
            disabled,
            country_ISO,
        } = this.props;
        const currency = countries[(country_ISO || '').toUpperCase()].currencies;
        return (
            <div className={`${styles.twoInputsWrapper} ${className || ''}`}>
                <div className={styles.textWrapper}>
                    <h2>{label}</h2>
                    <div className={styles.icon}
                         data-tip="It allows you to select customers based on how much do they spend on the average per visit in your brand.">
                        <IcoMoon icon="question"/></div>
                </div>
                <div className={styles.valueWrapper}>
                    <input disabled={disabled} className={styles.input} maxLength={30}
                           placeholder={placeholder1} type={type} value={input.value.value1}
                           onChange={this.handleFromChange}/>
                    {country_ISO && <p className={styles.paragraph1}>{currency}</p>}
                    <input disabled={disabled} className={styles.input} maxLength={30}
                           placeholder={placeholder2} type={type} value={input.value.value2}
                           onChange={this.handleToChange}/>
                    {country_ISO && <p className={styles.paragraph2}>{currency}</p>}
                </div>

                <ReactTooltip place="top" type="dark" effect="float"/>

                {touched && ((error &&
                    <span className={styles.formError}>{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        );
    }
}
