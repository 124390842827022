import React, { useContext, useMemo } from "react";
import { MODES, MiniAppScreen, ScreenNames } from "..";
// @ts-ignore
import StoreData from "../../../contexts/StoreData";
import MiniApp from "../MiniApp";
import * as styles from "./styles.module.sass";
import PhoneEntry from "../MiniApp/PhoneEntry";
import ThankYou from "../MiniApp/ThankYou";
import SpecialOTOEntry from "../MiniApp/SpecialOTOEntry";
import ThankYouSpecialOTO from "../MiniApp/ThankYouSpecialEntry";

interface IMockUpWrapperProps {
  headingText: string;
  description: string;
  mainColor: string;
  textColor: string;
  storeImage: string;
  currentScreen: MiniAppScreen;
  isPointsIncentive: boolean;
  download_title: string;
  isSpecialOTO?: boolean;
  campaign_language?: any;
  secondPageHeading?: string;
  campaign_logo?: string;
}

const useScreensMap = (props: IMockUpWrapperProps) => {
  const {
    headingText,
    description,
    mainColor,
    textColor,
    storeImage,
    isPointsIncentive,
    download_title,
    isSpecialOTO,
    campaign_language,
    secondPageHeading,
    campaign_logo,
  } = props;

  const { storeData } = useContext(StoreData);

  return useMemo(
    () => ({
      [ScreenNames.PhoneEntry]: isSpecialOTO ? (
        <SpecialOTOEntry
          campaign={{
            backgroundColor: mainColor,
            textColor,
            campaign_page_description: description,
            campaign_page_title: headingText,
            current_users_count: 92,
            max_users: "115",
            campaign_language: campaign_language,
            campaign_logo: campaign_logo,
          }}
          mode={MODES.MOCK_UP}
          store={{
            image: storeImage,
            country_iso_code: storeData
              ? storeData.country_iso_code
              : undefined,
          }}
        />
      ) : (
        <PhoneEntry
          campaign={{
            backgroundColor: mainColor,
            textColor,
            campaign_page_description: description,
            campaign_page_title: headingText,
            current_users_count: 92,
            max_users: "115",
          }}
          mode={MODES.MOCK_UP}
          store={{
            image: storeImage,
            country_iso_code: storeData
              ? storeData.country_iso_code
              : undefined,
          }}
        />
      ),
      [ScreenNames.ThankYou]: isSpecialOTO ? (
        <ThankYouSpecialOTO
          download_title={download_title}
          backgroundColor={mainColor}
          mode={MODES.MOCK_UP}
          isPointsIncentive={isPointsIncentive}
          campaign_logo={campaign_logo}
          store={{
            image: storeImage,
          }}
          OS={"IOS"}
          campaignLanguage={campaign_language}
          secondPageHeading={secondPageHeading}
        />
      ) : (
        <ThankYou
          download_title={download_title}
          backgroundColor={mainColor}
          mode={MODES.MOCK_UP}
          isPointsIncentive={isPointsIncentive}
          store={{
            image: storeImage,
          }}
          OS={"IOS"}
        />
      ),
    }),
    [
      description,
      mainColor,
      textColor,
      headingText,
      storeImage,
      download_title,
      campaign_logo,
    ]
  );
};

const MockUpWrapper: React.FC<IMockUpWrapperProps> = (props) => {
  const { mainColor, textColor, currentScreen } = props;
  const renderedScreen = useScreensMap(props)[currentScreen];
  const { storeData } = useContext(StoreData);
  return (
    storeData && (
      <div className={styles.mobile}>
        <div className={styles.notch} />
        <MiniApp
          backgroundColor={mainColor}
          textColor={textColor}
          mode={MODES.MOCK_UP}
        >
          {renderedScreen}
        </MiniApp>
      </div>
    )
  );
};

export default MockUpWrapper;
