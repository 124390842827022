import Switch, { ReactSwitchProps } from 'react-switch';
import React from 'react';

export const SettingsSwitch = (props: ReactSwitchProps) => {
    return (
        <Switch
            onHandleColor="#fff"
            handleDiameter={18}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0 1px 4px 0 rgba(0, 0, 0, 0.1)"
            activeBoxShadow="0 1px 4px 0 rgba(0, 0, 0, 0.1)"
            height={20}
            width={44}
            onColor="#fdae11" {...props} />
    );
};
