import * as React from "react";
import * as styles from './styles.module.sass';

interface IState {
  isOpen: boolean;
}

export class InfoPage extends React.Component<{}, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  public toggle = (e?) => {
    if (e) {
      e.preventDefault();
    }
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  };

  public render() {
    const {isOpen} = this.state;
    return (
      <div className={styles.app}>
        <section className={styles.section}>
          <div className={styles.text}>
            <h1>Koinz Reach</h1>
            <p>
              3 Smart Reasons Why You<br/>Should Consider Paying For<br/>Your
              Traffic
            </p>
            <button onClick={this.toggle} className={styles.btn}>
              How to use it<img src={require("../../assets/icon-arrow.svg")}/>
            </button>
          </div>
          <div className={styles["video-wrapper"]}>
            <img src={require("../../assets/video-background.svg")} className={styles.background}/>
            <img src={require("../../assets/video-thumbnail.png")} className={styles["video-img"]}/>
            {/*<img className={styles["video-img"]}/>*/}

            <a onClick={this.toggle} className={styles["video-play-button"]} href="#">
              <span/>
            </a>
            <VideoPopUp url={"https://www.youtube.com/embed/_akZV9S1_vg"} isOpen={isOpen} toggle={this.toggle}/>
          </div>
        </section>
        <div/>
        <section className={styles["why-section"]}>
          <div className={styles.line}/>
          <h2>Why Koinz Reach</h2>
          <div className={styles["cards-wrapper"]}>
            <div className={styles.card}>
              <img src={require("../../assets/robot.svg")} className={styles["card-img"]}/>
              <div className="card-text-wrapper">
                <h4 className={styles["card-title"]}>Run Marketing Automatically</h4>
                <p className={styles["card-text"]}>
                  Send email SMS targeted marketing campaigns, without lifting a
                  finger.
                </p>
              </div>
            </div>
            <div className={styles.card}>
              <img src={require("../../assets/target.svg")} className={styles["card-img"]}/>
              <div className="card-text-wrapper">
                <h4 className={styles["card-title"]}>Your best targeting tool</h4>
                <p className={styles["card-text"]}>
                  Easily target your customer by average size ticket, location,
                  visiting frequency.
                </p>
              </div>
            </div>
            <div className={styles.card}>
              <img src={require("../../assets/growth.svg")} className={styles["card-img"]}/>
              <div className="card-text-wrapper">
                <h4 className={styles["card-title"]}>Increase Revenue Growth</h4>
                <p className={styles["card-text"]}>
                  Koinz Reach increases customer visits, Drives loyalty thus
                  bringing in more money.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export const VideoPopUp: React.FunctionComponent<{ isOpen: boolean, toggle: () => void, url: string }> =
  ({isOpen, toggle, url}) => {
    return (
      <div onClick={toggle}
           className={`${isOpen ? styles.open : null} ${styles.videoOverlay}`}>
        <a onClick={toggle} className={styles.videoOverlayClose}>&times;</a>
        {isOpen &&
        <iframe width="50%" height="50%" src={url}
                frameBorder="0" allowFullScreen={true}/>}
      </div>
    );
  };
