import * as constants from '../../constants';
import {mutateState} from "../../helpers/mutate-state";
import {Map} from "immutable";
import {
    ICreateCampaignActionSuccess,
    ISetCampaignNameAction,
    ISetCampaignTypeAction
} from "../../types";

export interface ICreateCampaignReducerState {
    name: string,
    type: string,
    isCreated: boolean,
    isCreating: boolean,
    isFailed:boolean,
    closeModal:boolean,
    createdCampaign: {}
}

const NAME = 'name';
const TYPE = 'type';
const IS_CREATED = 'isCreated';
const IS_CREATING = 'isCreating';
const IS_FAILED = 'isFailed';
const CLOSE_MODAL = 'closeModal';
const CREATED_CAMPAIGN = "createdCampaign";

const initialState: ICreateCampaignReducerState = Map({
    [NAME]: '',
    [TYPE]: '',
    [IS_CREATED]: false,
    [IS_CREATING]: false,
    [IS_FAILED]:false,
    [CLOSE_MODAL]:false,
    [CREATED_CAMPAIGN]: {}

}).toJS();

const createCampaignReducer = (state: ICreateCampaignReducerState = initialState, action: ISetCampaignNameAction | ISetCampaignTypeAction | ICreateCampaignActionSuccess): ICreateCampaignReducerState => {
    switch (action.type) {
        case constants.setCampaignNameAction: {
            return mutateState(state, map => {
                map.set(NAME, (action as ISetCampaignNameAction).payload);
            });
        }
        case constants.setCampaignTypeAction: {
            return mutateState(state, map => {
                map.set(TYPE, (action as ISetCampaignTypeAction).payload);
            });
        }
        case constants.createCampaignAction.requested: {
        return mutateState(state, map => {
            map.set(IS_CREATED, false);
            map.set(IS_CREATING, true);
            map.set(IS_FAILED, false);
            map.set(CLOSE_MODAL, false);
        });
    }
        case constants.createCampaignAction.fulfilled: {
            return mutateState(state, map => {
                map.set(IS_CREATED, true);
                map.set(IS_CREATING, false);
                map.set(IS_FAILED, false);
                map.set(CLOSE_MODAL, true);
            });
        }
        case constants.createCampaignAction.rejected: {
            return mutateState(state, map => {
                map.set(IS_FAILED, true);
                map.set(IS_CREATED, false);
                map.set(IS_CREATING, false);
                map.set(CLOSE_MODAL, true);
            });
        }
        case constants.resetFailAction:{
            return mutateState(state, map => {
                map.set(IS_FAILED, false);
                map.set(IS_CREATED, false);
                map.set(IS_CREATING, false);
                map.set(CLOSE_MODAL, false);
            });
        }
        default:
            return state;
    }
};


export default createCampaignReducer;
