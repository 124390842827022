import { ScreenNames, MiniAppScreen } from "../../oto-campaign-mini-app";

import StoreData from "../../../contexts/StoreData";

import MockUpWrapper from "../../oto-campaign-mini-app/MockUpWrapper";

import { IRootReducerState } from "../../../redux-store/rootReducer";

import { getFormValues } from "redux-form";

import { connect } from "react-redux";
import React, { useMemo, useCallback } from "react";
import * as styles from "./styles.module.sass";
import { imagesURL } from "../../../../Utils/helpers";

export const ScreensArray = [ScreenNames.PhoneEntry, ScreenNames.ThankYou];

interface IProps {
  heading_text: string;
  description: string;
  main_color: string;
  text_color: string;
  currentScreen: MiniAppScreen;
  currentScreenIndex: number;
  nextScreen: () => void;
  previousScreen: () => void;
  isPointsIncentive: boolean;
  download_title: string;
  isSpecialOTO?: boolean;
  campaign_language?: any;
  secondPageHeading?: string;
  campaign_logo?: string;
}

const MockUp: React.FC<IProps> = ({
  description,
  heading_text,
  main_color,
  text_color,
  currentScreen,
  currentScreenIndex,
  nextScreen,
  previousScreen,
  isPointsIncentive,
  download_title,
  isSpecialOTO,
  campaign_language,
  campaign_logo,
  secondPageHeading
}) => {
  const { storeData } = React.useContext(StoreData);
  const isPreviousDisabled = useMemo(() => currentScreenIndex === 0, [
    currentScreenIndex
  ]);
  const isNextDisabled = useMemo(
    () => currentScreenIndex === ScreensArray.length - 1,
    [currentScreenIndex]
  );

  React.useEffect(() => {
    if (isSpecialOTO) {
      localStorage.setItem("campaign_tyoe", "special_event_OTO");
    } else {
      localStorage.setItem("campaign_tyoe", "normal_OTO");
    }
  }, [isSpecialOTO]);

  const nextScreenChevron = useMemo(() => {
    return isNextDisabled
      ? require("../../../assets/forward-inactive.svg")
      : require("../../../assets/forward-active.svg");
  }, [isNextDisabled]);
  const previousScreenChevron = useMemo(() => {
    return isPreviousDisabled
      ? require("../../../assets/backward-inactive.svg")
      : require("../../../assets/backward-active.svg");
  }, [isPreviousDisabled]);
  const formatColor = useCallback((hexColor: string) => hexColor.slice(1), []);
  return (
    storeData && (
      <>
        <div className={styles.switchScreen}>
          <button
            disabled={isPreviousDisabled}
            className={styles.switchBtn}
            type="button"
            onClick={previousScreen}
          >
            <img className={styles.chevron} src={previousScreenChevron} />
          </button>
          <div className={styles.currentScreen}>{currentScreen}</div>
          <button
            disabled={isNextDisabled}
            className={styles.switchBtn}
            onClick={nextScreen}
            type="button"
          >
            <img className={styles.chevron} src={nextScreenChevron} />
          </button>
        </div>
        <MockUpWrapper
          download_title={download_title}
          isPointsIncentive={isPointsIncentive}
          currentScreen={currentScreen}
          description={description}
          headingText={heading_text}
          mainColor={main_color}
          storeImage={imagesURL + storeData.store_image}
          textColor={text_color}
          isSpecialOTO={isSpecialOTO}
          campaign_language={campaign_language}
          secondPageHeading={secondPageHeading}
          campaign_logo={campaign_logo}
        />
        <a
          className={styles.viewExampleLink}
          target="_blank"
          href={`#/campaign-example/registration?title=${heading_text ||
            ""}&description=${description || ""}&background_color=${formatColor(
            main_color
          )}&text_color=${formatColor(text_color)}`}
        >
          Preview in Browser
          <img src={require("../../../assets/external.svg")} />
        </a>
      </>
    )
  );
};

const mapStateToProps = (state: IRootReducerState) => {
  const {
    description,
    heading_text,
    main_color,
    text_color,
    points_per_customer,
    download_title
  } = getFormValues("onlineToOfflineForm")(state) as any;
  return {
    description,
    heading_text,
    main_color: main_color.hex,
    text_color: text_color.hex,
    isPointsIncentive: Boolean(points_per_customer),
    download_title
  };
};
export default connect(mapStateToProps)(MockUp);
