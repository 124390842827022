import React, { useCallback } from "react";
import * as styles from "./styles.module.sass";
import { RouteComponentProps, withRouter } from "react-router";
import { IHomeCard } from "../index";
import { useToggleState } from "../../../../Utils/custom-hooks/useToggleState";
import { VideoPopUp } from "../../info-page";

interface IProps extends RouteComponentProps {
  options: IHomeCard;
}

const HomeCard: React.FunctionComponent<IProps> = ({
  history: { push },
  options: { url, subTitle, title, isNew, image, wrapSubTitle, videoUrl }
}) => {
  const goTo = useCallback(() => push(url), [push]);
  const [isOpen, toggle] = useToggleState();
  return (
    <div className={styles.wrapper}>
      <img className={styles.image} src={image} />
      <div style={{ opacity: isNew ? 1 : 0 }} className={styles.isNew}>
        <img src={require("../../../assets/icon-new-feature.svg")} />
        New feature!
      </div>
      <p className={styles.title}>
        {title}
        <br />
        Campaign
      </p>
      <p
        style={{ width: wrapSubTitle ? "75%" : "100%" }}
        className={styles.subTitle}
      >
        {subTitle}
      </p>
      <div className={styles.btnsWrapper}>
        <button onClick={goTo} className={styles.btn}>
          <span>+</span>Create
        </button>
        {videoUrl && (
          <button onClick={toggle} className={styles.howIt}>
            How it works
          </button>
        )}
      </div>
      {videoUrl && (
        <VideoPopUp isOpen={isOpen} toggle={toggle} url={videoUrl} />
      )}
    </div>
  );
};

export default withRouter(HomeCard);
