import * as constants from '../../constants';
import {mutateState} from "../../helpers/mutate-state";
import {Map} from "immutable";
import {
    IGetQuotasAction,
    IGetQuotasActionFailure,
    IGetQuotasActionSuccess,
    IQuota, IRequestQuotasAction, IRequestQuotasActionFailure, IRequestQuotasActionSuccess,
} from "../../types";

export interface IQuotasReducerState {
    loaded: boolean,
    loading: boolean,
    failed: boolean,
    quotas: IQuota[],
    hasPurchasedQuota: boolean,
    requesting: boolean,
    requested: boolean,
    requestedQuotaID: number,
    failedRequesting: boolean,
}

const LOADED = 'loaded';
const LOADING = 'loading';
const FAILED = 'failed';
const QUOTAS = "quotas";
const REQUESTED = "requested";
const REQUESTED_QUOTA_ID = "requestedQuotaID";
const REQUESTING = "requesting";
const FAILED_REQUESTING = "failedRequesting";
const HAS_PURCHASED_QUOTA = "hasPurchasedQuota";

const initialState: IQuotasReducerState = Map({
    [LOADED]: false,
    [LOADING]: false,
    [FAILED]: false,
    [QUOTAS]: [],
    [REQUESTED]: false,
    [REQUESTING]: false,
    [FAILED_REQUESTING]: false,
    [HAS_PURCHASED_QUOTA]: false,
    [REQUESTED_QUOTA_ID]: null

}).toJS();


const quotasReducer = (state: IQuotasReducerState = initialState,
                       action: IGetQuotasAction | IGetQuotasActionSuccess | IGetQuotasActionFailure
                           | IRequestQuotasAction | IRequestQuotasActionFailure | IRequestQuotasActionSuccess): IQuotasReducerState => {
    switch (action.type) {
        case constants.getQuotasAction.requested: {
            return mutateState(state, map => {
                map.set(LOADED, false);
                map.set(LOADING, true);
                map.set(FAILED, false);
                map.set(QUOTAS, []);
                map.set(HAS_PURCHASED_QUOTA, false);
            });
        }
        case constants.getQuotasAction.fulfilled: {
            return mutateState(state, map => {
                map.set(LOADED, true);
                map.set(LOADING, false);
                map.set(FAILED, false);
                map.set(QUOTAS, (action as IGetQuotasActionSuccess).payload.quotas);
                map.set(HAS_PURCHASED_QUOTA, (action as IGetQuotasActionSuccess).payload.hasPurchasedQuota);
            });
        }
        case constants.getQuotasAction.rejected: {
            return mutateState(state, map => {
                map.set(FAILED, true);
                map.set(LOADED, false);
                map.set(LOADING, false);
                map.set(QUOTAS, []);
                map.set(HAS_PURCHASED_QUOTA, false);
            });
        }
        case constants.requestQuotasAction.requested: {
            return mutateState(state, map => {
                map.set(REQUESTED, false);
                map.set(REQUESTING, true);
                map.set(FAILED_REQUESTING, false);
                map.set(REQUESTED_QUOTA_ID, (action as IRequestQuotasAction).payload);
            });
        }
        case constants.requestQuotasAction.fulfilled: {
            return mutateState(state, map => {
                map.set(REQUESTED, true);
                map.set(REQUESTING, false);
                map.set(FAILED_REQUESTING, false);
            });
        }
        case constants.requestQuotasAction.rejected: {
            return mutateState(state, map => {
                map.set(FAILED_REQUESTING, true);
                map.set(REQUESTED, false);
                map.set(REQUESTING, false);
            });
        }
        case constants.resetRequestQuotasAction: {
            return mutateState(state, map => {
                map.set(FAILED_REQUESTING, false);
                map.set(REQUESTED, false);
                map.set(REQUESTING, false);
                map.set(REQUESTED_QUOTA_ID, null as any);
            });
        }
        default:
            return state;
    }
};


export default quotasReducer;
