import * as React from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import * as styles from './styles.module.sass';
import {WrappedFieldProps} from "redux-form";
import {Aux} from "../../App";
import {CustomModal} from "../SMSCampaigns/Modal";
import IcoMoon from 'react-icomoon';

export interface ISelectItemData<OptionType = any> {
    label: string;
    value: OptionType;
}

interface ISelectItemProps {
    item: ISelectItemData;
    toggleModal: () => void;
    onSelect: (item: any) => any;
}

const SelectItem: React.SFC<ISelectItemProps> = ({item, onSelect, toggleModal}) => {
    const handleSelect = () => {
        if (item.value === 'custom') {
            toggleModal();
        } else {
            onSelect({dropDownValue: item.value});
        }
    };
    return (
        <DropdownItem className={styles.item} onClick={handleSelect}>{item.label}</DropdownItem>
    );
};

interface IRenderDropDownSelectState {
    isOpen: boolean;
    modal: boolean;
}

type DefaultProps = Readonly<typeof defaultProps>;

export interface IRenderDropDownSelectProps extends DefaultProps, WrappedFieldProps {
    label: string,
    toolTipText: string,
    type: string,
    disabled?: boolean;
    start?: ISelectItemData;
    options: ISelectItemData[];
}

const defaultProps = {
    renderSelectToggle: (item: ISelectItemData) => <Aux>{item.label}</Aux>,
};


export class RenderDropDownSelect extends React.Component<IRenderDropDownSelectProps, IRenderDropDownSelectState> {
    public static defaultProps = defaultProps;

    constructor(props: IRenderDropDownSelectProps) {
        super(props);
        this.state = {
            isOpen: false,
            modal: false,
        };
        if (props.start) {
            props.input.onChange({dropDownValue: props.start.value});
        }
    }

    public toggleSelectMenu = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };
    public toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    };


    public render() {
        const {input, label, meta: {touched, error, warning}, options, disabled, toolTipText} = this.props;
        const selectedLabel = options.find(opt => opt.value === input.value.dropDownValue) || {label, value: ''};
        return (
            <div className={styles.DDWrapper}>
                <div className={styles.textWrapper}>
                    <h2>{label}</h2>
                    <div className={styles.icon} data-tip={toolTipText}><IcoMoon icon="question"/></div>
                </div>
                <div className={styles.chipsWrapper}>
                    <Dropdown isOpen={this.state.isOpen} toggle={this.toggleSelectMenu}>
                        <DropdownToggle disabled={disabled} className={styles.switch}>
                            {this.props.renderSelectToggle(selectedLabel)}
                        </DropdownToggle>
                        <DropdownMenu className={styles.menuWrapper}>
                            {
                                options.map((option, index) => <SelectItem
                                    toggleModal={this.toggleModal}
                                    key={index}
                                    item={option}
                                    onSelect={input.onChange}/>)
                            }
                        </DropdownMenu>
                    </Dropdown>
                </div>
                {touched && ((error && <span className={styles.formError}>{error}</span>) || (warning &&
                    <span>{warning}</span>))}
                <CustomModal toggle={this.toggleModal}
                             {...this.props}
                             modal={this.state.modal}/>
            </div>
        )
    }
}
