import {call, put, select, takeLatest} from "redux-saga/effects";
import {loginAction} from "../../constants";
import {login, loginFailure, loginSuccess} from "../actions/login-actions";
import {getStoreProfile} from "../../axios/storeProfile";
import {ILoginAction} from "../../types";
import {getTotalUsersCount} from "../../axios/totalUserCount";
import {goToLogin} from "../../axios/axios";
import {selectToken} from "../selectors";
import {delay} from "redux-saga";
import {notify} from "react-notify-toast";

function* loginSaga(action: ILoginAction) {
  try {
    const selectedToken = yield select(selectToken);
    const token = action.payload || selectedToken;
    const storeProfileRes = yield call(getStoreProfile, token);
    const storeProfile = storeProfileRes.data.store;
    const totalUsersRes = yield call(getTotalUsersCount, token);
    yield put(loginSuccess({...storeProfile, total_users: totalUsersRes.data.total_users, token}));
    if (location.hash.includes('login')) {
        location.assign('/#/');
    }
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.clear();
      goToLogin();
        notify.show('Unauthorized access', 'warning');
    } else if (e.response.status === 500) {
      notify.show('An error has occurred, retrying...', 'warning');
      yield delay(1000);
      yield put(login(action.payload));
      yield put(loginFailure(e));
    }
  }
}

export function* watchLoginSaga() {
  yield takeLatest(loginAction.requested, loginSaga);
}
