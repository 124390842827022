import axios from './axios';
import { AxiosReturn } from "./types";
import * as Requests from './requests';
import { IOTOCampaign } from "../components/OnlineToOfflineCampaigns/RecentOTOs";


export const getOTOCampaign = (token: string, id: string): AxiosReturn<IOTOCampaign> => {
  return axios.get(Requests.OTOCampaignByID(id), {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  })
};
