import * as styles from './styles.module.sass';
import { hot } from "react-hot-loader";
import * as React from "react";
import StepsWithProgress from "../StepsWithProgress";
import OnlineToOfflineName from "../OnlineToOfflineName";
import { InjectedFormProps, reduxForm, Field, formValueSelector } from "redux-form";
import { useEffect } from "react";
import moment from "moment";
import OnlineToOfflineSettings from "../OnlineToOfflineSettings";
import { useState } from "react";
import { selectedTab } from "../../../redux-store/validators";
import OnlineToOfflineTemplate from "../OnlineToOfflineTemplate";
import BreadCrumbs from '../../../contexts/BreadCrumbs';
import StoreData from "../../../contexts/StoreData";
import { connect } from "react-redux";
import OnlineToOfflinePublish from "../OnlineToOfflinePublish";
import { useCampaignEditAndCreate } from './hooks';
import { OTOTabNavigation } from './OTOTabNav';
import { IOTOFormProps, screensInputNames, CampaignFormScreens, formatFormToRequestData } from './lib';
import { RouteComponentProps, withRouter } from 'react-router';
import formElementsStyles from '../../FormElements/styles.module.sass';


const genHeadingText = (isPoints: boolean, storeName = '') => {
  return `Get ${isPoints ? `free points` : `your free gift`} at ${storeName}`
}
const genDescriptionText = (isPoints: boolean) => {
  return `Enter your mobile number and get your welcoming ${isPoints ? `points` : `gift`} to our rewarding program`;
}

const OnlineToOfflineMain: React.FunctionComponent<InjectedFormProps & IOTOFormProps & RouteComponentProps> =
  ({
    valid, touch, initialize, change, formValues, isEdit,
    initialValues, initialUnlimitedIncentives, initialNewCustomersOnly,
    history, currentCustomersCount,
  }) => {
    const { setCrumbs } = React.useContext(BreadCrumbs);
    const { storeData } = React.useContext(StoreData);
    const [unlimitedIncentives, setUnlimitedIncentives] = useState<boolean>(initialUnlimitedIncentives);
    const [newCustomersOnly, setNewCustomersOnly] = useState<boolean>(initialNewCustomersOnly);
    const { createdCampaignId,
      loading,
      createMenuItemCampaign,
      createPointsCampaign,
      createVoucherCampaign,
      editMenuItemCampaign,
      editPointsCampaign,
      editVoucherCampaign,
    } = useCampaignEditAndCreate();
    const setCrumbsToCreateCampaign = React.useCallback(() => {
      setCrumbs([
        {
          name: 'Koinz Reach',
          url: '',
          disabled: true
        },
        {
          name: 'Create Online-To-Offline campaign',
          url: '/',
        }
      ]);
    }, [setCrumbs]);

    const initializeNewCampaignFrom = React.useCallback(() => {
      if (storeData) {
        initialize({
          name: 'new campaign - ' + moment().format('DD MMM YYYY'),
          main_color: {
            hex: storeData.background_color || '#ffffff'
          },
          text_color: {
            hex: '#ffffff'
          },
          incentive_number: 1000,
          only_first_store_visitors: false,
          heading_text: genHeadingText(true, storeData.name),
          description: genDescriptionText(true),
          download_title: 'To receive your gift download Koinz app NOW!',
        });
      }
    }, [initialize]);



    const isSendingGiftInbox = React.useMemo(() => !formValues.points_per_customer && (formValues.voucher || formValues.item_id), [formValues]);
    useEffect(() => {
      if (storeData && !isEdit) {
        const isHeadingPlaceholder = genHeadingText(!!isSendingGiftInbox, storeData.name) === formValues.heading_text;
        const isDescriptionPlaceholder = genHeadingText(!!isSendingGiftInbox, storeData.name) === formValues.description;
        if (isHeadingPlaceholder) {
          change('heading_text', genHeadingText(!isSendingGiftInbox, storeData.name));
        }
        if (isDescriptionPlaceholder) {
          change('description', genDescriptionText(!isSendingGiftInbox));
        }
      }
    }, [isSendingGiftInbox, storeData, isEdit])

    useEffect(() => {
      if (!isEdit) {
        setCrumbsToCreateCampaign();
        initializeNewCampaignFrom();
      }
    }, [isEdit]);
    const scrollToError = React.useCallback(() => {
      setTimeout(() => {
        const elem = document.querySelector(`.${formElementsStyles.formError}`)
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }, [valid]);

    const goToCampaignInfo = React.useCallback((id: string) => history.push('/online-to-offline/view?id=' + id), [history])
    const { createCampaign, editCampaign } = React.useMemo(() => {
      let create = createPointsCampaign;
      let edit = editPointsCampaign;
      if (formValues.voucher) {
        create = createVoucherCampaign;
        edit = editVoucherCampaign;
      } else if (formValues.item_id) {
        create = createMenuItemCampaign;
        edit = editMenuItemCampaign;
      }
      return {
        createCampaign: create,
        editCampaign: edit,
      };
    }, [formValues])

    const onSaveClicked = async () => {
      const mappedValues = await formatFormToRequestData(formValues, newCustomersOnly);
      if (validateScreenFields(screensInputNames.length - 1)) {
        (isEdit ? editCampaign({ ...mappedValues, id: (initialValues as any).id }) : createCampaign(mappedValues))
          .then((id) => {
            if (id) {
              goToCampaignInfo(id as string)
            }
          });
      }
    };

    const validateScreenFields = (screenIndex: CampaignFormScreens) => {
      touch(...screensInputNames[screenIndex]);
      if (!valid) {
        scrollToError();
      }
      return valid;
    };

    return createdCampaignId ?
      <OnlineToOfflinePublish campaignId={createdCampaignId} />
      : (
        <form className={styles.form}>
          <p className={styles.title}>{isEdit ? 'Edit' : 'Create'} Online-To-Offline campaign</p>
          <StepsWithProgress
            saving={loading}
            onSaveClicked={onSaveClicked}
            shouldGoNext={validateScreenFields}
            titles={["Campaign Name", "Campaign Settings", "Campaign Template"]}>
            <OnlineToOfflineName />
            <>
              <Field
                name={'tab_index'}
                isEdit={isEdit}
                validate={selectedTab}
                component={OTOTabNavigation} />
              <OnlineToOfflineSettings
                currentCustomersCount={currentCustomersCount}
                unlimitedIncentives={unlimitedIncentives}
                setUnlimitedIncentives={setUnlimitedIncentives}
                newCustomersOnly={newCustomersOnly}
                setNewCustomersOnly={setNewCustomersOnly}
                change={change} />
            </>
            <OnlineToOfflineTemplate />
          </StepsWithProgress>
        </form>
      );
  };
let OnlineToOfflineFormMain = reduxForm({
  form: 'onlineToOfflineForm',
})(OnlineToOfflineMain as any) as any;

const selector = formValueSelector('onlineToOfflineForm');

OnlineToOfflineFormMain = connect(state => {
  const formFields = Object.keys(screensInputNames).map(k => screensInputNames[k]).flat(1);
  const values = selector(state, ...formFields);
  return {
    formValues: values
  }
})(OnlineToOfflineFormMain);

OnlineToOfflineFormMain = withRouter(OnlineToOfflineFormMain);

export default hot(module)(OnlineToOfflineFormMain);
