import * as React from "react";
import * as styles from './styles.module.sass';
import {ILoyaltyItem} from "../../../axios/getLoyaltyItems";
import {useGetCurrency} from "../../../../Utils/getLocalizedCurrency";
import { imagesURL } from "../../../../Utils/helpers";

interface ISpendingForGiftProps {
  giftData: ILoyaltyItem;
}

const SpendingForGift: React.FunctionComponent<ISpendingForGiftProps> = ({giftData}) => {
  const currency = useGetCurrency();
  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <img src={giftData.image ? imagesURL + giftData.image : require('../../../assets/placeholder.svg')}
             className={styles.giftImage}/>
        <div className={styles.textWrapper}>
          <p className={styles.name}>{giftData.name}</p>
          <p className={styles.price}>{(giftData.price).toFixed(2)} {currency}</p>
        </div>
      </div>
      <div className={styles.footer}>
        <p className={styles.text}>
          Points needed to<br/>
          unlock this gift
        </p>
        <div className={styles.line}/>
        <p className={styles.price}>
          {giftData.points}&nbsp;Points
        </p>
      </div>
    </div>
  );
};

export default SpendingForGift;
