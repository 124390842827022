import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.module.sass';
import './fonts.css';
import persistedStore from './redux-store/configureStore';
import {Provider} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import {notify} from "react-notify-toast";
import {HeadProvider} from "react-head";
import * as Mixpanel from 'mixpanel-browser';

Mixpanel.init('d588d71855ea2638a1c4897f59e28b7d');

import * as Sentry from '@sentry/browser';
import { RecoilRoot } from "recoil";

Sentry.init({
  dsn: "https://44113bf8b8024e0da46c92c6fa94b4d4@sentry.io/1769505"
});

async function init() {
  const { store } = await persistedStore();

  ReactDOM.render(
    <Provider store={store}>
      <RecoilRoot>
        <HeadProvider>
          <App />
        </HeadProvider>
      </RecoilRoot>
    </Provider>,
    document.getElementById("root") as HTMLDivElement
  );
}

init()
  .catch(() => notify.show('Oops, application failed to start, please contact us ASAP, and we\'ll take care of it for ya!'));
