import axios from './axios';
import { AxiosReturn } from "./types";
import * as Requests from './requests';
import { IStoreItem } from "../types/wizard-types";


export const getSpecialItems = (token): AxiosReturn<{items: IStoreItem[]}> => {
  return axios.get(Requests.specialItems, {
    headers: {
      token,
    }
  });
};
