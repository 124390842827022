import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {IReachQuotasResponse} from "../types";
import axios from './axios';


export const getPurchasedQuotas = (token: string): AxiosReturn<{ quotas: IReachQuotasResponse[], total_quotas: number }> => {
    return axios.get(Requests.purchasedQuotas, {
        baseURL: process.env.LUMEN_ENDPOINT,
        headers: {
            token,
        },
        params: {status: 2}
    })
};

