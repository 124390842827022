import {Modal, ModalBody} from "reactstrap";
import * as React from "react";
import * as styles from "./styles.module.sass";


interface IProps {
    title: string;
    paragraph: string;
    modal: boolean,
    disabled?: boolean,
    toggle: () => void,
    action?: () => void;
}

class ConfirmationModal extends React.Component<IProps> {
    public handleSubmit = (e) => {
        e.preventDefault();
        const {action, toggle} = this.props;
        (action as any)();
        toggle();
    };

    public render() {
        const {title, modal, toggle, disabled, paragraph} = this.props;
        return (
            <Modal isOpen={modal} toggle={toggle}>
                <ModalBody>
                    <div className={styles.wrapper}>
                        <div className={styles.title}>
                            {title}?
                        </div>
                        <div className={styles.paragraph}>
                            {paragraph}
                        </div>
                        <img className={styles.logo} src={require('../../../assets/check.svg')}/>
                        <div className={styles.btnWrapper}>
                            <button onClick={toggle} className={styles.cancelBtn}>Cancel</button>
                            <button disabled={disabled} onClick={this.handleSubmit}
                                    className={styles.confirmBtn}>Confirm
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        );
    }
}


export default ConfirmationModal;
