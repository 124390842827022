import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {ICampaign} from "../types";




export const createCampaign = (data:ICampaign,token:string): AxiosReturn<{}> => {
  return axios.post(Requests.createCampaign, data,{
      baseURL:process.env.LUMEN_ENDPOINT,
    headers:{
      token,
    }
  })
};
