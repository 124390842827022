import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {IDeleteCampaignResponse} from "../types";



export const deleteCampaign = (campaign_id:string,token:string): AxiosReturn<IDeleteCampaignResponse> => {
  return axios.delete(Requests.deleteCampaign(campaign_id),{
      baseURL:process.env.LUMEN_ENDPOINT,
    headers:{
      token,
    }
  })
};
