import {call, put, select, takeLatest} from "redux-saga/effects";
import {filterCampaignAction} from "../../constants";
import {filterCampaign} from "../../axios";
import {selectForm, selectToken} from "../selectors";
import {filterCampaignFailure, filterCampaignSuccess} from "../actions/filter-campaign-actions";
import {notify} from "react-notify-toast";
import {IFormState} from "../rootReducer";
import {getMomentTimeStamp, getNewCampaignData, getServerTime} from "../../../Utils/helpers";
import {isMoment, Moment} from "moment";
import * as moment from "moment-timezone";
import {
    DAY,
    LAST_30_DAYS,
    LAST_7_DAYS,
} from "../../components/SMSCampaigns/FilterCampaignForm";
import {CUSTOM} from "../../components/SMSCampaigns/FilterCampaignForm";

const subtractDaysFromToday = (n: number) => moment().subtract(n, DAY).startOf(DAY);
const processCampaignCustomDate = (lastVisitFromDate, lastVisitToDate) => {
    // the custom date modal has been provided a from date
    const hasProvidedCustomFromDate = isMoment(lastVisitFromDate);
    const criteria = {
        last_visit_timestamps_range: [0, undefined],
        last_visit_timestamps_text: CUSTOM,
    };
    if (lastVisitToDate) {
        criteria.last_visit_timestamps_range[1] = getMomentTimeStamp(lastVisitToDate.endOf('day'));
    }
    if (hasProvidedCustomFromDate) {
        criteria.last_visit_timestamps_range[0] = getMomentTimeStamp(lastVisitFromDate);
        if (!lastVisitToDate) {
            criteria.last_visit_timestamps_range = [getMomentTimeStamp(lastVisitFromDate)];
        }
    }
    return criteria;
};

export const convertFormToCampaignData = (isNewCampaign = false, date, time, timezone, sms, lastVisit, last_visit_from, last_visit_to,
                                          visits_number, avg_ticket_from, avg_ticket_to, branches, campaignName?) => {
    let data: any = {};
    if (isNewCampaign) {
        const campaignServerTime = getServerTime(date as Moment, time as Moment, timezone);
        data = getNewCampaignData(campaignName as string, getMomentTimeStamp(campaignServerTime), timezone);
    }
    data.sms_content = sms;
    data.criteria = {};
    // only the custom date has a to date
    const isCustomDate = lastVisit === CUSTOM;
    if (isCustomDate) {
        data.criteria = processCampaignCustomDate(last_visit_from, last_visit_to);
    } else {
        const Times = {
            [LAST_7_DAYS]: getMomentTimeStamp(subtractDaysFromToday(7)),
            [LAST_30_DAYS]: getMomentTimeStamp(subtractDaysFromToday(30)),
        };
        if (lastVisit === LAST_30_DAYS || lastVisit === LAST_7_DAYS) {
            data.criteria.last_visit_timestamps_range = [Times[lastVisit], getMomentTimeStamp(moment())];
            data.criteria.last_visit_timestamps_text = lastVisit;
        }
    }

    data.criteria.visits_number = visits_number.toString() + '+';
    if (avg_ticket_from) {
        data.criteria.average_ticket_sizes_range = [+avg_ticket_from];
        if (avg_ticket_to) {
            data.criteria.average_ticket_sizes_range.push(+avg_ticket_to);
        }
    } else if (avg_ticket_to) {
        data.criteria.average_ticket_sizes_range = [0, +avg_ticket_to];
    }
    if (branches.filter(branch => branch.id === 'all branches').length === 0) {
        data.criteria.branch_ids = [...branches.map(branch => {
            return branch.id;
        })];
    }
    return data;
};

function* filterCampaignSaga() {
    try {
        const token = yield select(selectToken);
        const forms: IFormState = yield select(selectForm);
        const {sms, fullTime, branches, visitsCount, lastVisit, avg} = forms.filterCampaignForm.values as any;
        const data = convertFormToCampaignData(false, fullTime.date, fullTime.time, fullTime.timezone, sms,
            lastVisit.dropDownValue, lastVisit.value1 || undefined, lastVisit.value2 || undefined,
            visitsCount.dropDownValue, (avg || {}).value1 || undefined, (avg || {}).value2 || undefined,
            branches);
        const res = yield call(filterCampaign, data, token);
        const campaign = res.data;
        yield put(filterCampaignSuccess(campaign));
        if (!campaign.total_users) {
            notify.show('Can\'t create campaign with total users of 0', 'error');
        }
    } catch (e) {
        notify.show('Please try again. if the problem persists please contact us ASAP.', 'error');
        yield put(filterCampaignFailure(e));
    }
}

export function* watchFilterCampaignSaga() {
    yield takeLatest(filterCampaignAction.requested, filterCampaignSaga);
}
