import * as React from "react";
import * as styles from './styles.module.sass';
import {WrappedFieldProps} from 'redux-form';
import disabledChipTheme from "./disabledchipTheme";
import chipTheme from "./chipTheme";
import Chips, {Chip} from 'react-chips';
import IcoMoon from 'react-icomoon';
import themeDisabled from "./disabledTheme";
import theme from "./theme";
import ReactTooltip from 'react-tooltip';


interface IChipData {
    name: string;
    id: string;
}

interface IProps extends WrappedFieldProps {
    options: IChipData[];
    label: string;
    disabled?: boolean;
    className?: string;
}

interface IState {
    suggestions: any[]
    allSuggestions: any[];
}

export const onlyAll = [
    {
        name: 'All', id: 'all branches'
    },
];


export class RenderChipsField extends React.Component<IProps, IState> {
    public chipsRef;

    constructor(props: IProps) {
        super(props);
        if(!props.input.value){
            props.input.onChange(onlyAll);
        }
        this.state = {
            suggestions: props.options,
            allSuggestions: props.options,
        }
    }

    public filterSuggestions = (opt, val) => {
        const isNotInSelected = !(this.props.input.value || []).find(chip => chip.id === opt.id);
        const isLikeSearch = opt.name.toLowerCase().indexOf(val.toLowerCase()) !== -1;
        const isInSuggestionState = !!this.state.suggestions.find(chip => chip.id === opt.id);
        return isNotInSelected && isLikeSearch && isInSuggestionState;
    };
    public onChange = chips => {
        const hasAllSelectedWithOtherStuff = chips.length >= 2 && chips[0].id === onlyAll[0].id;
        const onlyAllIsSelected = chips.length === 1 && chips[0].id === onlyAll[0].id;
        const notAll = c => c.id !== onlyAll[0].id;
        let filteredChips = hasAllSelectedWithOtherStuff ? chips.filter(notAll) : chips;
        const allWasJustSelected = (chips[chips.length - 1] || {}).id === onlyAll[0].id;
        filteredChips = allWasJustSelected ? onlyAll : filteredChips;
        let newSuggestions = this.state.allSuggestions.filter(suggestion => {
            const isChipSelected = selectedChip => suggestion.id === selectedChip.id;
            const chipIsSelected = filteredChips.find(isChipSelected);
            return !chipIsSelected;
        });
        newSuggestions = !onlyAllIsSelected ? [...onlyAll, ...newSuggestions] : newSuggestions;
        this.setState({
            suggestions: newSuggestions,
        }, () => {
            this.props.input.onChange(filteredChips);
            this.chipsRef.onSuggestionsFetchRequested(this.chipsRef.state);
        });
    };

    public render() {
        const {
            input,
            label,
            className,
            disabled,
            meta: {touched, error, warning},
        } = this.props;
        const extractName = ({name}) => name;
        const renderChip = ({name}) => <Chip theme={disabled ? disabledChipTheme : chipTheme}>{name}</Chip>;

        return (
            <div className={`${styles.chipsFieldWrapper} ${className || ''}`}>
                <div className={styles.textWrapper}>
                    <h2>{label}</h2>
                    <div className={styles.icon}
                         data-tip="It allows you to select customers who visited a certain branch or branches.">
                        <IcoMoon icon="question"/>
                    </div>
                </div>
                <div className={styles.chipsWrapper}>
                    <Chips
                        ref={d => this.chipsRef = d}
                        theme={disabled ? themeDisabled : theme}
                        alwaysRenderSuggestions={!disabled}
                        value={input.value || onlyAll}
                        onChange={this.onChange}
                        suggestions={this.state.suggestions}
                        getChipValue={extractName}
                        renderSuggestion={extractName}
                        getSuggestionValue={extractName}
                        renderChip={renderChip}
                        suggestionsFilter={this.filterSuggestions}
                    />
                    {touched && ((error &&
                        <span className={styles.formError}>{error}</span>) || (warning &&
                        <span>{warning}</span>))}

                </div>
                <ReactTooltip place="top" type="dark" effect="float"/>
            </div>
        );
    }
}
