import axiosDefault, { AxiosError, AxiosResponse } from "axios";

export const goToLogin = () =>
  location.assign(process.env.BRAND_DASHBOARD_LINK as string);
const handleSuccess = (
  res: AxiosResponse
): AxiosResponse | Promise<AxiosResponse> => res;
const handleError = (error: AxiosError) => {
  if (error.response && (error.response as AxiosResponse).status === 401) {
    goToLogin();
    localStorage.clear()
  }
  throw error;
};

const axios = axiosDefault.create({
  baseURL: process.env.ENDPOINT,
  headers: {
    "Content-Type": "application/json"
  }
});

axios.interceptors.response.use(handleSuccess, handleError);
axios.interceptors.request.use((req) => {



  const token = localStorage.getItem("token");

  if (!req.headers.token) {
    req.headers.token = token;
  }

  const isKoinzAdminRemote = localStorage.getItem("is_remote_authentication");
  if (isKoinzAdminRemote) {
    const storeId = localStorage.getItem("store_id");
    if (req.method === "GET" || req.method === "get") {
      if (req.url && req.url.includes("?")) {

        req.url = `${req.url}&store_id=${storeId}`;
      } else {
        req.url = `${req.url}?store_id=${storeId}`;
      }
    } else {
      req.data = {
        ...req.data,
        store_id: storeId,
      };
    }
  }

  return {
    ...req,
  };
});

export default axios;
