import {diffFromNow} from "../../Utils/helpers";
import {Moment} from "moment";

export const required = value => value ? undefined : 'Required';
export const selectedTab = value => (typeof value === 'number' && value >= 0) ? undefined : 'Required';
export const fullTimeValidator = (value) => {
  const defVal = {date: undefined, time: undefined, timezone: undefined, ...value};
  const {date, time, timezone} = defVal;
  if (!date && !time && !timezone) {
    return 'Please select date, time and timezone for your campaign';
  }
  if (date && !time && !timezone) {
    return 'Please select time and timezone for your campaign';
  }
  if (date && time && !timezone) {
    return 'Please select timezone for your campaign';
  }
  if (date && !time && timezone) {
    return 'Please select time for your campaign';
  }

  if (!date && time && !timezone) {
    return 'Please select date and timezone for your campaign';
  }


  if (!date && !time && timezone) {
    return 'Please select date and time for your campaign';
  }


  if (!date && time && timezone) {
    return 'Please select date for your campaign';
  }

  if (date && time && !timezone) {
    return 'Please select timezone for your campaign';
  }

  if (date && time && timezone) {
    const difference = diffFromNow(date as Moment, time as Moment, timezone);
    return difference < 5 ? 'The time you choose is not available.Please select a time that is 5 minutes from now' : '';
  }
  return undefined;
};
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const minLength = min => value =>
  value && value.length < min ? `Must be at least ${min} numbers` : undefined;
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const isEmpty = value => value && value.trim() ? undefined : 'Can\'t be empty';
