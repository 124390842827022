import axios from './axios';
import {AxiosReturn} from "./types";
import * as Requests from './requests';
import {ICampaign, ICreateCampaignResponse} from "../types";




export const filterCampaign = (data:ICampaign,token:string): AxiosReturn<{response:ICreateCampaignResponse}> => {
  return axios.get(Requests.filterCampaign,{
      baseURL:process.env.LUMEN_ENDPOINT,
    headers:{
      token,
    },
      params:data
  })
};
