import React from "react";
import Card from "../Card";
import * as styles from './styles.module.sass'
import {useGetOTOStats} from './hooks';

const OTOStatCards: React.FC<{ campaignId: string }> = ({campaignId}) => {
  const {stats, loading} = useGetOTOStats(campaignId);
  return (
    <div className={styles.cardsWrapper}>
      <Card
        number={stats.total_clicks}
        loading={loading}
        title='Link visits'
        img={require('../../../../assets/oto-link.svg')}
        index={1}/>
      <Card
        number={stats.added_users_count}
        loading={loading}
        title='Phone numbers added'
        img={require('../../../../assets/oto-phone.svg')}
        index={2}/>
      <Card
        number={stats.visitors_count}
        loading={loading}
        title='Store visits'
        img={require('../../../../assets/oto-visits.svg')}
        isBig={true}
        index={3}/>
    </div>
  );
};

export default OTOStatCards;
