import {call, put, select, takeLatest} from "redux-saga/effects";
import {getCampaignsLogAction} from "../../constants";
import {getCampaignsLogFailure, getCampaignsLogSuccess} from "../actions/campaigns-log";
import {campaignsLog} from "../../axios";
import {selectToken} from "../selectors";

function* getCampaignsLogSaga() {
    try {
        const token = yield select(selectToken);
        const res = yield call(campaignsLog, token);
        const campaigns = res.data.campaigns;
        yield put(getCampaignsLogSuccess(campaigns));
    } catch (e) {
        yield put(getCampaignsLogFailure(e));
    }
}

export function* watchCampaignsLogSaga() {
    yield takeLatest(getCampaignsLogAction.requested, getCampaignsLogSaga);
}
