import React, { useContext, useCallback } from 'react';
import * as styles from './styles.module.sass'
import ExampleWrapper from '../../oto-campaign-mini-app/ExampleWrapper';
import StoreData from '../../../contexts/StoreData';
import { hot } from 'react-hot-loader';
import { imagesURL } from '../../../../Utils/helpers';

const MiniAppExample: React.FC = () => {
  const { storeData } = useContext(StoreData);
  const storeImage = storeData ? imagesURL + storeData.store_image : '';
  const closeWindow = useCallback(() => window.close(), []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <button type="button" className={styles.backToEditor} onClick={closeWindow}>
          <img src={require('../../../assets/single_arrow.svg')} alt="" />
          Back to editor
        </button>
      </div>
      <div className={styles.exampleWrapper}>
        <ExampleWrapper storeImage={storeImage} />
      </div>
    </div>
  );
}

export default hot(module)(MiniAppExample);
