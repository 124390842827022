import { MODES } from "../..";

const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=tech.gplanet.shopx';
const IOS_APP_LINK = 'https://apps.apple.com/us/app/shopx-go/id1220667985';
import * as styles from './styles.module.sass'
import { IThankYouProps } from ".";
import React, { useEffect } from "react";
import { useKindaTranslate } from "../PhoneEntry";
import RemindMe from "../RemindMe";
import { useToggleState } from "../../../../../Utils/custom-hooks/useToggleState";



const DownloadAppContent: React.FC<IThankYouProps> = (props) => {
  const { 
    mode,
    OS,
    formatText,
    onSendEmail,
    download_title,
    campaignPoints
  } = props;
  const [isSelected, setIsSelected] = useToggleState();

  const renderAndroid = OS === 'ANDROID' || OS === null;
  const renderIOS = OS === 'IOS' || OS === null;
  const androidLink = mode === MODES.MOCK_UP ? undefined : ANDROID_APP_LINK;
  const iosLink = mode === MODES.MOCK_UP ? undefined : IOS_APP_LINK;
  const t = useKindaTranslate(formatText);
  const handleSendEmail = (email: string) => {
    if (onSendEmail) {
      return onSendEmail(email)
        .then(setIsSelected)
    }
    return Promise.resolve();
  }

  useEffect(() => {
    if (mode !== MODES.MOCK_UP) {
      const top = isSelected ? 1000 : 0;
      const handle = setTimeout(() => {
        window.scrollTo({
          top,
          behavior: "smooth",
        })
      })
      return () => clearTimeout(handle);
    }
    return;
  }, [isSelected])

  return (
    <>
      <p className={styles.downloadText}>{download_title || t('You won 20 points!', {count: campaignPoints})}</p>
      <div className={styles.downloadAppIcons}>
        {
          renderAndroid &&
          <a className={styles.appLink} href={androidLink}>
            <img src={require('../../assets/download-from-google-play.png')} />
          </a>
        }
        {
          renderIOS &&
          <a className={styles.appLink} href={iosLink}>
            <img src={require('../../assets/download-from-app-store.svg')} />
          </a>
        }
      </div>
      <div className={styles.separator} />
      <label className={styles.wrapper}>
        <input
          type="checkbox"
          disabled={mode === MODES.MOCK_UP}
          checked={isSelected}
          onChange={setIsSelected}
          className={styles.input}
        />
        <span className={styles.checkSpan}>
          {isSelected && <img className={styles.check} src={require('../../assets/check.svg')} />}
        </span>
        <p style={{ color: isSelected ? '#41126c' : '#1c1c1c' }} className={styles.title}>{t('Email me the download link')}</p>
      </label>
      {
        isSelected && <RemindMe {...props} onSendEmail={handleSendEmail} />
      }
    </>
  )
}

export default DownloadAppContent;
