import * as React from "react";


export interface ICrumbData {
  name: string;
  url: string;
  disabled?: boolean;
}

export type ISetCrumbData = (crumbData: ICrumbData[]) => void;

interface IContextValues {
  crumbs: ICrumbData[],
  setCrumbs: ISetCrumbData;
}

export default React.createContext<IContextValues>({
  crumbs: [],
  setCrumbs: (crumbData) => undefined,
})
