import {PersistConfig, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {combineReducers} from 'redux';
import createCampaignReducer, {ICreateCampaignReducerState} from "./reducers/createCampaignReducer";
import campaignsLogReducer, {ICampaignsLogReducerState} from "./reducers/campaignsLogReducer";
import authReducer, {IAuthReducerState} from "./reducers/authReducer";
import editCampaignReducer, {IEditCampaignReducerState} from "./reducers/editCampaignReducer";
import filterCampaignReducer, {IFilterCampaignReducerState} from "./reducers/filterCampaignReducer";
import quotasReducer, {IQuotasReducerState} from "./reducers/quotasReducer";
import {FormState, reducer as form} from "redux-form";


export interface IFormState {
  filterCampaignForm: FormState;
  onlineToOfflineForm: FormState;
}

export interface IRootReducerState {
  form: IFormState;
  filterCampaignReducer: IFilterCampaignReducerState,
  createCampaignReducer: ICreateCampaignReducerState;
  campaignsLogReducer: ICampaignsLogReducerState;
  authReducer: IAuthReducerState;
  editCampaignReducer: IEditCampaignReducerState;
  quotasReducer: IQuotasReducerState;
}


const persistAuth: PersistConfig = {
  key: 'authReducer',
  storage,
};

const rootReducer = combineReducers<(Action) => IRootReducerState>({
  // loadingBar: loadingBarReducer,
  createCampaignReducer,
  campaignsLogReducer,
  editCampaignReducer,
  filterCampaignReducer,
  authReducer: persistReducer(persistAuth, authReducer),
  quotasReducer,
  form,
});

const persistorOptions: PersistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'appReducer',
  ]
};

const persistedReducer = persistReducer(persistorOptions, rootReducer);

export default persistedReducer;
